import { useEffect } from 'react'
import { useMutation } from './useMutation'
import { useAsyncEffect, useAsyncEffectThrow } from './useAsyncEffect'
import { notifyInvalidators } from './useInvalidator'

export type QueryState = 'loading' | 'error' | 'success'
export type QuerySuccessState = Exclude<QueryState, 'error'>

/** Same as useMutation but it runs if the component gets mounted */
export function useQuery<T>(callback: () => Promise<T>, deps: unknown[]) {
  const mutation = useMutation(callback)

  useEffect(() => {
    mutation.run().then(() => {
      if (deps) notifyInvalidators(deps)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  switch (mutation.state) {
    case 'error':
      return { ...mutation, state: 'error' as const }
    case 'waiting':
      return { ...mutation, state: 'loading' as const }
    case 'loading':
      return { ...mutation, state: 'loading' as const }
    case 'success':
      return { ...mutation, state: 'success' as const }
  }
}

/**
 * useQuery but with only success result
 * Same as useMutation but it runs if the component gets mounted
 */
export function useQuerySuccessThrow<T>(
  callback: () => Promise<T>,
  deps?: unknown[]
) {
  const mutation = useMutation(callback)

  useAsyncEffectThrow(async () => {
    try {
      await mutation.run()
    } finally {
      if (deps) notifyInvalidators(deps)
    }
  }, deps)

  switch (mutation.state) {
    case 'error':
      throw mutation.error
    case 'waiting':
    case 'loading':
      return { ...mutation, state: 'loading' as const }
    case 'success':
      return { ...mutation, state: 'success' as const }
  }
}

/**
 * useQuery but with only success result
 * Same as useMutation but it runs if the component gets mounted
 */
export function useQuerySuccess<T>(
  callback: () => Promise<T>,
  deps: unknown[]
) {
  const mutation = useMutation(callback)

  useAsyncEffect(async () => {
    try {
      await mutation.run()
    } finally {
      if (deps) notifyInvalidators(deps)
    }
  }, deps)

  switch (mutation.state) {
    case 'error':
      return { ...mutation, state: 'error' as const }
    case 'waiting':
      return { ...mutation, state: 'loading' as const }
    case 'loading':
      return { ...mutation, state: 'loading' as const }
    case 'success':
      return { ...mutation, state: 'success' as const }
  }
}
