import useLanguage from '../../../stores/useLanguage'
import { styled } from '../../../../stitches'
import { useAuth0 } from '@auth0/auth0-react'

export function LoginAndRegisterButtons() {
  const { t, languageCode } = useLanguage()
  const { loginWithRedirect } = useAuth0()

  const handleLogin = async () => {
    await loginWithRedirect({
      authorizationParams: {
        ui_locales: languageCode,
      },
    })
  }

  const handleSignUp = async () => {
    await loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
        ui_locales: languageCode,
      },
    })
  }

  return (
    <ButtonGroup>
      <LinkButton variant="primary" onClick={handleLogin}>
        {t('accountRelated.login')}
      </LinkButton>
      <LinkButton variant="secondary" onClick={handleSignUp}>
        {t('accountRelated.register')}
      </LinkButton>
    </ButtonGroup>
  )
}
const ButtonGroup = styled('div', {
  justifyContent: 'right',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
})

const LinkButton = styled('button', {
  display: 'flex',
  width: '7em',
  height: '44px',
  color: '#fff',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'none',
  cursor: 'pointer',
  borderRadius: '0.35rem',
  border: 'none',
  fontWeight: '600',
  textAlign: 'center',
  variants: {
    variant: {
      primary: {
        background: '#5f5bfe',
        color: '#fff',
      },
      secondary: {
        background: '#fff',
        color: '#5f5bfe',
      },
    },
  },
})
