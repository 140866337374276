import { keyframes } from '@stitches/react'
import { styled } from '../../../stitches'

export default function LoadingScreen() {
  return (
    <LoadingContainer>
      <LoadingSvg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <Circle
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          strokeWidth="4"
        ></Circle>
        <Path
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></Path>
      </LoadingSvg>
    </LoadingContainer>
  )
}

const LoadingContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
})

const spinAnimation = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
})

const LoadingSvg = styled('svg', {
  width: '2.5rem',
  height: '2.5rem',
  color: '#5494ea',
  animation: `${spinAnimation} 1s linear infinite`,
})

const Circle = styled('circle', {
  opacity: 0.25,
})

const Path = styled('path', {
  opacity: 0.75,
})
