/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApiErrorResponse,
  ChannelSubscriptionItem,
  GetSubscriptionResponse,
  GetUserInfoResponse,
  UpdateDescriptionRequest,
  UpdateDisplayNameRequest,
  UpdateSocialUrlsRequest,
  UploadBannerPictureResponse,
  UploadProfilePictureResponse,
} from '../models/index';
import {
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    ChannelSubscriptionItemFromJSON,
    ChannelSubscriptionItemToJSON,
    GetSubscriptionResponseFromJSON,
    GetSubscriptionResponseToJSON,
    GetUserInfoResponseFromJSON,
    GetUserInfoResponseToJSON,
    UpdateDescriptionRequestFromJSON,
    UpdateDescriptionRequestToJSON,
    UpdateDisplayNameRequestFromJSON,
    UpdateDisplayNameRequestToJSON,
    UpdateSocialUrlsRequestFromJSON,
    UpdateSocialUrlsRequestToJSON,
    UploadBannerPictureResponseFromJSON,
    UploadBannerPictureResponseToJSON,
    UploadProfilePictureResponseFromJSON,
    UploadProfilePictureResponseToJSON,
} from '../models/index';

export interface UserChannelDescriptionPutRequest {
    updateDescriptionRequest?: UpdateDescriptionRequest;
}

export interface UserCompleteRegistrationPutRequest {
    updateDisplayNameRequest?: UpdateDisplayNameRequest;
}

export interface UserDisplaynamePutRequest {
    updateDisplayNameRequest?: UpdateDisplayNameRequest;
}

export interface UserSocialurlsPutRequest {
    updateSocialUrlsRequest?: UpdateSocialUrlsRequest;
}

export interface UserSubscribeChannelIdPostRequest {
    channelId: string;
}

export interface UserUnsubscribeChannelIdPostRequest {
    channelId: string;
}

export interface UserUploadChannelBannerpicturePutRequest {
    image?: Blob;
}

export interface UserUploadProfilepicturePutRequest {
    image?: Blob;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Updates description for channel
     */
    async userChannelDescriptionPutRaw(requestParameters: UserChannelDescriptionPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/channel/description`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDescriptionRequestToJSON(requestParameters.updateDescriptionRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates description for channel
     */
    async userChannelDescriptionPut(requestParameters: UserChannelDescriptionPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userChannelDescriptionPutRaw(requestParameters, initOverrides);
    }

    /**
     * Completes registration by setting the username for the user
     */
    async userCompleteRegistrationPutRaw(requestParameters: UserCompleteRegistrationPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/complete-registration`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDisplayNameRequestToJSON(requestParameters.updateDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Completes registration by setting the username for the user
     */
    async userCompleteRegistrationPut(requestParameters: UserCompleteRegistrationPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userCompleteRegistrationPutRaw(requestParameters, initOverrides);
    }

    /**
     * Updates display name for user.
     */
    async userDisplaynamePutRaw(requestParameters: UserDisplaynamePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/displayname`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateDisplayNameRequestToJSON(requestParameters.updateDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates display name for user.
     */
    async userDisplaynamePut(requestParameters: UserDisplaynamePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userDisplaynamePutRaw(requestParameters, initOverrides);
    }

    /**
     * Gets user information for the logged in user
     */
    async userGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUserInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUserInfoResponseFromJSON(jsonValue));
    }

    /**
     * Gets user information for the logged in user
     */
    async userGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUserInfoResponse> {
        const response = await this.userGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates social urls for user
     */
    async userSocialurlsPutRaw(requestParameters: UserSocialurlsPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/socialurls`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateSocialUrlsRequestToJSON(requestParameters.updateSocialUrlsRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Updates social urls for user
     */
    async userSocialurlsPut(requestParameters: UserSocialurlsPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSocialurlsPutRaw(requestParameters, initOverrides);
    }

    /**
     * Subscribes from channel
     */
    async userSubscribeChannelIdPostRaw(requestParameters: UserSubscribeChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling userSubscribeChannelIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/subscribe/{channelId}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Subscribes from channel
     */
    async userSubscribeChannelIdPost(requestParameters: UserSubscribeChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userSubscribeChannelIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Get subscription history for the user
     */
    async userSubscriptionHistoryGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChannelSubscriptionItem>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/subscription-history`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChannelSubscriptionItemFromJSON));
    }

    /**
     * Get subscription history for the user
     */
    async userSubscriptionHistoryGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChannelSubscriptionItem>> {
        const response = await this.userSubscriptionHistoryGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * List all the active subscriptions for the user
     */
    async userSubscriptionsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetSubscriptionResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetSubscriptionResponseFromJSON));
    }

    /**
     * List all the active subscriptions for the user
     */
    async userSubscriptionsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetSubscriptionResponse>> {
        const response = await this.userSubscriptionsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Unsubscribes from channel
     */
    async userUnsubscribeChannelIdPostRaw(requestParameters: UserUnsubscribeChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling userUnsubscribeChannelIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/unsubscribe/{channelId}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unsubscribes from channel
     */
    async userUnsubscribeChannelIdPost(requestParameters: UserUnsubscribeChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.userUnsubscribeChannelIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Uploads banner picture for channel
     */
    async userUploadChannelBannerpicturePutRaw(requestParameters: UserUploadChannelBannerpicturePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadBannerPictureResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/user/upload/channel/bannerpicture`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadBannerPictureResponseFromJSON(jsonValue));
    }

    /**
     * Uploads banner picture for channel
     */
    async userUploadChannelBannerpicturePut(requestParameters: UserUploadChannelBannerpicturePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadBannerPictureResponse> {
        const response = await this.userUploadChannelBannerpicturePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Uploads profile picture for user
     */
    async userUploadProfilepicturePutRaw(requestParameters: UserUploadProfilepicturePutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UploadProfilePictureResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/user/upload/profilepicture`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UploadProfilePictureResponseFromJSON(jsonValue));
    }

    /**
     * Uploads profile picture for user
     */
    async userUploadProfilepicturePut(requestParameters: UserUploadProfilepicturePutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UploadProfilePictureResponse> {
        const response = await this.userUploadProfilepicturePutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
