/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetMessageSenderResponse
 */
export interface GetMessageSenderResponse {
    /**
     * 
     * @type {string}
     * @memberof GetMessageSenderResponse
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageSenderResponse
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageSenderResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageSenderResponse
     */
    badgeUrl: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageSenderResponse
     */
    badgeLabel: string;
    /**
     * 
     * @type {string}
     * @memberof GetMessageSenderResponse
     */
    color: string;
}

/**
 * Check if a given object implements the GetMessageSenderResponse interface.
 */
export function instanceOfGetMessageSenderResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "badgeUrl" in value;
    isInstance = isInstance && "badgeLabel" in value;
    isInstance = isInstance && "color" in value;

    return isInstance;
}

export function GetMessageSenderResponseFromJSON(json: any): GetMessageSenderResponse {
    return GetMessageSenderResponseFromJSONTyped(json, false);
}

export function GetMessageSenderResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetMessageSenderResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'username': json['username'],
        'displayName': json['displayName'],
        'badgeUrl': json['badgeUrl'],
        'badgeLabel': json['badgeLabel'],
        'color': json['color'],
    };
}

export function GetMessageSenderResponseToJSON(value?: GetMessageSenderResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'username': value.username,
        'displayName': value.displayName,
        'badgeUrl': value.badgeUrl,
        'badgeLabel': value.badgeLabel,
        'color': value.color,
    };
}

