import { CSS, keyframes, styled } from '@stitches/react'

type LoaderType = 'text' | 'image'

type SkeletonProps = {
  type: LoaderType
  css?: CSS
}

export const Skeleton = ({ type, css }: SkeletonProps) => {
  switch (type) {
    case 'text':
      return <SkeletonText css={css}></SkeletonText>
    case 'image':
      return <SkeletonImage css={css}></SkeletonImage>
  }
}

const shimmerAnimation = keyframes({
  '0%': { transform: 'translateX(-100%)' },
  '100%': { transform: 'translateX(100%)' },
})

const SkeletonBase = styled('div', {
  backgroundColor: 'var(--base-color, #202020)',
  width: '100%',
  borderRadius: '0.25rem',
  display: 'inline-flex',
  lineHeight: 1,
  position: 'relative',
  userSelect: 'none',
  overflow: 'hidden',
  '--animation-duration': '1.5s',
  '--animation-direction': 'normal',
  '--pseudo-element-display': 'block',
  '&::after': {
    content: '',
    display: 'var(--pseudo-element-display)',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '100%',
    backgroundImage:
      'linear-gradient(90deg, var(--base-color, #202020), var(--highlight-color, #444), var(--base-color, #202020))',
    backgroundRepeat: 'no-repeat',
    transform: 'translateX(-100%)',
    animation: `${shimmerAnimation} var(--animation-duration) ease-in-out infinite var(--animation-direction)`,
  },
})

const SkeletonImage = styled(SkeletonBase, {
  height: '0',
  paddingBottom: '56.25%',
  marginBottom: '2cqw',
  borderRadius: '4px',
})

const SkeletonText = styled(SkeletonBase, {
  height: '1em',
  marginBottom: '2px',
  borderRadius: '4px',
})
