/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AssetType } from './AssetType';
import {
    AssetTypeFromJSON,
    AssetTypeFromJSONTyped,
    AssetTypeToJSON,
} from './AssetType';
import type { ImageVariant } from './ImageVariant';
import {
    ImageVariantFromJSON,
    ImageVariantFromJSONTyped,
    ImageVariantToJSON,
} from './ImageVariant';

/**
 * 
 * @export
 * @interface Asset
 */
export interface Asset {
    /**
     * 
     * @type {Date}
     * @memberof Asset
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Asset
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Asset
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Asset
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    cloudflareImageId?: string | null;
    /**
     * 
     * @type {AssetType}
     * @memberof Asset
     */
    assetType?: AssetType;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    originalFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    newFileName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    contentType?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Asset
     */
    extension?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Asset
     */
    size?: number;
    /**
     * 
     * @type {Date}
     * @memberof Asset
     */
    uploadedAt?: Date;
    /**
     * 
     * @type {Array<ImageVariant>}
     * @memberof Asset
     */
    variants?: Array<ImageVariant> | null;
}

/**
 * Check if a given object implements the Asset interface.
 */
export function instanceOfAsset(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AssetFromJSON(json: any): Asset {
    return AssetFromJSONTyped(json, false);
}

export function AssetFromJSONTyped(json: any, ignoreDiscriminator: boolean): Asset {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'cloudflareImageId': !exists(json, 'cloudflareImageId') ? undefined : json['cloudflareImageId'],
        'assetType': !exists(json, 'assetType') ? undefined : AssetTypeFromJSON(json['assetType']),
        'originalFileName': !exists(json, 'originalFileName') ? undefined : json['originalFileName'],
        'newFileName': !exists(json, 'newFileName') ? undefined : json['newFileName'],
        'contentType': !exists(json, 'contentType') ? undefined : json['contentType'],
        'extension': !exists(json, 'extension') ? undefined : json['extension'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'uploadedAt': !exists(json, 'uploadedAt') ? undefined : (new Date(json['uploadedAt'])),
        'variants': !exists(json, 'variants') ? undefined : (json['variants'] === null ? null : (json['variants'] as Array<any>).map(ImageVariantFromJSON)),
    };
}

export function AssetToJSON(value?: Asset | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'cloudflareImageId': value.cloudflareImageId,
        'assetType': AssetTypeToJSON(value.assetType),
        'originalFileName': value.originalFileName,
        'newFileName': value.newFileName,
        'contentType': value.contentType,
        'extension': value.extension,
        'size': value.size,
        'uploadedAt': value.uploadedAt === undefined ? undefined : (value.uploadedAt.toISOString()),
        'variants': value.variants === undefined ? undefined : (value.variants === null ? null : (value.variants as Array<any>).map(ImageVariantToJSON)),
    };
}

