import { Container, styled } from '../../../stitches'
import LanguageDropdown from './LanguageDropdown'
import LogoPath from '../../../assets/logo.png?url'
import useLanguage from '../../stores/useLanguage'
import { routes } from '../../../routes'
import { Link } from 'react-router-dom'
import { HELPDESK_URL } from '../../constants/ReferencedSites'
import TwitterIcon from '../../../assets/icons/TwitterIcon.svg?react'
import InstagramIcon from '../../../assets/icons/InstagramIcon.svg?react'
import DiscordIcon from '../../../assets/icons/DiscordIcon.svg?react'

const Footer = () => {
  const { t, languageCode } = useLanguage()

  return (
    <StyledFooter>
      <Container>
        <FooterContainer>
          <LogoContainer>
            <LogoImage src={LogoPath} />
          </LogoContainer>
          <Subtitle>{t('footer.ourPolicy')}</Subtitle>
          <FooterLinkContainer>
            <StyledLink to={routes.communityGuidelines}>
              {t('userMenu.communityGuidelines')}
            </StyledLink>
            <Separator />
            <StyledLink to={routes.dmcaPolicy}>
              {t('userMenu.dmcaPolicy')}
            </StyledLink>
            <Separator />
            <StyledLink to={routes.privacyPolicy}>
              {t('userMenu.privacyPolicy')}
            </StyledLink>
            <Separator />
            <StyledLink to={routes.termsOfService}>
              {t('userMenu.termsOfService')}
            </StyledLink>
          </FooterLinkContainer>

          <Subtitle>{t('footer.resources')}</Subtitle>
          <ResourcesContainer>
            <LinkContainer>
              {t('footer.support')}{' '}
              <EmailLink to="mailto:support@beestream.io">
                {'support@beestream.io'}
              </EmailLink>
            </LinkContainer>
            <Separator />
            <StyledLink
              as="a"
              href={`${HELPDESK_URL}/${languageCode}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('userMenu.helpAndSupport')}
            </StyledLink>
          </ResourcesContainer>

          <Subtitle>{t('footer.language')}</Subtitle>
          <LanguageDropdown />
          <SocialContainer>
            <SocialLink
              to="https://twitter.com/beestream"
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon />
            </SocialLink>
            <SocialLink
              to="https://discord.gg/beestream"
              target="_blank"
              rel="noopener noreferrer"
            >
              <DiscordIcon />
            </SocialLink>
            <SocialLink
              to="https://instagram.com/beestream"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InstagramIcon />
            </SocialLink>
          </SocialContainer>
        </FooterContainer>
      </Container>
    </StyledFooter>
  )
}

const StyledFooter = styled('footer', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginInline: '2rem',
})

const FooterContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  paddingBlock: '4rem',
  paddingInline: '0.75rem',
})

const Separator = styled('span', {
  lineHeight: '1.5rem',
  '&::after': {
    content: '|',
  },
  color: '#809ea6',
  marginInline: '1rem',
  fontSize: '0.875rem',
})

const StyledLink = styled(Link, {
  lineHeight: '1.5rem',
  textAlign: 'center',
  textDecoration: 'none',
  color: '#809ea6',
  '&:hover': {
    color: '#ffffff',
  },
  whiteSpace: 'nowrap',
  fontSize: '0.875rem',
})

const EmailLink = styled(Link, {
  textDecoration: 'none',
  color: '#d2dae0',
  '&:hover': {
    color: '#ffffff',
  },
  fontSize: '0.875rem',
})

const FooterLinkContainer = styled('div', {
  '@onlyPhone': {
    ':nth-child(4)': {
      display: 'none',
    },
  },
  marginTop: '0.5rem',
  display: 'flex',
  boxSizing: 'border-box',
  flexWrap: 'wrap',
  justifyContent: 'center',
  lineHeight: '1rem',
})

const ResourcesContainer = styled('div', {
  lineHeight: '1.5rem',
  display: 'flex',
  marginTop: '0.5rem',
  '@onlyPhone': {
    flexDirection: 'column',
    [`${Separator}`]: {
      display: 'none',
    },
  },
})

const SocialContainer = styled('div', {
  marginTop: '1.75rem',
  display: 'flex',
  gap: '3rem',
})

const SocialLink = styled(Link, {
  color: '#b1bcc3',
  '& svg': {
    transform: 'scale(2)',
  },
})

const LogoContainer = styled('div', {})

const LogoImage = styled('img', {
  width: '160px',
  height: 'auto',
})

const Subtitle = styled('div', {
  fontWeight: 600,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  marginTop: '2rem',
})

const LinkContainer = styled('span', {
  color: '#809ea6',
  fontSize: '0.875rem',
})

export default Footer
