import { useState } from 'react'

/** It lets you crash the page in async tasks */
export function useThrowError() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_state, setState] = useState()

  return (error: Error) => {
    setState(() => {
      throw error
    })
  }
}
