/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { ChannelSubscriptionItem } from './ChannelSubscriptionItem';
import {
    ChannelSubscriptionItemFromJSON,
    ChannelSubscriptionItemFromJSONTyped,
    ChannelSubscriptionItemToJSON,
} from './ChannelSubscriptionItem';
import type { SubscriptionStatus } from './SubscriptionStatus';
import {
    SubscriptionStatusFromJSON,
    SubscriptionStatusFromJSONTyped,
    SubscriptionStatusToJSON,
} from './SubscriptionStatus';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ChannelSubscription
 */
export interface ChannelSubscription {
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscription
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscription
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscription
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscription
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChannelSubscription
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ChannelSubscription
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscription
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof ChannelSubscription
     */
    channel?: Channel;
    /**
     * 
     * @type {SubscriptionStatus}
     * @memberof ChannelSubscription
     */
    status?: SubscriptionStatus;
    /**
     * 
     * @type {boolean}
     * @memberof ChannelSubscription
     */
    isActive?: boolean;
    /**
     * 
     * @type {Array<ChannelSubscriptionItem>}
     * @memberof ChannelSubscription
     */
    subscriptionItems?: Array<ChannelSubscriptionItem> | null;
}

/**
 * Check if a given object implements the ChannelSubscription interface.
 */
export function instanceOfChannelSubscription(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelSubscriptionFromJSON(json: any): ChannelSubscription {
    return ChannelSubscriptionFromJSONTyped(json, false);
}

export function ChannelSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelSubscription {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'status': !exists(json, 'status') ? undefined : SubscriptionStatusFromJSON(json['status']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
        'subscriptionItems': !exists(json, 'subscriptionItems') ? undefined : (json['subscriptionItems'] === null ? null : (json['subscriptionItems'] as Array<any>).map(ChannelSubscriptionItemFromJSON)),
    };
}

export function ChannelSubscriptionToJSON(value?: ChannelSubscription | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'status': SubscriptionStatusToJSON(value.status),
        'isActive': value.isActive,
        'subscriptionItems': value.subscriptionItems === undefined ? undefined : (value.subscriptionItems === null ? null : (value.subscriptionItems as Array<any>).map(ChannelSubscriptionItemToJSON)),
    };
}

