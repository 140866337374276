/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetChannelRecommendedItemResponse } from './GetChannelRecommendedItemResponse';
import {
    GetChannelRecommendedItemResponseFromJSON,
    GetChannelRecommendedItemResponseFromJSONTyped,
    GetChannelRecommendedItemResponseToJSON,
} from './GetChannelRecommendedItemResponse';

/**
 * 
 * @export
 * @interface GetChannelRecommendedResponse
 */
export interface GetChannelRecommendedResponse {
    /**
     * 
     * @type {Array<GetChannelRecommendedItemResponse>}
     * @memberof GetChannelRecommendedResponse
     */
    channels: Array<GetChannelRecommendedItemResponse>;
}

/**
 * Check if a given object implements the GetChannelRecommendedResponse interface.
 */
export function instanceOfGetChannelRecommendedResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "channels" in value;

    return isInstance;
}

export function GetChannelRecommendedResponseFromJSON(json: any): GetChannelRecommendedResponse {
    return GetChannelRecommendedResponseFromJSONTyped(json, false);
}

export function GetChannelRecommendedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelRecommendedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': ((json['channels'] as Array<any>).map(GetChannelRecommendedItemResponseFromJSON)),
    };
}

export function GetChannelRecommendedResponseToJSON(value?: GetChannelRecommendedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': ((value.channels as Array<any>).map(GetChannelRecommendedItemResponseToJSON)),
    };
}

