import { SnackbarContent, CustomContentProps, useSnackbar } from 'notistack'
import {
  forwardRef,
  useState,
  useEffect,
  useCallback,
  ForwardRefRenderFunction,
} from 'react'
import { styled } from '../../../stitches'
import { CheckCircledIcon, Cross1Icon } from '@radix-ui/react-icons'

const SuccessToastComponent: ForwardRefRenderFunction<
  HTMLDivElement,
  CustomContentProps
> = (props, ref) => {
  const { id, message, style } = props
  const { closeSnackbar } = useSnackbar()
  const [value, setValue] = useState(0)

  const close = useCallback(() => {
    closeSnackbar(id)
  }, [closeSnackbar, id])

  useEffect(() => {
    const closeTime = Date.now() + 5000
    const timer = setInterval(() => {
      const now = Date.now()
      const diff = closeTime - now
      const value = diff / 5000
      setValue(1 - value)
      if (diff <= 0) {
        close()
      }
    }, 20)
    return () => {
      clearInterval(timer)
    }
  }, [close])

  return (
    <ToastContainer ref={ref} role="alert" style={style}>
      <ToastContent>
        <ToastIcon>
          <CheckCircledIcon />
        </ToastIcon>
        <ToastMessage>{message}</ToastMessage>
        <CloseButton type="button" onClick={close}>
          <Cross1Icon />
        </CloseButton>
      </ToastContent>
      <ProgressBarGold value={value} />
    </ToastContainer>
  )
}

type ProgressBarProps = {
  value: number
}
const ProgressBarGold = ({ value }: ProgressBarProps) => {
  return (
    <ProgressBarContainer>
      <ProgressBarValue style={{ width: `${value * 100}%` }}></ProgressBarValue>
    </ProgressBarContainer>
  )
}

export const SuccessToast = forwardRef<HTMLDivElement, CustomContentProps>(
  SuccessToastComponent
)

const ToastContainer = styled(SnackbarContent, {
  backgroundColor: '#181818',
  color: '#fff',
  borderRadius: '0.25rem',
  padding: '1rem',
  zIndex: 52,
})

const ToastContent = styled('div', {
  display: 'flex',
  alignItems: 'center',
})

const ToastIcon = styled('div', {
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  marginRight: '1.5rem',
  '& svg': {
    width: '1.5em',
    height: '1.5em',
  },
})

const ToastMessage = styled('div', {
  marginRight: '1.5rem',
})

const CloseButton = styled('button', {
  background: 'none',
  border: 'none',
  color: '#fff',
  cursor: 'pointer',
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  '& svg': {
    width: '1em',
    height: '1em',
  },
})

const ProgressBarContainer = styled('div', {
  width: '100%',
  height: '0.25rem',
  backgroundColor: 'black',
  marginTop: '1rem',
  borderRadius: '0.25rem',
})

const ProgressBarValue = styled('div', {
  height: '0.25rem',
  backgroundColor: 'blue',
  borderRadius: '0.25rem',
})
