/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddBadgeResponse,
  AddEmoteResponse,
  ApiErrorResponse,
  GetAchievementResponse,
  GetBadgeResponse,
  GetChannelFollowedResponse,
  GetChannelInfoResponse,
  GetChannelLoggedInUserResponse,
  GetChannelPromoPanelResponse,
  GetChannelRecommendedResponse,
  GetChatMessagesResponse,
  GetEmotesResponse,
  GetLivestreamResponse,
  PinMessageRequest,
  UpdatePromoPanelOrderRequest,
  UpdatePromoPanelsOrderRequest,
} from '../models/index';
import {
    AddBadgeResponseFromJSON,
    AddBadgeResponseToJSON,
    AddEmoteResponseFromJSON,
    AddEmoteResponseToJSON,
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetAchievementResponseFromJSON,
    GetAchievementResponseToJSON,
    GetBadgeResponseFromJSON,
    GetBadgeResponseToJSON,
    GetChannelFollowedResponseFromJSON,
    GetChannelFollowedResponseToJSON,
    GetChannelInfoResponseFromJSON,
    GetChannelInfoResponseToJSON,
    GetChannelLoggedInUserResponseFromJSON,
    GetChannelLoggedInUserResponseToJSON,
    GetChannelPromoPanelResponseFromJSON,
    GetChannelPromoPanelResponseToJSON,
    GetChannelRecommendedResponseFromJSON,
    GetChannelRecommendedResponseToJSON,
    GetChatMessagesResponseFromJSON,
    GetChatMessagesResponseToJSON,
    GetEmotesResponseFromJSON,
    GetEmotesResponseToJSON,
    GetLivestreamResponseFromJSON,
    GetLivestreamResponseToJSON,
    PinMessageRequestFromJSON,
    PinMessageRequestToJSON,
    UpdatePromoPanelOrderRequestFromJSON,
    UpdatePromoPanelOrderRequestToJSON,
    UpdatePromoPanelsOrderRequestFromJSON,
    UpdatePromoPanelsOrderRequestToJSON,
} from '../models/index';

export interface ChannelsBadgeMonthRangeDeleteRequest {
    monthRange: number;
}

export interface ChannelsBadgePostRequest {
    monthRange: number;
    image?: Blob;
    label?: string;
}

export interface ChannelsChannelIdEmotesGetRequest {
    channelId: string;
}

export interface ChannelsChannelIdMessagesGetRequest {
    channelId: string;
}

export interface ChannelsChannelIdPinMessagePostRequest {
    channelId: string;
    pinMessageRequest?: PinMessageRequest;
}

export interface ChannelsChannelIdPromoPanelsGetRequest {
    channelId: string;
}

export interface ChannelsEmotesEmoteIdDeleteRequest {
    emoteId: string;
}

export interface ChannelsEmotesPostRequest {
    name: string;
    image?: Blob;
}

export interface ChannelsFollowChannelIdPostRequest {
    channelId: string;
}

export interface ChannelsPromoPanelsBulkOrderPutRequest {
    updatePromoPanelsOrderRequest?: UpdatePromoPanelsOrderRequest;
}

export interface ChannelsPromoPanelsOrderPutRequest {
    updatePromoPanelOrderRequest?: UpdatePromoPanelOrderRequest;
}

export interface ChannelsPromoPanelsPanelIdDeleteRequest {
    panelId: string;
}

export interface ChannelsPromoPanelsPanelIdGetRequest {
    panelId: string;
}

export interface ChannelsPromoPanelsPanelIdPutRequest {
    panelId: string;
    title?: string;
    description?: string;
    image?: Blob;
    imageLinkUrl?: string;
}

export interface ChannelsPromoPanelsPostRequest {
    title?: string;
    description?: string;
    image?: Blob;
    imageLinkUrl?: string;
}

export interface ChannelsSlugGetRequest {
    slug: string;
}

export interface ChannelsSlugLivestreamGetRequest {
    slug: string;
}

export interface ChannelsSlugMeGetRequest {
    slug: string;
}

export interface ChannelsUnfollowChannelIdPostRequest {
    channelId: string;
}

export interface ChannelsUnpinMessagePinnedMessageIdDeleteRequest {
    pinnedMessageId: string;
}

/**
 * 
 */
export class ChannelsApi extends runtime.BaseAPI {

    /**
     */
    async channelsAchievementsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetAchievementResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/achievements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetAchievementResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelsAchievementsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetAchievementResponse> {
        const response = await this.channelsAchievementsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Removes a badge associated with a specific month range.
     */
    async channelsBadgeMonthRangeDeleteRaw(requestParameters: ChannelsBadgeMonthRangeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.monthRange === null || requestParameters.monthRange === undefined) {
            throw new runtime.RequiredError('monthRange','Required parameter requestParameters.monthRange was null or undefined when calling channelsBadgeMonthRangeDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/badge/{monthRange}`.replace(`{${"monthRange"}}`, encodeURIComponent(String(requestParameters.monthRange))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Removes a badge associated with a specific month range.
     */
    async channelsBadgeMonthRangeDelete(requestParameters: ChannelsBadgeMonthRangeDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsBadgeMonthRangeDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new badge associated with a specific month range.
     */
    async channelsBadgePostRaw(requestParameters: ChannelsBadgePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddBadgeResponse>> {
        if (requestParameters.monthRange === null || requestParameters.monthRange === undefined) {
            throw new runtime.RequiredError('monthRange','Required parameter requestParameters.monthRange was null or undefined when calling channelsBadgePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.monthRange !== undefined) {
            formParams.append('MonthRange', requestParameters.monthRange as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        if (requestParameters.label !== undefined) {
            formParams.append('Label', requestParameters.label as any);
        }

        const response = await this.request({
            path: `/channels/badge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddBadgeResponseFromJSON(jsonValue));
    }

    /**
     * Adds a new badge associated with a specific month range.
     */
    async channelsBadgePost(requestParameters: ChannelsBadgePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddBadgeResponse> {
        const response = await this.channelsBadgePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the list of badges awarded based on subscription duration.
     */
    async channelsBadgesGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetBadgeResponse>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/badges`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetBadgeResponseFromJSON));
    }

    /**
     * Retrieves the list of badges awarded based on subscription duration.
     */
    async channelsBadgesGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetBadgeResponse>> {
        const response = await this.channelsBadgesGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves all custom emotes for a specific channel.
     */
    async channelsChannelIdEmotesGetRaw(requestParameters: ChannelsChannelIdEmotesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetEmotesResponse>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsChannelIdEmotesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/{channelId}/emotes`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmotesResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves all custom emotes for a specific channel.
     */
    async channelsChannelIdEmotesGet(requestParameters: ChannelsChannelIdEmotesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetEmotesResponse> {
        const response = await this.channelsChannelIdEmotesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async channelsChannelIdMessagesGetRaw(requestParameters: ChannelsChannelIdMessagesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChatMessagesResponse>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsChannelIdMessagesGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/{channelId}/messages`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChatMessagesResponseFromJSON(jsonValue));
    }

    /**
     */
    async channelsChannelIdMessagesGet(requestParameters: ChannelsChannelIdMessagesGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChatMessagesResponse> {
        const response = await this.channelsChannelIdMessagesGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async channelsChannelIdPinMessagePostRaw(requestParameters: ChannelsChannelIdPinMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsChannelIdPinMessagePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/{channelId}/pin-message`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PinMessageRequestToJSON(requestParameters.pinMessageRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelsChannelIdPinMessagePost(requestParameters: ChannelsChannelIdPinMessagePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsChannelIdPinMessagePostRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves a list of all promo panels associated with the specified channel.
     */
    async channelsChannelIdPromoPanelsGetRaw(requestParameters: ChannelsChannelIdPromoPanelsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<GetChannelPromoPanelResponse>>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsChannelIdPromoPanelsGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/{channelId}/promo-panels`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GetChannelPromoPanelResponseFromJSON));
    }

    /**
     * Retrieves a list of all promo panels associated with the specified channel.
     */
    async channelsChannelIdPromoPanelsGet(requestParameters: ChannelsChannelIdPromoPanelsGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<GetChannelPromoPanelResponse>> {
        const response = await this.channelsChannelIdPromoPanelsGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes a custom emote from the channel.
     */
    async channelsEmotesEmoteIdDeleteRaw(requestParameters: ChannelsEmotesEmoteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.emoteId === null || requestParameters.emoteId === undefined) {
            throw new runtime.RequiredError('emoteId','Required parameter requestParameters.emoteId was null or undefined when calling channelsEmotesEmoteIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/emotes/{emoteId}`.replace(`{${"emoteId"}}`, encodeURIComponent(String(requestParameters.emoteId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes a custom emote from the channel.
     */
    async channelsEmotesEmoteIdDelete(requestParameters: ChannelsEmotesEmoteIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsEmotesEmoteIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Adds a new custom emote to the channel.
     */
    async channelsEmotesPostRaw(requestParameters: ChannelsEmotesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddEmoteResponse>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling channelsEmotesPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.name !== undefined) {
            formParams.append('Name', requestParameters.name as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        const response = await this.request({
            path: `/channels/emotes`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddEmoteResponseFromJSON(jsonValue));
    }

    /**
     * Adds a new custom emote to the channel.
     */
    async channelsEmotesPost(requestParameters: ChannelsEmotesPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddEmoteResponse> {
        const response = await this.channelsEmotesPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allows the logged-in user to follow a specified channel.
     */
    async channelsFollowChannelIdPostRaw(requestParameters: ChannelsFollowChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsFollowChannelIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/follow/{channelId}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows the logged-in user to follow a specified channel.
     */
    async channelsFollowChannelIdPost(requestParameters: ChannelsFollowChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsFollowChannelIdPostRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves the list of following channels.
     */
    async channelsFollowedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelFollowedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/followed`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelFollowedResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves the list of following channels.
     */
    async channelsFollowedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelFollowedResponse> {
        const response = await this.channelsFollowedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Updates the display order of multiple promo panels within a channel in a bulk operation. Only the channel owner can perform this update.
     */
    async channelsPromoPanelsBulkOrderPutRaw(requestParameters: ChannelsPromoPanelsBulkOrderPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/promo-panels/bulk-order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromoPanelsOrderRequestToJSON(requestParameters.updatePromoPanelsOrderRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the display order of multiple promo panels within a channel in a bulk operation. Only the channel owner can perform this update.
     */
    async channelsPromoPanelsBulkOrderPut(requestParameters: ChannelsPromoPanelsBulkOrderPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.channelsPromoPanelsBulkOrderPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates the display order of a specific promo panel within a channel. Only the channel owner can update the order.
     */
    async channelsPromoPanelsOrderPutRaw(requestParameters: ChannelsPromoPanelsOrderPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/promo-panels/order`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdatePromoPanelOrderRequestToJSON(requestParameters.updatePromoPanelOrderRequest),
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Updates the display order of a specific promo panel within a channel. Only the channel owner can update the order.
     */
    async channelsPromoPanelsOrderPut(requestParameters: ChannelsPromoPanelsOrderPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.channelsPromoPanelsOrderPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Deletes an existing promo panel within a channel. Only the channel owner is authorized to delete the promo panel.
     */
    async channelsPromoPanelsPanelIdDeleteRaw(requestParameters: ChannelsPromoPanelsPanelIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling channelsPromoPanelsPanelIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Deletes an existing promo panel within a channel. Only the channel owner is authorized to delete the promo panel.
     */
    async channelsPromoPanelsPanelIdDelete(requestParameters: ChannelsPromoPanelsPanelIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsPromoPanelsPanelIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     * Retrieves detailed information about a specific promo panel by its ID within a channel.
     */
    async channelsPromoPanelsPanelIdGetRaw(requestParameters: ChannelsPromoPanelsPanelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling channelsPromoPanelsPanelIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves detailed information about a specific promo panel by its ID within a channel.
     */
    async channelsPromoPanelsPanelIdGet(requestParameters: ChannelsPromoPanelsPanelIdGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.channelsPromoPanelsPanelIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Updates an existing promo panel within a channel. Only the channel owner is authorized to update the promo panel.
     */
    async channelsPromoPanelsPanelIdPutRaw(requestParameters: ChannelsPromoPanelsPanelIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        if (requestParameters.panelId === null || requestParameters.panelId === undefined) {
            throw new runtime.RequiredError('panelId','Required parameter requestParameters.panelId was null or undefined when calling channelsPromoPanelsPanelIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        if (requestParameters.imageLinkUrl !== undefined) {
            formParams.append('ImageLinkUrl', requestParameters.imageLinkUrl as any);
        }

        const response = await this.request({
            path: `/channels/promo-panels/{panelId}`.replace(`{${"panelId"}}`, encodeURIComponent(String(requestParameters.panelId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Updates an existing promo panel within a channel. Only the channel owner is authorized to update the promo panel.
     */
    async channelsPromoPanelsPanelIdPut(requestParameters: ChannelsPromoPanelsPanelIdPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.channelsPromoPanelsPanelIdPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Adds a new promo panel to the specified channel. Only authorized users (channel owners) can add promo panels.
     */
    async channelsPromoPanelsPostRaw(requestParameters: ChannelsPromoPanelsPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelPromoPanelResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.title !== undefined) {
            formParams.append('Title', requestParameters.title as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('Description', requestParameters.description as any);
        }

        if (requestParameters.image !== undefined) {
            formParams.append('Image', requestParameters.image as any);
        }

        if (requestParameters.imageLinkUrl !== undefined) {
            formParams.append('ImageLinkUrl', requestParameters.imageLinkUrl as any);
        }

        const response = await this.request({
            path: `/channels/promo-panels`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelPromoPanelResponseFromJSON(jsonValue));
    }

    /**
     * Adds a new promo panel to the specified channel. Only authorized users (channel owners) can add promo panels.
     */
    async channelsPromoPanelsPost(requestParameters: ChannelsPromoPanelsPostRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelPromoPanelResponse> {
        const response = await this.channelsPromoPanelsPostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves the list of channels which are recommended.
     */
    async channelsRecommendedGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelRecommendedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/recommended`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelRecommendedResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves the list of channels which are recommended.
     */
    async channelsRecommendedGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelRecommendedResponse> {
        const response = await this.channelsRecommendedGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Retrieves information about a channel based on its slug.
     */
    async channelsSlugGetRaw(requestParameters: ChannelsSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelInfoResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/{slug}`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelInfoResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves information about a channel based on its slug.
     */
    async channelsSlugGet(requestParameters: ChannelsSlugGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelInfoResponse> {
        const response = await this.channelsSlugGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves live stream information for the channel\'s slug.
     */
    async channelsSlugLivestreamGetRaw(requestParameters: ChannelsSlugLivestreamGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetLivestreamResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugLivestreamGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/{slug}/livestream`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetLivestreamResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves live stream information for the channel\'s slug.
     */
    async channelsSlugLivestreamGet(requestParameters: ChannelsSlugLivestreamGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetLivestreamResponse> {
        const response = await this.channelsSlugLivestreamGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieves channel information specific to the logged-in user by the channel\'s slug.
     */
    async channelsSlugMeGetRaw(requestParameters: ChannelsSlugMeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetChannelLoggedInUserResponse>> {
        if (requestParameters.slug === null || requestParameters.slug === undefined) {
            throw new runtime.RequiredError('slug','Required parameter requestParameters.slug was null or undefined when calling channelsSlugMeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/{slug}/me`.replace(`{${"slug"}}`, encodeURIComponent(String(requestParameters.slug))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetChannelLoggedInUserResponseFromJSON(jsonValue));
    }

    /**
     * Retrieves channel information specific to the logged-in user by the channel\'s slug.
     */
    async channelsSlugMeGet(requestParameters: ChannelsSlugMeGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetChannelLoggedInUserResponse> {
        const response = await this.channelsSlugMeGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allows the logged-in user to unfollow a specified channel.
     */
    async channelsUnfollowChannelIdPostRaw(requestParameters: ChannelsUnfollowChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelsUnfollowChannelIdPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/unfollow/{channelId}`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows the logged-in user to unfollow a specified channel.
     */
    async channelsUnfollowChannelIdPost(requestParameters: ChannelsUnfollowChannelIdPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsUnfollowChannelIdPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelsUnpinMessagePinnedMessageIdDeleteRaw(requestParameters: ChannelsUnpinMessagePinnedMessageIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.pinnedMessageId === null || requestParameters.pinnedMessageId === undefined) {
            throw new runtime.RequiredError('pinnedMessageId','Required parameter requestParameters.pinnedMessageId was null or undefined when calling channelsUnpinMessagePinnedMessageIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/unpin-message/{pinnedMessageId}`.replace(`{${"pinnedMessageId"}}`, encodeURIComponent(String(requestParameters.pinnedMessageId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelsUnpinMessagePinnedMessageIdDelete(requestParameters: ChannelsUnpinMessagePinnedMessageIdDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsUnpinMessagePinnedMessageIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelsVerifyPostRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channels/verify`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelsVerifyPost(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelsVerifyPostRaw(initOverrides);
    }

}
