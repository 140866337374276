import { styled } from '../../../../stitches'

const StyledForm = styled('form', {
  width: '100%',
  flex: 1,
  display: 'flex',
  flexFlow: 'column',
  gap: '2rem',
  justifyContent: 'space-around',
})

const Body = styled('div', {
  flex: 1,
  justifyContent: 'center',
  display: 'flex',
  flexFlow: 'column',
  gap: '1.5rem',
})

const Footer = styled('div', {
  flex: 0,
  display: 'flex',
  flexFlow: 'column',
  gap: '1rem',
})

export const Form = {
  Form: StyledForm,
  Body,
  Footer,
}
