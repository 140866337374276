/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivityEventType } from './ActivityEventType';
import {
    ActivityEventTypeFromJSON,
    ActivityEventTypeFromJSONTyped,
    ActivityEventTypeToJSON,
} from './ActivityEventType';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ActivityFeedEvent
 */
export interface ActivityFeedEvent {
    /**
     * 
     * @type {Date}
     * @memberof ActivityFeedEvent
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedEvent
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ActivityFeedEvent
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedEvent
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedEvent
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedEvent
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof ActivityFeedEvent
     */
    channel?: Channel;
    /**
     * 
     * @type {number}
     * @memberof ActivityFeedEvent
     */
    userId?: number | null;
    /**
     * 
     * @type {User}
     * @memberof ActivityFeedEvent
     */
    user?: User;
    /**
     * 
     * @type {ActivityEventType}
     * @memberof ActivityFeedEvent
     */
    eventType?: ActivityEventType;
    /**
     * 
     * @type {Date}
     * @memberof ActivityFeedEvent
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof ActivityFeedEvent
     */
    eventDetails?: string | null;
}

/**
 * Check if a given object implements the ActivityFeedEvent interface.
 */
export function instanceOfActivityFeedEvent(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ActivityFeedEventFromJSON(json: any): ActivityFeedEvent {
    return ActivityFeedEventFromJSONTyped(json, false);
}

export function ActivityFeedEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityFeedEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'eventType': !exists(json, 'eventType') ? undefined : ActivityEventTypeFromJSON(json['eventType']),
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'eventDetails': !exists(json, 'eventDetails') ? undefined : json['eventDetails'],
    };
}

export function ActivityFeedEventToJSON(value?: ActivityFeedEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'id': value.id,
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'eventType': ActivityEventTypeToJSON(value.eventType),
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'eventDetails': value.eventDetails,
    };
}

