/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ChannelFollower
 */
export interface ChannelFollower {
    /**
     * 
     * @type {Date}
     * @memberof ChannelFollower
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelFollower
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChannelFollower
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelFollower
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChannelFollower
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ChannelFollower
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ChannelFollower
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof ChannelFollower
     */
    channel?: Channel;
    /**
     * 
     * @type {Date}
     * @memberof ChannelFollower
     */
    followedAt?: Date;
}

/**
 * Check if a given object implements the ChannelFollower interface.
 */
export function instanceOfChannelFollower(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelFollowerFromJSON(json: any): ChannelFollower {
    return ChannelFollowerFromJSONTyped(json, false);
}

export function ChannelFollowerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelFollower {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'followedAt': !exists(json, 'followedAt') ? undefined : (new Date(json['followedAt'])),
    };
}

export function ChannelFollowerToJSON(value?: ChannelFollower | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'followedAt': value.followedAt === undefined ? undefined : (value.followedAt.toISOString()),
    };
}

