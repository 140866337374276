/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * 
 * @export
 * @interface CategoryTag
 */
export interface CategoryTag {
    /**
     * 
     * @type {Date}
     * @memberof CategoryTag
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CategoryTag
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CategoryTag
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CategoryTag
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CategoryTag
     */
    tagId?: number;
    /**
     * 
     * @type {Tag}
     * @memberof CategoryTag
     */
    tag?: Tag;
    /**
     * 
     * @type {number}
     * @memberof CategoryTag
     */
    categoryId?: number;
    /**
     * 
     * @type {Category}
     * @memberof CategoryTag
     */
    category?: Category;
}

/**
 * Check if a given object implements the CategoryTag interface.
 */
export function instanceOfCategoryTag(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CategoryTagFromJSON(json: any): CategoryTag {
    return CategoryTagFromJSONTyped(json, false);
}

export function CategoryTagFromJSONTyped(json: any, ignoreDiscriminator: boolean): CategoryTag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'tagId': !exists(json, 'tagId') ? undefined : json['tagId'],
        'tag': !exists(json, 'tag') ? undefined : TagFromJSON(json['tag']),
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
    };
}

export function CategoryTagToJSON(value?: CategoryTag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'tagId': value.tagId,
        'tag': TagToJSON(value.tag),
        'categoryId': value.categoryId,
        'category': CategoryToJSON(value.category),
    };
}

