import React, { useCallback, useEffect, useRef, useState } from 'react'
import { styled } from '../../stitches'
import SideColumn from './SideColumn'

interface WithSideColumnLayoutProps {
  children: React.ReactNode
}

const WithSideColumnLayout = ({ children }: WithSideColumnLayoutProps) => {
  const [sideColumnWidth, setSideColumnWidth] = useState<number>(0)
  const sideColumnRef = useRef<HTMLDivElement>(null)

  const handleResize = useCallback(() => {
    setSideColumnWidth(sideColumnRef.current?.offsetWidth || 0)
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)
    handleResize()
    window.addEventListener('resize', handleResize)

    const resizeObserver = new ResizeObserver((entries) => {
      setSideColumnWidth(entries[0].contentRect.width)
    })

    const currentSideColumnRef = sideColumnRef.current
    if (currentSideColumnRef) {
      resizeObserver.observe(currentSideColumnRef)
    }

    return () => {
      window.removeEventListener('resize', handleResize)
      if (currentSideColumnRef) resizeObserver.disconnect()
    }
  }, [handleResize])

  const mainContentStyle = { marginLeft: `${sideColumnWidth}px` }

  return (
    <Container>
      <SideColumnWrapper ref={sideColumnRef}>
        <SideColumn />
      </SideColumnWrapper>
      <MainContent style={mainContentStyle}>{children}</MainContent>
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
})

const SideColumnWrapper = styled('div', {
  position: 'fixed',
  zIndex: 111,
})

const MainContent = styled('div', {
  width: '100%',
})

export default WithSideColumnLayout
