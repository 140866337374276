/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ReportReason } from './ReportReason';
import {
    ReportReasonFromJSON,
    ReportReasonFromJSONTyped,
    ReportReasonToJSON,
} from './ReportReason';

/**
 * 
 * @export
 * @interface CreateStreamSessionReportRequest
 */
export interface CreateStreamSessionReportRequest {
    /**
     * 
     * @type {ReportReason}
     * @memberof CreateStreamSessionReportRequest
     */
    reason?: ReportReason;
    /**
     * 
     * @type {string}
     * @memberof CreateStreamSessionReportRequest
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateStreamSessionReportRequest
     */
    streamSessionId: string;
}

/**
 * Check if a given object implements the CreateStreamSessionReportRequest interface.
 */
export function instanceOfCreateStreamSessionReportRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "streamSessionId" in value;

    return isInstance;
}

export function CreateStreamSessionReportRequestFromJSON(json: any): CreateStreamSessionReportRequest {
    return CreateStreamSessionReportRequestFromJSONTyped(json, false);
}

export function CreateStreamSessionReportRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateStreamSessionReportRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'reason': !exists(json, 'reason') ? undefined : ReportReasonFromJSON(json['reason']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'streamSessionId': json['streamSessionId'],
    };
}

export function CreateStreamSessionReportRequestToJSON(value?: CreateStreamSessionReportRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'reason': ReportReasonToJSON(value.reason),
        'description': value.description,
        'streamSessionId': value.streamSessionId,
    };
}

