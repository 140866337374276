/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BaseTagResponse
 */
export interface BaseTagResponse {
    /**
     * 
     * @type {number}
     * @memberof BaseTagResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BaseTagResponse
     */
    tagKey: string;
}

/**
 * Check if a given object implements the BaseTagResponse interface.
 */
export function instanceOfBaseTagResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "tagKey" in value;

    return isInstance;
}

export function BaseTagResponseFromJSON(json: any): BaseTagResponse {
    return BaseTagResponseFromJSONTyped(json, false);
}

export function BaseTagResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseTagResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'tagKey': json['tagKey'],
    };
}

export function BaseTagResponseToJSON(value?: BaseTagResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'tagKey': value.tagKey,
    };
}

