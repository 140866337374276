/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface BaseUserResponse
 */
export interface BaseUserResponse {
    /**
     * 
     * @type {number}
     * @memberof BaseUserResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    auth0Id: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof BaseUserResponse
     */
    profilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    twitterUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    facebookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    instagramUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    youtubeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    discordUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BaseUserResponse
     */
    tikTokUrl?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUserResponse
     */
    canChangeDisplayName?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof BaseUserResponse
     */
    registrationCompletedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof BaseUserResponse
     */
    isRegistrationCompleted?: boolean | null;
}

/**
 * Check if a given object implements the BaseUserResponse interface.
 */
export function instanceOfBaseUserResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "auth0Id" in value;

    return isInstance;
}

export function BaseUserResponseFromJSON(json: any): BaseUserResponse {
    return BaseUserResponseFromJSONTyped(json, false);
}

export function BaseUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BaseUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'auth0Id': json['auth0Id'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : ImageVariantsResponseFromJSON(json['profilePicture']),
        'twitterUrl': !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'facebookUrl': !exists(json, 'facebookUrl') ? undefined : json['facebookUrl'],
        'instagramUrl': !exists(json, 'instagramUrl') ? undefined : json['instagramUrl'],
        'youtubeUrl': !exists(json, 'youtubeUrl') ? undefined : json['youtubeUrl'],
        'discordUrl': !exists(json, 'discordUrl') ? undefined : json['discordUrl'],
        'tikTokUrl': !exists(json, 'tikTokUrl') ? undefined : json['tikTokUrl'],
        'canChangeDisplayName': !exists(json, 'canChangeDisplayName') ? undefined : json['canChangeDisplayName'],
        'registrationCompletedAt': !exists(json, 'registrationCompletedAt') ? undefined : (json['registrationCompletedAt'] === null ? null : new Date(json['registrationCompletedAt'])),
        'isRegistrationCompleted': !exists(json, 'isRegistrationCompleted') ? undefined : json['isRegistrationCompleted'],
    };
}

export function BaseUserResponseToJSON(value?: BaseUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'auth0Id': value.auth0Id,
        'profilePicture': ImageVariantsResponseToJSON(value.profilePicture),
        'twitterUrl': value.twitterUrl,
        'facebookUrl': value.facebookUrl,
        'instagramUrl': value.instagramUrl,
        'youtubeUrl': value.youtubeUrl,
        'discordUrl': value.discordUrl,
        'tikTokUrl': value.tikTokUrl,
        'canChangeDisplayName': value.canChangeDisplayName,
        'registrationCompletedAt': value.registrationCompletedAt === undefined ? undefined : (value.registrationCompletedAt === null ? null : value.registrationCompletedAt.toISOString()),
        'isRegistrationCompleted': value.isRegistrationCompleted,
    };
}

