import { ReactNode } from 'react'

import { styled } from '../../../../stitches'
import * as RadixSelect from '@radix-ui/react-select'
import { ChevronDownIcon } from '@radix-ui/react-icons'

type SelectProps<
  TItem extends string,
  TOption extends { label: ReactNode; value: TItem }[],
> = {
  value?: TItem
  placeholder?: ReactNode
  className?: string
  onChange: (value: TItem) => void
  options: TOption
  labelText?: string
}

export const Select = <
  TItem extends string,
  TOption extends { label: ReactNode; value: TItem }[],
>(
  props: SelectProps<TItem, TOption>
) => {
  return (
    <>
      {props.labelText && <FloatingLabel>{props.labelText}</FloatingLabel>}
      <DropdownContainer className={props.className}>
        <RadixSelect.Root value={props.value} onValueChange={props.onChange}>
          <StyledTrigger>
            <RadixSelect.Value placeholder={props.placeholder} />
            <StyledIcon>
              <ChevronDownIcon />
            </StyledIcon>
          </StyledTrigger>
          <RadixSelect.Portal>
            <StyledContent position="popper" align="center" sideOffset={10}>
              <StyledViewport>
                {props.options.map((option) => (
                  <StyledItem
                    key={option.value}
                    value={option.value}
                    active={option.value == props.value}
                  >
                    <RadixSelect.ItemText>{option.label}</RadixSelect.ItemText>
                  </StyledItem>
                ))}
              </StyledViewport>
            </StyledContent>
          </RadixSelect.Portal>
        </RadixSelect.Root>
      </DropdownContainer>
    </>
  )
}
const DropdownContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid #47494B',
  borderRadius: '0.2rem',
  marginTop: '-2rem',
})

const StyledTrigger = styled(RadixSelect.Trigger, {
  flex: 1,
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
  textTransform: 'capitalize',
  background: 'none',
  color: '#fff',
  cursor: 'pointer',
  boxShadow: 'none',
  outline: 'none',
  position: 'relative',
  border: 'none',
  padding: '0 1rem',
  margin: 0,
  height: '42px',
})

const StyledViewport = styled(RadixSelect.Viewport, {
  position: 'relative',
  padding: '0.5rem',
  listStyleType: 'none',
  width: 'var(--radix-select-trigger-width)',
  zIndex: 1050,
  backgroundColor: 'rgb(36, 39, 44)',
  maxHeight: '200px',
})

const StyledItem = styled(RadixSelect.Item, {
  cursor: 'pointer',
  padding: '0.2rem 0.7rem',
  color: '#fff',
  whiteSpace: 'nowrap',
  border: 'none',
  boxShadow: 'none',
  outline: 'none',
  borderRadius: '0.3em',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  variants: {
    active: {
      true: {
        color: '#3E526E',
        cursor: 'default',
        '&:hover': {
          background: 'none',
        },
      },
    },
  },
  '&:hover': {
    background: 'rgba(255,255,255,0.2)',
  },
  paddingBlock: '1rem',
})

const StyledContent = styled(RadixSelect.Content, {
  zIndex: 1000,
})

const StyledIcon = styled(RadixSelect.SelectIcon, {
  position: 'absolute',
  right: '1.25rem',
  top: '0.75rem',
})

const FloatingLabel = styled('label', {
  fontWeight: 500,
  position: 'relative',
  pointerEvents: 'none',
  color: '#fff',
  fontSize: '1rem',
  lineHeight: 1,
  top: '0rem',
  borderRadius: '5px',
  scale: 0.8,
  opacity: 1,
  backgroundColor: '#1B1D1F',
  marginInline: '0.2rem',
  paddingInline: '0.2rem',
  width: 'max-content',
})
