/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetChatMessageItemResponse } from './GetChatMessageItemResponse';
import {
    GetChatMessageItemResponseFromJSON,
    GetChatMessageItemResponseFromJSONTyped,
    GetChatMessageItemResponseToJSON,
} from './GetChatMessageItemResponse';

/**
 * 
 * @export
 * @interface GetPinnedChatMessageResponse
 */
export interface GetPinnedChatMessageResponse {
    /**
     * 
     * @type {number}
     * @memberof GetPinnedChatMessageResponse
     */
    duration?: number;
    /**
     * 
     * @type {Date}
     * @memberof GetPinnedChatMessageResponse
     */
    finishedAt?: Date;
    /**
     * 
     * @type {GetChatMessageItemResponse}
     * @memberof GetPinnedChatMessageResponse
     */
    message: GetChatMessageItemResponse;
}

/**
 * Check if a given object implements the GetPinnedChatMessageResponse interface.
 */
export function instanceOfGetPinnedChatMessageResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;

    return isInstance;
}

export function GetPinnedChatMessageResponseFromJSON(json: any): GetPinnedChatMessageResponse {
    return GetPinnedChatMessageResponseFromJSONTyped(json, false);
}

export function GetPinnedChatMessageResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetPinnedChatMessageResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'finishedAt': !exists(json, 'finishedAt') ? undefined : (new Date(json['finishedAt'])),
        'message': GetChatMessageItemResponseFromJSON(json['message']),
    };
}

export function GetPinnedChatMessageResponseToJSON(value?: GetPinnedChatMessageResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'duration': value.duration,
        'finishedAt': value.finishedAt === undefined ? undefined : (value.finishedAt.toISOString()),
        'message': GetChatMessageItemResponseToJSON(value.message),
    };
}

