/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MFAStatusResponse
 */
export interface MFAStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof MFAStatusResponse
     */
    mfaActive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof MFAStatusResponse
     */
    mfaId?: string | null;
}

/**
 * Check if a given object implements the MFAStatusResponse interface.
 */
export function instanceOfMFAStatusResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MFAStatusResponseFromJSON(json: any): MFAStatusResponse {
    return MFAStatusResponseFromJSONTyped(json, false);
}

export function MFAStatusResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MFAStatusResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'mfaActive': !exists(json, 'mfaActive') ? undefined : json['mfaActive'],
        'mfaId': !exists(json, 'mfaId') ? undefined : json['mfaId'],
    };
}

export function MFAStatusResponseToJSON(value?: MFAStatusResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'mfaActive': value.mfaActive,
        'mfaId': value.mfaId,
    };
}

