/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PanelOrderUpdate } from './PanelOrderUpdate';
import {
    PanelOrderUpdateFromJSON,
    PanelOrderUpdateFromJSONTyped,
    PanelOrderUpdateToJSON,
} from './PanelOrderUpdate';

/**
 * 
 * @export
 * @interface UpdatePromoPanelsOrderRequest
 */
export interface UpdatePromoPanelsOrderRequest {
    /**
     * 
     * @type {Array<PanelOrderUpdate>}
     * @memberof UpdatePromoPanelsOrderRequest
     */
    updates?: Array<PanelOrderUpdate> | null;
}

/**
 * Check if a given object implements the UpdatePromoPanelsOrderRequest interface.
 */
export function instanceOfUpdatePromoPanelsOrderRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdatePromoPanelsOrderRequestFromJSON(json: any): UpdatePromoPanelsOrderRequest {
    return UpdatePromoPanelsOrderRequestFromJSONTyped(json, false);
}

export function UpdatePromoPanelsOrderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdatePromoPanelsOrderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'updates': !exists(json, 'updates') ? undefined : (json['updates'] === null ? null : (json['updates'] as Array<any>).map(PanelOrderUpdateFromJSON)),
    };
}

export function UpdatePromoPanelsOrderRequestToJSON(value?: UpdatePromoPanelsOrderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'updates': value.updates === undefined ? undefined : (value.updates === null ? null : (value.updates as Array<any>).map(PanelOrderUpdateToJSON)),
    };
}

