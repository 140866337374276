import { atom, useAtom, useSetAtom } from 'jotai'
import { Modal } from './Modal'
import { styled } from '../../../stitches'
import useLanguage from '../../stores/useLanguage'
import { Form } from './Form/Form'
import Button from './Form/Button'

type ConfirmationContext = {
  description: string
  callback: () => void
}

const confirmationAtom = atom<ConfirmationContext | undefined>(undefined)

/**
 *
 * Example:
 * ```
 * const { withConfirmation } = useConfirmation()
 * // ...
 * const myCallback = withConfirmation(
 *    () => deleteUser(),
 *    { description: "Are you Sure?" }
 * )
 * ```
 */
export function useConfirmation() {
  const setState = useSetAtom(confirmationAtom)

  function withConfirmation(
    callback: () => void,
    options: { description: string }
  ) {
    return () => {
      setState({
        callback,
        description: options.description,
      })
    }
  }

  return { withConfirmation }
}

export function Confirmation() {
  const [state, setState] = useAtom(confirmationAtom)
  return (
    <Modal
      title={state?.description}
      Component={ConfirmationModal}
      onClose={() => setState(undefined)}
      open={!!state}
    />
  )
}

function ConfirmationModal() {
  const { t } = useLanguage()
  const [state, setState] = useAtom(confirmationAtom)
  return (
    <Form.Form>
      <Form.Footer>
        <Actions>
          <Button
            type="button"
            onClick={() => setState(undefined)}
            variant="secondary"
          >
            {t('general.cancel')}
          </Button>
          <Button
            type="button"
            variant="danger"
            onClick={() => {
              state?.callback()
              setState(undefined)
            }}
          >
            {t('general.proceed')}
          </Button>
        </Actions>
      </Form.Footer>
    </Form.Form>
  )
}
const Actions = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  gap: '0.5rem',
  '& button': {
    height: '2.5rem',
    width: '5rem',
  },
})
