import { styled } from '../../../../stitches'

export const FormRow = styled('div', {
  fontFamily: 'Inter',
})
export const HelpText = styled('div', {
  color: 'gray',
  fontSize: '0.8em',
  variants: {
    /** For input fields */
    input: {
      true: {
        textAlign: 'left',
        padding: '4px 16px',
      },
    },
    center: {
      true: {
        textAlign: 'center',
      },
    },
  },
})

export const ErrorText = styled(HelpText, {
  color: 'red',
})
