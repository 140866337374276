/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetAchievementItemResponse } from './GetAchievementItemResponse';
import {
    GetAchievementItemResponseFromJSON,
    GetAchievementItemResponseFromJSONTyped,
    GetAchievementItemResponseToJSON,
} from './GetAchievementItemResponse';

/**
 * 
 * @export
 * @interface GetAchievementResponse
 */
export interface GetAchievementResponse {
    /**
     * 
     * @type {Array<GetAchievementItemResponse>}
     * @memberof GetAchievementResponse
     */
    achievements?: Array<GetAchievementItemResponse> | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAchievementResponse
     */
    isVerified?: boolean;
}

/**
 * Check if a given object implements the GetAchievementResponse interface.
 */
export function instanceOfGetAchievementResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetAchievementResponseFromJSON(json: any): GetAchievementResponse {
    return GetAchievementResponseFromJSONTyped(json, false);
}

export function GetAchievementResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetAchievementResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'achievements': !exists(json, 'achievements') ? undefined : (json['achievements'] === null ? null : (json['achievements'] as Array<any>).map(GetAchievementItemResponseFromJSON)),
        'isVerified': !exists(json, 'isVerified') ? undefined : json['isVerified'],
    };
}

export function GetAchievementResponseToJSON(value?: GetAchievementResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'achievements': value.achievements === undefined ? undefined : (value.achievements === null ? null : (value.achievements as Array<any>).map(GetAchievementItemResponseToJSON)),
        'isVerified': value.isVerified,
    };
}

