/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseCategoryResponse } from './BaseCategoryResponse';
import {
    BaseCategoryResponseFromJSON,
    BaseCategoryResponseFromJSONTyped,
    BaseCategoryResponseToJSON,
} from './BaseCategoryResponse';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetChannelRecommendedItemResponse
 */
export interface GetChannelRecommendedItemResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChannelRecommendedItemResponse
     */
    channelId: string;
    /**
     * 
     * @type {BaseCategoryResponse}
     * @memberof GetChannelRecommendedItemResponse
     */
    category?: BaseCategoryResponse;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelRecommendedItemResponse
     */
    isLive?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetChannelRecommendedItemResponse
     */
    sessionTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChannelRecommendedItemResponse
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof GetChannelRecommendedItemResponse
     */
    displayName: string;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetChannelRecommendedItemResponse
     */
    profilePicture?: ImageVariantsResponse;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetChannelRecommendedItemResponse
     */
    bannerPicture?: ImageVariantsResponse;
}

/**
 * Check if a given object implements the GetChannelRecommendedItemResponse interface.
 */
export function instanceOfGetChannelRecommendedItemResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "channelId" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "displayName" in value;

    return isInstance;
}

export function GetChannelRecommendedItemResponseFromJSON(json: any): GetChannelRecommendedItemResponse {
    return GetChannelRecommendedItemResponseFromJSONTyped(json, false);
}

export function GetChannelRecommendedItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelRecommendedItemResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelId': json['channelId'],
        'category': !exists(json, 'category') ? undefined : BaseCategoryResponseFromJSON(json['category']),
        'isLive': !exists(json, 'isLive') ? undefined : json['isLive'],
        'sessionTitle': !exists(json, 'sessionTitle') ? undefined : json['sessionTitle'],
        'slug': json['slug'],
        'displayName': json['displayName'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : ImageVariantsResponseFromJSON(json['profilePicture']),
        'bannerPicture': !exists(json, 'bannerPicture') ? undefined : ImageVariantsResponseFromJSON(json['bannerPicture']),
    };
}

export function GetChannelRecommendedItemResponseToJSON(value?: GetChannelRecommendedItemResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelId': value.channelId,
        'category': BaseCategoryResponseToJSON(value.category),
        'isLive': value.isLive,
        'sessionTitle': value.sessionTitle,
        'slug': value.slug,
        'displayName': value.displayName,
        'profilePicture': ImageVariantsResponseToJSON(value.profilePicture),
        'bannerPicture': ImageVariantsResponseToJSON(value.bannerPicture),
    };
}

