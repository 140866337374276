/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseCategoryResponse } from './BaseCategoryResponse';
import {
    BaseCategoryResponseFromJSON,
    BaseCategoryResponseFromJSONTyped,
    BaseCategoryResponseToJSON,
} from './BaseCategoryResponse';
import type { BaseTagResponse } from './BaseTagResponse';
import {
    BaseTagResponseFromJSON,
    BaseTagResponseFromJSONTyped,
    BaseTagResponseToJSON,
} from './BaseTagResponse';

/**
 * 
 * @export
 * @interface GetTopCategoryResponse
 */
export interface GetTopCategoryResponse {
    /**
     * 
     * @type {number}
     * @memberof GetTopCategoryResponse
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GetTopCategoryResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof GetTopCategoryResponse
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof GetTopCategoryResponse
     */
    description?: string | null;
    /**
     * 
     * @type {Array<BaseTagResponse>}
     * @memberof GetTopCategoryResponse
     */
    tags: Array<BaseTagResponse>;
    /**
     * 
     * @type {BaseCategoryResponse}
     * @memberof GetTopCategoryResponse
     */
    parentCategory: BaseCategoryResponse;
}

/**
 * Check if a given object implements the GetTopCategoryResponse interface.
 */
export function instanceOfGetTopCategoryResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "parentCategory" in value;

    return isInstance;
}

export function GetTopCategoryResponseFromJSON(json: any): GetTopCategoryResponse {
    return GetTopCategoryResponseFromJSONTyped(json, false);
}

export function GetTopCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'slug': json['slug'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'tags': ((json['tags'] as Array<any>).map(BaseTagResponseFromJSON)),
        'parentCategory': BaseCategoryResponseFromJSON(json['parentCategory']),
    };
}

export function GetTopCategoryResponseToJSON(value?: GetTopCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'description': value.description,
        'tags': ((value.tags as Array<any>).map(BaseTagResponseToJSON)),
        'parentCategory': BaseCategoryResponseToJSON(value.parentCategory),
    };
}

