/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';

/**
 * 
 * @export
 * @interface ImageVariant
 */
export interface ImageVariant {
    /**
     * 
     * @type {Date}
     * @memberof ImageVariant
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ImageVariant
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ImageVariant
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ImageVariant
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    assetId?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    url?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageVariant
     */
    width?: number;
    /**
     * 
     * @type {string}
     * @memberof ImageVariant
     */
    format?: string | null;
    /**
     * 
     * @type {Asset}
     * @memberof ImageVariant
     */
    asset?: Asset;
}

/**
 * Check if a given object implements the ImageVariant interface.
 */
export function instanceOfImageVariant(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ImageVariantFromJSON(json: any): ImageVariant {
    return ImageVariantFromJSONTyped(json, false);
}

export function ImageVariantFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageVariant {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'assetId': !exists(json, 'assetId') ? undefined : json['assetId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'url': !exists(json, 'url') ? undefined : json['url'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'format': !exists(json, 'format') ? undefined : json['format'],
        'asset': !exists(json, 'asset') ? undefined : AssetFromJSON(json['asset']),
    };
}

export function ImageVariantToJSON(value?: ImageVariant | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'assetId': value.assetId,
        'name': value.name,
        'url': value.url,
        'width': value.width,
        'format': value.format,
        'asset': AssetToJSON(value.asset),
    };
}

