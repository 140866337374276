/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Category } from './Category';
import {
    CategoryFromJSON,
    CategoryFromJSONTyped,
    CategoryToJSON,
} from './Category';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { ContentReport } from './ContentReport';
import {
    ContentReportFromJSON,
    ContentReportFromJSONTyped,
    ContentReportToJSON,
} from './ContentReport';
import type { StreamSession } from './StreamSession';
import {
    StreamSessionFromJSON,
    StreamSessionFromJSONTyped,
    StreamSessionToJSON,
} from './StreamSession';

/**
 * 
 * @export
 * @interface Video
 */
export interface Video {
    /**
     * 
     * @type {Date}
     * @memberof Video
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Video
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Video
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Video
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    language?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Video
     */
    isMature?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    awsRecordingS3BucketName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    awsRecordingS3KeyPrefix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    sourceUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Video
     */
    duration?: number;
    /**
     * 
     * @type {Date}
     * @memberof Video
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Video
     */
    finishedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Video
     */
    bannedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof Video
     */
    channel?: Channel;
    /**
     * 
     * @type {string}
     * @memberof Video
     */
    streamSessionId?: string;
    /**
     * 
     * @type {StreamSession}
     * @memberof Video
     */
    streamSession?: StreamSession;
    /**
     * 
     * @type {number}
     * @memberof Video
     */
    categoryId?: number;
    /**
     * 
     * @type {Category}
     * @memberof Video
     */
    category?: Category;
    /**
     * 
     * @type {Array<ContentReport>}
     * @memberof Video
     */
    contentReports?: Array<ContentReport> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Video
     */
    readonly isBanned?: boolean;
}

/**
 * Check if a given object implements the Video interface.
 */
export function instanceOfVideo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function VideoFromJSON(json: any): Video {
    return VideoFromJSONTyped(json, false);
}

export function VideoFromJSONTyped(json: any, ignoreDiscriminator: boolean): Video {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': json['title'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'isMature': !exists(json, 'isMature') ? undefined : json['isMature'],
        'awsRecordingS3BucketName': !exists(json, 'awsRecordingS3BucketName') ? undefined : json['awsRecordingS3BucketName'],
        'awsRecordingS3KeyPrefix': !exists(json, 'awsRecordingS3KeyPrefix') ? undefined : json['awsRecordingS3KeyPrefix'],
        'sourceUrl': !exists(json, 'sourceUrl') ? undefined : json['sourceUrl'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'startedAt': !exists(json, 'startedAt') ? undefined : (new Date(json['startedAt'])),
        'finishedAt': !exists(json, 'finishedAt') ? undefined : (new Date(json['finishedAt'])),
        'bannedAt': !exists(json, 'bannedAt') ? undefined : (json['bannedAt'] === null ? null : new Date(json['bannedAt'])),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'streamSessionId': !exists(json, 'streamSessionId') ? undefined : json['streamSessionId'],
        'streamSession': !exists(json, 'streamSession') ? undefined : StreamSessionFromJSON(json['streamSession']),
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'category': !exists(json, 'category') ? undefined : CategoryFromJSON(json['category']),
        'contentReports': !exists(json, 'contentReports') ? undefined : (json['contentReports'] === null ? null : (json['contentReports'] as Array<any>).map(ContentReportFromJSON)),
        'isBanned': !exists(json, 'isBanned') ? undefined : json['isBanned'],
    };
}

export function VideoToJSON(value?: Video | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'title': value.title,
        'language': value.language,
        'isMature': value.isMature,
        'awsRecordingS3BucketName': value.awsRecordingS3BucketName,
        'awsRecordingS3KeyPrefix': value.awsRecordingS3KeyPrefix,
        'sourceUrl': value.sourceUrl,
        'thumbnailUrl': value.thumbnailUrl,
        'duration': value.duration,
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'finishedAt': value.finishedAt === undefined ? undefined : (value.finishedAt.toISOString()),
        'bannedAt': value.bannedAt === undefined ? undefined : (value.bannedAt === null ? null : value.bannedAt.toISOString()),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'streamSessionId': value.streamSessionId,
        'streamSession': StreamSessionToJSON(value.streamSession),
        'categoryId': value.categoryId,
        'category': CategoryToJSON(value.category),
        'contentReports': value.contentReports === undefined ? undefined : (value.contentReports === null ? null : (value.contentReports as Array<any>).map(ContentReportToJSON)),
    };
}

