/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStreamMetadataResponse
 */
export interface GetStreamMetadataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStreamMetadataResponse
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof GetStreamMetadataResponse
     */
    language: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetStreamMetadataResponse
     */
    hasMatureContent: boolean;
}

/**
 * Check if a given object implements the GetStreamMetadataResponse interface.
 */
export function instanceOfGetStreamMetadataResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "hasMatureContent" in value;

    return isInstance;
}

export function GetStreamMetadataResponseFromJSON(json: any): GetStreamMetadataResponse {
    return GetStreamMetadataResponseFromJSONTyped(json, false);
}

export function GetStreamMetadataResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamMetadataResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'language': json['language'],
        'hasMatureContent': json['hasMatureContent'],
    };
}

export function GetStreamMetadataResponseToJSON(value?: GetStreamMetadataResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'language': value.language,
        'hasMatureContent': value.hasMatureContent,
    };
}

