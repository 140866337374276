/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MessageSenderRequest
 */
export interface MessageSenderRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageSenderRequest
     */
    userId: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSenderRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSenderRequest
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof MessageSenderRequest
     */
    badgeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageSenderRequest
     */
    badgeLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageSenderRequest
     */
    color?: string | null;
}

/**
 * Check if a given object implements the MessageSenderRequest interface.
 */
export function instanceOfMessageSenderRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "displayName" in value;

    return isInstance;
}

export function MessageSenderRequestFromJSON(json: any): MessageSenderRequest {
    return MessageSenderRequestFromJSONTyped(json, false);
}

export function MessageSenderRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageSenderRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'username': json['username'],
        'displayName': json['displayName'],
        'badgeUrl': !exists(json, 'badgeUrl') ? undefined : json['badgeUrl'],
        'badgeLabel': !exists(json, 'badgeLabel') ? undefined : json['badgeLabel'],
        'color': !exists(json, 'color') ? undefined : json['color'],
    };
}

export function MessageSenderRequestToJSON(value?: MessageSenderRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'username': value.username,
        'displayName': value.displayName,
        'badgeUrl': value.badgeUrl,
        'badgeLabel': value.badgeLabel,
        'color': value.color,
    };
}

