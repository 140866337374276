/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStreamInfoResponse
 */
export interface GetStreamInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStreamInfoResponse
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetStreamInfoResponse
     */
    categoryId?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetStreamInfoResponse
     */
    hasMatureContent?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetStreamInfoResponse
     */
    isDefault: boolean;
}

/**
 * Check if a given object implements the GetStreamInfoResponse interface.
 */
export function instanceOfGetStreamInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isDefault" in value;

    return isInstance;
}

export function GetStreamInfoResponseFromJSON(json: any): GetStreamInfoResponse {
    return GetStreamInfoResponseFromJSONTyped(json, false);
}

export function GetStreamInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': !exists(json, 'title') ? undefined : json['title'],
        'categoryId': !exists(json, 'categoryId') ? undefined : json['categoryId'],
        'hasMatureContent': !exists(json, 'hasMatureContent') ? undefined : json['hasMatureContent'],
        'isDefault': json['isDefault'],
    };
}

export function GetStreamInfoResponseToJSON(value?: GetStreamInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'categoryId': value.categoryId,
        'hasMatureContent': value.hasMatureContent,
        'isDefault': value.isDefault,
    };
}

