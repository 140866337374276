/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStreamConfigResponse
 */
export interface GetStreamConfigResponse {
    /**
     * An ingest server identifies a specific Amazon IVS component that receives the stream, along with an ingestion protocol (for example, RTMPS).
     * @type {string}
     * @memberof GetStreamConfigResponse
     */
    ingestServer: string;
    /**
     * A unique code used to identify a source of video streaming. IVS assigns a stream key when you create a channel and uses it to authorize streaming. Treat the stream key like a secret, since it allows anyone to stream to the channel.
     * @type {string}
     * @memberof GetStreamConfigResponse
     */
    streamKey: string;
}

/**
 * Check if a given object implements the GetStreamConfigResponse interface.
 */
export function instanceOfGetStreamConfigResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ingestServer" in value;
    isInstance = isInstance && "streamKey" in value;

    return isInstance;
}

export function GetStreamConfigResponseFromJSON(json: any): GetStreamConfigResponse {
    return GetStreamConfigResponseFromJSONTyped(json, false);
}

export function GetStreamConfigResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamConfigResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ingestServer': json['ingestServer'],
        'streamKey': json['streamKey'],
    };
}

export function GetStreamConfigResponseToJSON(value?: GetStreamConfigResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ingestServer': value.ingestServer,
        'streamKey': value.streamKey,
    };
}

