import { useState } from 'react'
import { styled } from '../../stitches'
import { FollowedChannels } from './FollowedChannels/FollowedChannels'
import { RecommendedChannels } from './RecommendedChannels/RecommendedChannels'
import { useAuth0 } from '@auth0/auth0-react'

export default function SideColumn() {
  const { isAuthenticated } = useAuth0()
  const [collapsed, setCollapsed] = useState(false)

  return (
    <SideColumnContainer collapsed={collapsed}>
      <>
        {isAuthenticated && (
          <FollowedChannels
            collapsed={collapsed}
            handleCollapse={() => setCollapsed((prev) => !prev)}
          />
        )}
        <RecommendedChannels
          collapsed={collapsed}
          handleCollapse={() => setCollapsed(!collapsed)}
        />
      </>
    </SideColumnContainer>
  )
}

const SideColumnContainer = styled('div', {
  display: 'none',
  height: '100vh',
  flexDirection: 'column',
  alignItems: 'stretch',
  '@desktop': {
    display: 'flex',
  },
  variants: {
    collapsed: {
      false: {
        width: '15em',
      },
    },
  },
})
