/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDisplayNameRequest
 */
export interface UpdateDisplayNameRequest {
    /**
     * The display name must be between 3 and 20 characters long and can only contain alphanumeric characters and underscores.
     * @type {string}
     * @memberof UpdateDisplayNameRequest
     */
    newDisplayName?: string | null;
}

/**
 * Check if a given object implements the UpdateDisplayNameRequest interface.
 */
export function instanceOfUpdateDisplayNameRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateDisplayNameRequestFromJSON(json: any): UpdateDisplayNameRequest {
    return UpdateDisplayNameRequestFromJSONTyped(json, false);
}

export function UpdateDisplayNameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDisplayNameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'newDisplayName': !exists(json, 'newDisplayName') ? undefined : json['newDisplayName'],
    };
}

export function UpdateDisplayNameRequestToJSON(value?: UpdateDisplayNameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'newDisplayName': value.newDisplayName,
    };
}

