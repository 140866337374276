/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivityEventType } from './ActivityEventType';
import {
    ActivityEventTypeFromJSON,
    ActivityEventTypeFromJSONTyped,
    ActivityEventTypeToJSON,
} from './ActivityEventType';

/**
 * 
 * @export
 * @interface GetActivityFeedEventResponse
 */
export interface GetActivityFeedEventResponse {
    /**
     * 
     * @type {string}
     * @memberof GetActivityFeedEventResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetActivityFeedEventResponse
     */
    channelId: string;
    /**
     * 
     * @type {string}
     * @memberof GetActivityFeedEventResponse
     */
    channelName: string;
    /**
     * 
     * @type {number}
     * @memberof GetActivityFeedEventResponse
     */
    userId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivityFeedEventResponse
     */
    username?: string | null;
    /**
     * 
     * @type {ActivityEventType}
     * @memberof GetActivityFeedEventResponse
     */
    eventType?: ActivityEventType;
    /**
     * 
     * @type {Date}
     * @memberof GetActivityFeedEventResponse
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof GetActivityFeedEventResponse
     */
    eventDetails?: string | null;
}

/**
 * Check if a given object implements the GetActivityFeedEventResponse interface.
 */
export function instanceOfGetActivityFeedEventResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "channelId" in value;
    isInstance = isInstance && "channelName" in value;

    return isInstance;
}

export function GetActivityFeedEventResponseFromJSON(json: any): GetActivityFeedEventResponse {
    return GetActivityFeedEventResponseFromJSONTyped(json, false);
}

export function GetActivityFeedEventResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetActivityFeedEventResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'channelId': json['channelId'],
        'channelName': json['channelName'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'eventType': !exists(json, 'eventType') ? undefined : ActivityEventTypeFromJSON(json['eventType']),
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'eventDetails': !exists(json, 'eventDetails') ? undefined : json['eventDetails'],
    };
}

export function GetActivityFeedEventResponseToJSON(value?: GetActivityFeedEventResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'channelId': value.channelId,
        'channelName': value.channelName,
        'userId': value.userId,
        'username': value.username,
        'eventType': ActivityEventTypeToJSON(value.eventType),
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'eventDetails': value.eventDetails,
    };
}

