import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import {
  Configuration,
  Auth0Api,
  StreamApi,
  LivestreamsApi,
  ChannelsApi,
  VideoApi,
  UserApi,
  CategoryApi,
  SearchApi,
  ContentReportApi,
  ChatApi,
  ChannelModeratorApi,
  ChannelBanApi,
  ActivityFeedApi,
} from '../../client'
import { errorHandler } from './apiError'
import LoadingScreen from '../components/Common/LoadingScreen'

interface ApiContextProps {
  auth0Api: Auth0Api
  streamApi: StreamApi
  liveStreamsApi: LivestreamsApi
  channelsApi: ChannelsApi
  chatApi: ChatApi
  videoApi: VideoApi
  userApi: UserApi
  categoriesApi: CategoryApi
  searchApi: SearchApi
  reportApi: ContentReportApi
  channelModeratorApi: ChannelModeratorApi
  channelBanApi: ChannelBanApi
  activityFeedApi: ActivityFeedApi
}

const ApiContext = createContext<ApiContextProps | undefined>(undefined)

interface ApiProviderProps {
  children: ReactNode
}

const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
  const { getAccessTokenSilently, isLoading, user } = useAuth0()
  const [token, setToken] = useState<string | null>(null)
  const [apis, setApis] = useState<ApiContextProps | null>(null)

  useEffect(() => {
    const fetchToken = async () => {
      if (user) {
        try {
          const fetchedToken = await getAccessTokenSilently()
          setToken(fetchedToken)
        } catch (error) {
          console.error('Error fetching access token:', error)
        }
      } else {
        setToken(null)
      }
    }

    if (!isLoading) {
      fetchToken()
    }
  }, [user, getAccessTokenSilently, isLoading])

  useEffect(() => {
    const initializeApis = () => {
      const headers: HeadersInit = token
        ? { Authorization: `Bearer ${token}` }
        : {}

      const configuration = new Configuration({
        basePath: import.meta.env.VITE_API_BASE_URL,
        credentials: 'include',
        headers,
      })

      const apiClients = {
        auth0Api: new Auth0Api(configuration).withMiddleware(errorHandler),
        streamApi: new StreamApi(configuration).withMiddleware(errorHandler),
        liveStreamsApi: new LivestreamsApi(configuration).withMiddleware(
          errorHandler
        ),
        channelsApi: new ChannelsApi(configuration).withMiddleware(
          errorHandler
        ),
        chatApi: new ChatApi(configuration).withMiddleware(errorHandler),
        videoApi: new VideoApi(configuration).withMiddleware(errorHandler),
        userApi: new UserApi(configuration).withMiddleware(errorHandler),
        categoriesApi: new CategoryApi(configuration).withMiddleware(
          errorHandler
        ),
        searchApi: new SearchApi(configuration).withMiddleware(errorHandler),
        reportApi: new ContentReportApi(configuration).withMiddleware(
          errorHandler
        ),
        channelModeratorApi: new ChannelModeratorApi(
          configuration
        ).withMiddleware(errorHandler),
        channelBanApi: new ChannelBanApi(configuration).withMiddleware(
          errorHandler
        ),
        activityFeedApi: new ActivityFeedApi(configuration).withMiddleware(
          errorHandler
        ),
      }

      setApis(apiClients)
    }

    initializeApis()
  }, [token])

  if (isLoading || !apis) {
    return <LoadingScreen />
  }

  return <ApiContext.Provider value={apis}>{children}</ApiContext.Provider>
}

const useApi = (): ApiContextProps => {
  const context = useContext(ApiContext)
  if (!context) {
    throw new Error('useApi must be used within an ApiProvider')
  }
  return context
}

export { ApiProvider, useApi }
