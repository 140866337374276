/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { ReportReason } from './ReportReason';
import {
    ReportReasonFromJSON,
    ReportReasonFromJSONTyped,
    ReportReasonToJSON,
} from './ReportReason';
import type { StreamSession } from './StreamSession';
import {
    StreamSessionFromJSON,
    StreamSessionFromJSONTyped,
    StreamSessionToJSON,
} from './StreamSession';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Video } from './Video';
import {
    VideoFromJSON,
    VideoFromJSONTyped,
    VideoToJSON,
} from './Video';

/**
 * 
 * @export
 * @interface ContentReport
 */
export interface ContentReport {
    /**
     * 
     * @type {Date}
     * @memberof ContentReport
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContentReport
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContentReport
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ContentReport
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    id?: string;
    /**
     * 
     * @type {ReportReason}
     * @memberof ContentReport
     */
    reason?: ReportReason;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    reasonDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    description?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ContentReport
     */
    reportedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof ContentReport
     */
    channel?: Channel;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    videoId?: string | null;
    /**
     * 
     * @type {Video}
     * @memberof ContentReport
     */
    video?: Video;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    streamSessionId?: string | null;
    /**
     * 
     * @type {StreamSession}
     * @memberof ContentReport
     */
    streamSession?: StreamSession;
    /**
     * 
     * @type {number}
     * @memberof ContentReport
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ContentReport
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    channelName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    reporterUserName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentReport
     */
    streamTitle?: string | null;
}

/**
 * Check if a given object implements the ContentReport interface.
 */
export function instanceOfContentReport(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContentReportFromJSON(json: any): ContentReport {
    return ContentReportFromJSONTyped(json, false);
}

export function ContentReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentReport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'reason': !exists(json, 'reason') ? undefined : ReportReasonFromJSON(json['reason']),
        'reasonDescription': !exists(json, 'reasonDescription') ? undefined : json['reasonDescription'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'reportedAt': !exists(json, 'reportedAt') ? undefined : (new Date(json['reportedAt'])),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'videoId': !exists(json, 'videoId') ? undefined : json['videoId'],
        'video': !exists(json, 'video') ? undefined : VideoFromJSON(json['video']),
        'streamSessionId': !exists(json, 'streamSessionId') ? undefined : json['streamSessionId'],
        'streamSession': !exists(json, 'streamSession') ? undefined : StreamSessionFromJSON(json['streamSession']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'channelName': !exists(json, 'channelName') ? undefined : json['channelName'],
        'reporterUserName': !exists(json, 'reporterUserName') ? undefined : json['reporterUserName'],
        'streamTitle': !exists(json, 'streamTitle') ? undefined : json['streamTitle'],
    };
}

export function ContentReportToJSON(value?: ContentReport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'reason': ReportReasonToJSON(value.reason),
        'reasonDescription': value.reasonDescription,
        'description': value.description,
        'reportedAt': value.reportedAt === undefined ? undefined : (value.reportedAt.toISOString()),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'videoId': value.videoId,
        'video': VideoToJSON(value.video),
        'streamSessionId': value.streamSessionId,
        'streamSession': StreamSessionToJSON(value.streamSession),
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'channelName': value.channelName,
        'reporterUserName': value.reporterUserName,
        'streamTitle': value.streamTitle,
    };
}

