/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetStreamDetailResponse
 */
export interface GetStreamDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof GetStreamDetailResponse
     */
    channelId: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetStreamDetailResponse
     */
    isLive: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetStreamDetailResponse
     */
    followerCount?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetStreamDetailResponse
     */
    viewerCount?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof GetStreamDetailResponse
     */
    startTime?: Date | null;
}

/**
 * Check if a given object implements the GetStreamDetailResponse interface.
 */
export function instanceOfGetStreamDetailResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "channelId" in value;
    isInstance = isInstance && "isLive" in value;

    return isInstance;
}

export function GetStreamDetailResponseFromJSON(json: any): GetStreamDetailResponse {
    return GetStreamDetailResponseFromJSONTyped(json, false);
}

export function GetStreamDetailResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamDetailResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelId': json['channelId'],
        'isLive': json['isLive'],
        'followerCount': !exists(json, 'followerCount') ? undefined : json['followerCount'],
        'viewerCount': !exists(json, 'viewerCount') ? undefined : json['viewerCount'],
        'startTime': !exists(json, 'startTime') ? undefined : (json['startTime'] === null ? null : new Date(json['startTime'])),
    };
}

export function GetStreamDetailResponseToJSON(value?: GetStreamDetailResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelId': value.channelId,
        'isLive': value.isLive,
        'followerCount': value.followerCount,
        'viewerCount': value.viewerCount,
        'startTime': value.startTime === undefined ? undefined : (value.startTime === null ? null : value.startTime.toISOString()),
    };
}

