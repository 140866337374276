/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {Date}
     * @memberof Tag
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Tag
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    tagKey: string;
}

/**
 * Check if a given object implements the Tag interface.
 */
export function instanceOfTag(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "tagKey" in value;

    return isInstance;
}

export function TagFromJSON(json: any): Tag {
    return TagFromJSONTyped(json, false);
}

export function TagFromJSONTyped(json: any, ignoreDiscriminator: boolean): Tag {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'tagKey': json['tagKey'],
    };
}

export function TagToJSON(value?: Tag | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'id': value.id,
        'tagKey': value.tagKey,
    };
}

