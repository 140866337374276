import { styled } from '../../../stitches'
import VerifiedIcon from '../../../assets/icons/VerifiedIcon.svg?react'

export function VerifiedBadge(props: { isVerified: boolean }) {
  if (!props.isVerified) return
  return (
    <VerifiedIconWrapper>
      <VerifiedIcon />
    </VerifiedIconWrapper>
  )
}
const VerifiedIconWrapper = styled('span', {
  color: '#53fc18',
  width: '24px',
  height: '24px',
})
