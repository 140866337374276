/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetChannelSearchResponse
 */
export interface GetChannelSearchResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChannelSearchResponse
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof GetChannelSearchResponse
     */
    displayName: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelSearchResponse
     */
    isLive: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelSearchResponse
     */
    isVerified: boolean;
}

/**
 * Check if a given object implements the GetChannelSearchResponse interface.
 */
export function instanceOfGetChannelSearchResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "slug" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "isLive" in value;
    isInstance = isInstance && "isVerified" in value;

    return isInstance;
}

export function GetChannelSearchResponseFromJSON(json: any): GetChannelSearchResponse {
    return GetChannelSearchResponseFromJSONTyped(json, false);
}

export function GetChannelSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slug': json['slug'],
        'displayName': json['displayName'],
        'isLive': json['isLive'],
        'isVerified': json['isVerified'],
    };
}

export function GetChannelSearchResponseToJSON(value?: GetChannelSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'displayName': value.displayName,
        'isLive': value.isLive,
        'isVerified': value.isVerified,
    };
}

