/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageVariantResponse } from './ImageVariantResponse';
import {
    ImageVariantResponseFromJSON,
    ImageVariantResponseFromJSONTyped,
    ImageVariantResponseToJSON,
} from './ImageVariantResponse';

/**
 * 
 * @export
 * @interface ImageVariantsResponse
 */
export interface ImageVariantsResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageVariantsResponse
     */
    source: string;
    /**
     * 
     * @type {Array<ImageVariantResponse>}
     * @memberof ImageVariantsResponse
     */
    variants: Array<ImageVariantResponse>;
    /**
     * 
     * @type {string}
     * @memberof ImageVariantsResponse
     */
    readonly srcset: string;
}

/**
 * Check if a given object implements the ImageVariantsResponse interface.
 */
export function instanceOfImageVariantsResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "source" in value;
    isInstance = isInstance && "variants" in value;
    isInstance = isInstance && "srcset" in value;

    return isInstance;
}

export function ImageVariantsResponseFromJSON(json: any): ImageVariantsResponse {
    return ImageVariantsResponseFromJSONTyped(json, false);
}

export function ImageVariantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageVariantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'source': json['source'],
        'variants': ((json['variants'] as Array<any>).map(ImageVariantResponseFromJSON)),
        'srcset': json['srcset'],
    };
}

export function ImageVariantsResponseToJSON(value?: ImageVariantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'source': value.source,
        'variants': ((value.variants as Array<any>).map(ImageVariantResponseToJSON)),
    };
}

