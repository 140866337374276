/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface IVSStreamConfig
 */
export interface IVSStreamConfig {
    /**
     * 
     * @type {Date}
     * @memberof IVSStreamConfig
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IVSStreamConfig
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof IVSStreamConfig
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IVSStreamConfig
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    ingestEndpoint: string;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    streamKey: string;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    playbackUrl: string;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    ingestServer: string;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    arn: string;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    chatArn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    recordingConfigurationArn?: string | null;
    /**
     * 
     * @type {User}
     * @memberof IVSStreamConfig
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof IVSStreamConfig
     */
    channelName?: string | null;
}

/**
 * Check if a given object implements the IVSStreamConfig interface.
 */
export function instanceOfIVSStreamConfig(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "ingestEndpoint" in value;
    isInstance = isInstance && "streamKey" in value;
    isInstance = isInstance && "playbackUrl" in value;
    isInstance = isInstance && "ingestServer" in value;
    isInstance = isInstance && "arn" in value;

    return isInstance;
}

export function IVSStreamConfigFromJSON(json: any): IVSStreamConfig {
    return IVSStreamConfigFromJSONTyped(json, false);
}

export function IVSStreamConfigFromJSONTyped(json: any, ignoreDiscriminator: boolean): IVSStreamConfig {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'ingestEndpoint': json['ingestEndpoint'],
        'streamKey': json['streamKey'],
        'playbackUrl': json['playbackUrl'],
        'ingestServer': json['ingestServer'],
        'arn': json['arn'],
        'chatArn': !exists(json, 'chatArn') ? undefined : json['chatArn'],
        'recordingConfigurationArn': !exists(json, 'recordingConfigurationArn') ? undefined : json['recordingConfigurationArn'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'channelName': !exists(json, 'channelName') ? undefined : json['channelName'],
    };
}

export function IVSStreamConfigToJSON(value?: IVSStreamConfig | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'ingestEndpoint': value.ingestEndpoint,
        'streamKey': value.streamKey,
        'playbackUrl': value.playbackUrl,
        'ingestServer': value.ingestServer,
        'arn': value.arn,
        'chatArn': value.chatArn,
        'recordingConfigurationArn': value.recordingConfigurationArn,
        'user': UserToJSON(value.user),
        'channelName': value.channelName,
    };
}

