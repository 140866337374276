import { FallbackProps } from 'react-error-boundary'
import {
  Button,
  ErrorLayout,
  Message,
} from '../../components/Layout/ErrorLayout'
import useLanguage from '../../stores/useLanguage'

export function ErrorPage({ error, resetErrorBoundary }: FallbackProps) {
  const { t } = useLanguage()

  console.error('ErrorPage got this error:', error)

  return (
    <ErrorLayout>
      <Message>{t('general.technicalError')}</Message>
      <Button onClick={resetErrorBoundary}>{t('general.retry')}</Button>
    </ErrorLayout>
  )
}
