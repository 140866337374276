import { HelmetProvider } from 'react-helmet-async'
import * as RadixTooltip from '@radix-ui/react-tooltip'
import { ErrorBoundary } from 'react-error-boundary'
import { ErrorPage } from './shared/pages/error/GeneralErrorPage'
import { Router } from './Router'
import { Provider } from 'jotai'
import { store } from './store'
import { CustomSnackbarProvider } from './shared/components/Notification/CustomSnackProvider'
import { Confirmation } from './shared/components/Common/Confirmation'

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <HelmetProvider>
          <CustomSnackbarProvider>
            <RadixTooltip.Provider>
              <Router />
              <Confirmation />
            </RadixTooltip.Provider>
          </CustomSnackbarProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </Provider>
  )
}

export default App
