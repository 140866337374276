/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetEmoteResponse } from './GetEmoteResponse';
import {
    GetEmoteResponseFromJSON,
    GetEmoteResponseFromJSONTyped,
    GetEmoteResponseToJSON,
} from './GetEmoteResponse';

/**
 * 
 * @export
 * @interface GetEmotesResponse
 */
export interface GetEmotesResponse {
    /**
     * 
     * @type {Array<GetEmoteResponse>}
     * @memberof GetEmotesResponse
     */
    customEmotes: Array<GetEmoteResponse>;
}

/**
 * Check if a given object implements the GetEmotesResponse interface.
 */
export function instanceOfGetEmotesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "customEmotes" in value;

    return isInstance;
}

export function GetEmotesResponseFromJSON(json: any): GetEmotesResponse {
    return GetEmotesResponseFromJSONTyped(json, false);
}

export function GetEmotesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetEmotesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'customEmotes': ((json['customEmotes'] as Array<any>).map(GetEmoteResponseFromJSON)),
    };
}

export function GetEmotesResponseToJSON(value?: GetEmotesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'customEmotes': ((value.customEmotes as Array<any>).map(GetEmoteResponseToJSON)),
    };
}

