/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const SubscriptionStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type SubscriptionStatus = typeof SubscriptionStatus[keyof typeof SubscriptionStatus];


export function SubscriptionStatusFromJSON(json: any): SubscriptionStatus {
    return SubscriptionStatusFromJSONTyped(json, false);
}

export function SubscriptionStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): SubscriptionStatus {
    return json as SubscriptionStatus;
}

export function SubscriptionStatusToJSON(value?: SubscriptionStatus | null): any {
    return value as any;
}

