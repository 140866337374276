import { Navbar } from './Header/Navbar'
import Footer from './Footer'
import Tabbar from './Tabbar'
import { useLocation } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import { styled } from '@stitches/react'

type LayoutProps = {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const location = useLocation()
  const navbarRef = useRef<HTMLDivElement>(null)
  const [navbarHeight, setNavbarHeight] = useState<number>(0)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  const handleResize = () => {
    setNavbarHeight(navbarRef.current?.offsetHeight || 0)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <Container>
      <Navbar ref={navbarRef} />
      <ContentContainer style={{ marginTop: `${navbarHeight}px` }}>
        {children}
      </ContentContainer>
      <Footer />
      <Tabbar />
    </Container>
  )
}

const Container = styled('div', {
  minHeight: '100vh',
})

const ContentContainer = styled('div', {
  paddingBottom: '1rem',
  minHeight: '100vh',
  color: 'white',
})

export default Layout
