/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChannelSubscriptionItem } from './ChannelSubscriptionItem';
import {
    ChannelSubscriptionItemFromJSON,
    ChannelSubscriptionItemFromJSONTyped,
    ChannelSubscriptionItemToJSON,
} from './ChannelSubscriptionItem';

/**
 * 
 * @export
 * @interface ChannelSubscriptionItemHistory
 */
export interface ChannelSubscriptionItemHistory {
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscriptionItemHistory
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItemHistory
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscriptionItemHistory
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItemHistory
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItemHistory
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItemHistory
     */
    subscriptionItemId?: string;
    /**
     * 
     * @type {ChannelSubscriptionItem}
     * @memberof ChannelSubscriptionItemHistory
     */
    subscriptionItem?: ChannelSubscriptionItem;
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscriptionItemHistory
     */
    transactionDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItemHistory
     */
    action?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItemHistory
     */
    performedBy?: string | null;
}

/**
 * Check if a given object implements the ChannelSubscriptionItemHistory interface.
 */
export function instanceOfChannelSubscriptionItemHistory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelSubscriptionItemHistoryFromJSON(json: any): ChannelSubscriptionItemHistory {
    return ChannelSubscriptionItemHistoryFromJSONTyped(json, false);
}

export function ChannelSubscriptionItemHistoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelSubscriptionItemHistory {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'subscriptionItemId': !exists(json, 'subscriptionItemId') ? undefined : json['subscriptionItemId'],
        'subscriptionItem': !exists(json, 'subscriptionItem') ? undefined : ChannelSubscriptionItemFromJSON(json['subscriptionItem']),
        'transactionDate': !exists(json, 'transactionDate') ? undefined : (new Date(json['transactionDate'])),
        'action': !exists(json, 'action') ? undefined : json['action'],
        'performedBy': !exists(json, 'performedBy') ? undefined : json['performedBy'],
    };
}

export function ChannelSubscriptionItemHistoryToJSON(value?: ChannelSubscriptionItemHistory | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'id': value.id,
        'subscriptionItemId': value.subscriptionItemId,
        'subscriptionItem': ChannelSubscriptionItemToJSON(value.subscriptionItem),
        'transactionDate': value.transactionDate === undefined ? undefined : (value.transactionDate.toISOString()),
        'action': value.action,
        'performedBy': value.performedBy,
    };
}

