import { styled } from '../../../stitches'

export default function HighlightedText(props: { text: string; word: string }) {
  const highlightIndex = props.text
    .toLocaleLowerCase()
    .indexOf(props.word.toLocaleLowerCase())
  if (highlightIndex < 0) return props.text
  const firstPart = props.text.substring(0, highlightIndex)
  const secondPart = props.text.substring(
    highlightIndex + props.word.length,
    props.text.length
  )
  const highlightedPart = props.text.substring(
    highlightIndex,
    highlightIndex + props.word.length
  )
  return (
    <>
      {firstPart}
      <BoldText>{highlightedPart}</BoldText>
      {secondPart}
    </>
  )
}
const BoldText = styled('b', {
  fontWeight: 900,
})
