/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ActivityFeedEvent } from './ActivityFeedEvent';
import {
    ActivityFeedEventFromJSON,
    ActivityFeedEventFromJSONTyped,
    ActivityFeedEventToJSON,
} from './ActivityFeedEvent';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import type { ChannelBannedUser } from './ChannelBannedUser';
import {
    ChannelBannedUserFromJSON,
    ChannelBannedUserFromJSONTyped,
    ChannelBannedUserToJSON,
} from './ChannelBannedUser';
import type { ChannelFollower } from './ChannelFollower';
import {
    ChannelFollowerFromJSON,
    ChannelFollowerFromJSONTyped,
    ChannelFollowerToJSON,
} from './ChannelFollower';
import type { ChannelModerator } from './ChannelModerator';
import {
    ChannelModeratorFromJSON,
    ChannelModeratorFromJSONTyped,
    ChannelModeratorToJSON,
} from './ChannelModerator';
import type { ChannelPromoPanel } from './ChannelPromoPanel';
import {
    ChannelPromoPanelFromJSON,
    ChannelPromoPanelFromJSONTyped,
    ChannelPromoPanelToJSON,
} from './ChannelPromoPanel';
import type { ChannelSubscription } from './ChannelSubscription';
import {
    ChannelSubscriptionFromJSON,
    ChannelSubscriptionFromJSONTyped,
    ChannelSubscriptionToJSON,
} from './ChannelSubscription';
import type { ChannelSubscriptionItem } from './ChannelSubscriptionItem';
import {
    ChannelSubscriptionItemFromJSON,
    ChannelSubscriptionItemFromJSONTyped,
    ChannelSubscriptionItemToJSON,
} from './ChannelSubscriptionItem';
import type { ChatMessage } from './ChatMessage';
import {
    ChatMessageFromJSON,
    ChatMessageFromJSONTyped,
    ChatMessageToJSON,
} from './ChatMessage';
import type { ContentReport } from './ContentReport';
import {
    ContentReportFromJSON,
    ContentReportFromJSONTyped,
    ContentReportToJSON,
} from './ContentReport';
import type { CustomEmote } from './CustomEmote';
import {
    CustomEmoteFromJSON,
    CustomEmoteFromJSONTyped,
    CustomEmoteToJSON,
} from './CustomEmote';
import type { StreamSession } from './StreamSession';
import {
    StreamSessionFromJSON,
    StreamSessionFromJSONTyped,
    StreamSessionToJSON,
} from './StreamSession';
import type { SubscriptionBadge } from './SubscriptionBadge';
import {
    SubscriptionBadgeFromJSON,
    SubscriptionBadgeFromJSONTyped,
    SubscriptionBadgeToJSON,
} from './SubscriptionBadge';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';
import type { Video } from './Video';
import {
    VideoFromJSON,
    VideoFromJSONTyped,
    VideoToJSON,
} from './Video';

/**
 * 
 * @export
 * @interface Channel
 */
export interface Channel {
    /**
     * 
     * @type {Date}
     * @memberof Channel
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Channel
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Channel
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    slug: string;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Channel
     */
    bannerPictureId?: string | null;
    /**
     * 
     * @type {Asset}
     * @memberof Channel
     */
    bannerPicture?: Asset;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    isVerified?: boolean;
    /**
     * 
     * @type {User}
     * @memberof Channel
     */
    user?: User;
    /**
     * 
     * @type {Date}
     * @memberof Channel
     */
    bannedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    isFeatured?: boolean | null;
    /**
     * 
     * @type {Array<ChannelFollower>}
     * @memberof Channel
     */
    followers?: Array<ChannelFollower> | null;
    /**
     * 
     * @type {Array<ChannelPromoPanel>}
     * @memberof Channel
     */
    promoPanels?: Array<ChannelPromoPanel> | null;
    /**
     * 
     * @type {Array<ChannelModerator>}
     * @memberof Channel
     */
    moderators?: Array<ChannelModerator> | null;
    /**
     * 
     * @type {Array<StreamSession>}
     * @memberof Channel
     */
    streamSessions?: Array<StreamSession> | null;
    /**
     * 
     * @type {Array<SubscriptionBadge>}
     * @memberof Channel
     */
    subscriptionBadges?: Array<SubscriptionBadge> | null;
    /**
     * 
     * @type {Array<CustomEmote>}
     * @memberof Channel
     */
    customEmotes?: Array<CustomEmote> | null;
    /**
     * 
     * @type {Array<ActivityFeedEvent>}
     * @memberof Channel
     */
    activityFeedEvents?: Array<ActivityFeedEvent> | null;
    /**
     * 
     * @type {Array<Video>}
     * @memberof Channel
     */
    videos?: Array<Video> | null;
    /**
     * 
     * @type {Array<ContentReport>}
     * @memberof Channel
     */
    contentReports?: Array<ContentReport> | null;
    /**
     * 
     * @type {Array<ChatMessage>}
     * @memberof Channel
     */
    chatMessages?: Array<ChatMessage> | null;
    /**
     * 
     * @type {Array<ChannelBannedUser>}
     * @memberof Channel
     */
    bannedUsers?: Array<ChannelBannedUser> | null;
    /**
     * 
     * @type {Array<ChannelSubscription>}
     * @memberof Channel
     */
    subscriptions?: Array<ChannelSubscription> | null;
    /**
     * 
     * @type {Array<ChannelSubscriptionItem>}
     * @memberof Channel
     */
    subscriptionItems?: Array<ChannelSubscriptionItem> | null;
    /**
     * 
     * @type {boolean}
     * @memberof Channel
     */
    readonly isBanned?: boolean;
    /**
     * 
     * @type {StreamSession}
     * @memberof Channel
     */
    livestream?: StreamSession;
}

/**
 * Check if a given object implements the Channel interface.
 */
export function instanceOfChannel(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "slug" in value;

    return isInstance;
}

export function ChannelFromJSON(json: any): Channel {
    return ChannelFromJSONTyped(json, false);
}

export function ChannelFromJSONTyped(json: any, ignoreDiscriminator: boolean): Channel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': json['slug'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'bannerPictureId': !exists(json, 'bannerPictureId') ? undefined : json['bannerPictureId'],
        'bannerPicture': !exists(json, 'bannerPicture') ? undefined : AssetFromJSON(json['bannerPicture']),
        'isVerified': !exists(json, 'isVerified') ? undefined : json['isVerified'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'bannedAt': !exists(json, 'bannedAt') ? undefined : (json['bannedAt'] === null ? null : new Date(json['bannedAt'])),
        'isFeatured': !exists(json, 'isFeatured') ? undefined : json['isFeatured'],
        'followers': !exists(json, 'followers') ? undefined : (json['followers'] === null ? null : (json['followers'] as Array<any>).map(ChannelFollowerFromJSON)),
        'promoPanels': !exists(json, 'promoPanels') ? undefined : (json['promoPanels'] === null ? null : (json['promoPanels'] as Array<any>).map(ChannelPromoPanelFromJSON)),
        'moderators': !exists(json, 'moderators') ? undefined : (json['moderators'] === null ? null : (json['moderators'] as Array<any>).map(ChannelModeratorFromJSON)),
        'streamSessions': !exists(json, 'streamSessions') ? undefined : (json['streamSessions'] === null ? null : (json['streamSessions'] as Array<any>).map(StreamSessionFromJSON)),
        'subscriptionBadges': !exists(json, 'subscriptionBadges') ? undefined : (json['subscriptionBadges'] === null ? null : (json['subscriptionBadges'] as Array<any>).map(SubscriptionBadgeFromJSON)),
        'customEmotes': !exists(json, 'customEmotes') ? undefined : (json['customEmotes'] === null ? null : (json['customEmotes'] as Array<any>).map(CustomEmoteFromJSON)),
        'activityFeedEvents': !exists(json, 'activityFeedEvents') ? undefined : (json['activityFeedEvents'] === null ? null : (json['activityFeedEvents'] as Array<any>).map(ActivityFeedEventFromJSON)),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(VideoFromJSON)),
        'contentReports': !exists(json, 'contentReports') ? undefined : (json['contentReports'] === null ? null : (json['contentReports'] as Array<any>).map(ContentReportFromJSON)),
        'chatMessages': !exists(json, 'chatMessages') ? undefined : (json['chatMessages'] === null ? null : (json['chatMessages'] as Array<any>).map(ChatMessageFromJSON)),
        'bannedUsers': !exists(json, 'bannedUsers') ? undefined : (json['bannedUsers'] === null ? null : (json['bannedUsers'] as Array<any>).map(ChannelBannedUserFromJSON)),
        'subscriptions': !exists(json, 'subscriptions') ? undefined : (json['subscriptions'] === null ? null : (json['subscriptions'] as Array<any>).map(ChannelSubscriptionFromJSON)),
        'subscriptionItems': !exists(json, 'subscriptionItems') ? undefined : (json['subscriptionItems'] === null ? null : (json['subscriptionItems'] as Array<any>).map(ChannelSubscriptionItemFromJSON)),
        'isBanned': !exists(json, 'isBanned') ? undefined : json['isBanned'],
        'livestream': !exists(json, 'livestream') ? undefined : StreamSessionFromJSON(json['livestream']),
    };
}

export function ChannelToJSON(value?: Channel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'slug': value.slug,
        'description': value.description,
        'bannerPictureId': value.bannerPictureId,
        'bannerPicture': AssetToJSON(value.bannerPicture),
        'isVerified': value.isVerified,
        'user': UserToJSON(value.user),
        'bannedAt': value.bannedAt === undefined ? undefined : (value.bannedAt === null ? null : value.bannedAt.toISOString()),
        'isFeatured': value.isFeatured,
        'followers': value.followers === undefined ? undefined : (value.followers === null ? null : (value.followers as Array<any>).map(ChannelFollowerToJSON)),
        'promoPanels': value.promoPanels === undefined ? undefined : (value.promoPanels === null ? null : (value.promoPanels as Array<any>).map(ChannelPromoPanelToJSON)),
        'moderators': value.moderators === undefined ? undefined : (value.moderators === null ? null : (value.moderators as Array<any>).map(ChannelModeratorToJSON)),
        'streamSessions': value.streamSessions === undefined ? undefined : (value.streamSessions === null ? null : (value.streamSessions as Array<any>).map(StreamSessionToJSON)),
        'subscriptionBadges': value.subscriptionBadges === undefined ? undefined : (value.subscriptionBadges === null ? null : (value.subscriptionBadges as Array<any>).map(SubscriptionBadgeToJSON)),
        'customEmotes': value.customEmotes === undefined ? undefined : (value.customEmotes === null ? null : (value.customEmotes as Array<any>).map(CustomEmoteToJSON)),
        'activityFeedEvents': value.activityFeedEvents === undefined ? undefined : (value.activityFeedEvents === null ? null : (value.activityFeedEvents as Array<any>).map(ActivityFeedEventToJSON)),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(VideoToJSON)),
        'contentReports': value.contentReports === undefined ? undefined : (value.contentReports === null ? null : (value.contentReports as Array<any>).map(ContentReportToJSON)),
        'chatMessages': value.chatMessages === undefined ? undefined : (value.chatMessages === null ? null : (value.chatMessages as Array<any>).map(ChatMessageToJSON)),
        'bannedUsers': value.bannedUsers === undefined ? undefined : (value.bannedUsers === null ? null : (value.bannedUsers as Array<any>).map(ChannelBannedUserToJSON)),
        'subscriptions': value.subscriptions === undefined ? undefined : (value.subscriptions === null ? null : (value.subscriptions as Array<any>).map(ChannelSubscriptionToJSON)),
        'subscriptionItems': value.subscriptionItems === undefined ? undefined : (value.subscriptionItems === null ? null : (value.subscriptionItems as Array<any>).map(ChannelSubscriptionItemToJSON)),
        'livestream': StreamSessionToJSON(value.livestream),
    };
}

