/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateSocialUrlsRequest
 */
export interface UpdateSocialUrlsRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialUrlsRequest
     */
    twitterUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialUrlsRequest
     */
    facebookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialUrlsRequest
     */
    instagramUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialUrlsRequest
     */
    youtubeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialUrlsRequest
     */
    discordUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSocialUrlsRequest
     */
    tikTokUrl?: string | null;
}

/**
 * Check if a given object implements the UpdateSocialUrlsRequest interface.
 */
export function instanceOfUpdateSocialUrlsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UpdateSocialUrlsRequestFromJSON(json: any): UpdateSocialUrlsRequest {
    return UpdateSocialUrlsRequestFromJSONTyped(json, false);
}

export function UpdateSocialUrlsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateSocialUrlsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'twitterUrl': !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'facebookUrl': !exists(json, 'facebookUrl') ? undefined : json['facebookUrl'],
        'instagramUrl': !exists(json, 'instagramUrl') ? undefined : json['instagramUrl'],
        'youtubeUrl': !exists(json, 'youtubeUrl') ? undefined : json['youtubeUrl'],
        'discordUrl': !exists(json, 'discordUrl') ? undefined : json['discordUrl'],
        'tikTokUrl': !exists(json, 'tikTokUrl') ? undefined : json['tikTokUrl'],
    };
}

export function UpdateSocialUrlsRequestToJSON(value?: UpdateSocialUrlsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'twitterUrl': value.twitterUrl,
        'facebookUrl': value.facebookUrl,
        'instagramUrl': value.instagramUrl,
        'youtubeUrl': value.youtubeUrl,
        'discordUrl': value.discordUrl,
        'tikTokUrl': value.tikTokUrl,
    };
}

