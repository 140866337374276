/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ImageVariantsResponse } from './ImageVariantsResponse';
import {
    ImageVariantsResponseFromJSON,
    ImageVariantsResponseFromJSONTyped,
    ImageVariantsResponseToJSON,
} from './ImageVariantsResponse';

/**
 * 
 * @export
 * @interface GetChannelPromoPanelResponse
 */
export interface GetChannelPromoPanelResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChannelPromoPanelResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GetChannelPromoPanelResponse
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChannelPromoPanelResponse
     */
    description?: string | null;
    /**
     * 
     * @type {ImageVariantsResponse}
     * @memberof GetChannelPromoPanelResponse
     */
    image?: ImageVariantsResponse;
    /**
     * 
     * @type {string}
     * @memberof GetChannelPromoPanelResponse
     */
    imageLinkUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetChannelPromoPanelResponse
     */
    order?: number;
}

/**
 * Check if a given object implements the GetChannelPromoPanelResponse interface.
 */
export function instanceOfGetChannelPromoPanelResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetChannelPromoPanelResponseFromJSON(json: any): GetChannelPromoPanelResponse {
    return GetChannelPromoPanelResponseFromJSONTyped(json, false);
}

export function GetChannelPromoPanelResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelPromoPanelResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'image': !exists(json, 'image') ? undefined : ImageVariantsResponseFromJSON(json['image']),
        'imageLinkUrl': !exists(json, 'imageLinkUrl') ? undefined : json['imageLinkUrl'],
        'order': !exists(json, 'order') ? undefined : json['order'],
    };
}

export function GetChannelPromoPanelResponseToJSON(value?: GetChannelPromoPanelResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'image': ImageVariantsResponseToJSON(value.image),
        'imageLinkUrl': value.imageLinkUrl,
        'order': value.order,
    };
}

