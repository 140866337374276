import {
  ChevronLeftIcon,
  ChevronRightIcon,
  HeartFilledIcon,
  LinkBreak1Icon,
} from '@radix-ui/react-icons'
import { styled } from '../../../stitches'
import { Avatar } from '../../../OnlyStream/components/Channel/Avatar'
import { Link } from 'react-router-dom'
import { routes } from '../../../routes'
import { useQuery } from '../../../shared/utils/AsyncUtils/useQuery'
import { useApi } from '../../../shared/utils/ApiContext'
import useLanguage from '../../../shared/stores/useLanguage'
import { LiveBadge } from '../Channel/LiveBadge'
import { useAuth0 } from '@auth0/auth0-react'
import { Skeleton } from '../../../shared/components/Common/Skeleton'

type FollowedChannelsProps = {
  handleCollapse: () => void
  collapsed: boolean
}

export function FollowedChannels({
  handleCollapse,
  collapsed,
}: FollowedChannelsProps) {
  const { t } = useLanguage()
  return (
    <Container collapsed={collapsed}>
      <SectionContainer>
        <SectionHeader collapsed={collapsed}>
          {!collapsed && (
            <SectionTitle>{t('followingPage.title')}</SectionTitle>
          )}
          <SectionButton onClick={() => handleCollapse()}>
            {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </SectionButton>
          {collapsed && (
            <SectionIcon>
              <HeartFilledIcon />
            </SectionIcon>
          )}
        </SectionHeader>
        <SectionBody collapsed={collapsed}>
          <FollowedChannelsList collapsed={collapsed} />
        </SectionBody>
      </SectionContainer>
    </Container>
  )
}

function FollowedChannelsList(props: { collapsed: boolean }) {
  const { t } = useLanguage()
  const { isAuthenticated } = useAuth0()
  const { channelsApi } = useApi()
  const followedChannels = useQuery(async () => {
    if (!isAuthenticated) return { channels: [] }
    return await channelsApi.channelsFollowedGet()
  }, [isAuthenticated])

  if (followedChannels.state === 'loading') {
    return (
      <div>
        <Skeleton type="text" />
        <Skeleton type="text" />
        <Skeleton type="text" />
      </div>
    )
  }

  if (
    followedChannels == null ||
    followedChannels.data == null ||
    (!followedChannels.data.channels.length && !props.collapsed)
  ) {
    return <EmptyMessage>{t('followingPage.noFolloweds')}</EmptyMessage>
  }

  return followedChannels.data.channels.map((channel) => {
    return (
      <ChannelContainer
        to={routes.channel.home(channel.slug)}
        key={channel.slug}
        collapsed={props.collapsed}
      >
        <ChannelAvatar>
          <Avatar
            isLive={channel.isLive ?? false}
            url={channel.profilePicture?.source}
            size={'xs'}
          />
        </ChannelAvatar>
        {!props.collapsed && (
          <>
            <ChannelName>{channel.displayName}</ChannelName>
            <ChannelStatus>
              {channel.isLive ? (
                <LiveBadge isLive={true} />
              ) : (
                <LinkBreak1Icon />
              )}
            </ChannelStatus>
          </>
        )}
      </ChannelContainer>
    )
  })
}
const EmptyMessage = styled('div', {
  fontSize: '12px',
  color: 'rgb(146, 158, 166)',
})

const Container = styled('div', {
  background: '#191b1f',
  borderRight: '1px solid #24272c',
  padding: '1rem',
  transition: 'padding 300ms',
  maxWidth: '15em',
  variants: {
    collapsed: {
      true: {
        padding: '0.5rem',
      },
    },
  },
})
const SectionContainer = styled('div', {})
const SectionHeader = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: 'calc(-0.75rem - 4px)',
  padding: '0.75rem',
  transition: 'all 250ms',
  variants: {
    collapsed: {
      true: {
        margin: 'calc(-0.25rem - 4px)',
        padding: '0.75rem 0.25rem',
        flexFlow: 'column',
        gap: '0.75rem',
      },
    },
  },
})
const SectionIcon = styled('div', {
  color: '#6B888C',
  display: 'flex',
  '& > svg': {
    width: '1.2rem',
    height: '1.2rem',
  },
})
const SectionTitle = styled('div', {
  flex: 1,
  fontWeight: '900',
  fontSize: '14px',
})
const SectionButton = styled('button', {
  flex: 0,
  color: '#BBBBBB',
  display: 'flex',
  cursor: 'pointer',
  minHeight: '1.8rem',
  minWidth: '1.8rem',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '8px',
  transition: 'opacity 200ms',
  border: '1px solid rgb(41 41 41)',
  background: '#FFFFFF09',
  opacity: '0.5',
  '& > svg': {
    width: '1.2rem',
    height: '1.2rem',
  },
  '&:hover': {
    opacity: '1',
  },
})
const SectionBody = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  padding: '0.5rem 0',
  paddingTop: '0.75rem',
  variants: {
    collapsed: {
      true: { padding: '0.75rem 0' },
    },
  },
})
const ChannelContainer = styled(Link, {
  display: 'flex',
  padding: '0.5rem',
  alignItems: 'center',
  gap: '0.725rem',
  borderRadius: '0.5rem',
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'white',
  transition: 'background 200ms',
  maxWidth: '16em',
  '&:hover': {
    background: '#24272c',
  },
  variants: {
    collapsed: {
      true: {
        justifyContent: 'center',
      },
    },
  },
})
const ChannelAvatar = styled('div', {
  flex: 0,
})
const ChannelName = styled('div', {
  flex: 1,
  fontWeight: '500',
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
})
const ChannelStatus = styled('div', {
  flex: 0,
  display: 'flex',
  color: '#c3f7f6',
  whiteSpace: 'nowrap',
  justifyContent: 'flex-end',
})
