/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseUserResponse } from './BaseUserResponse';
import {
    BaseUserResponseFromJSON,
    BaseUserResponseFromJSONTyped,
    BaseUserResponseToJSON,
} from './BaseUserResponse';
import type { GetChannelResponse } from './GetChannelResponse';
import {
    GetChannelResponseFromJSON,
    GetChannelResponseFromJSONTyped,
    GetChannelResponseToJSON,
} from './GetChannelResponse';

/**
 * 
 * @export
 * @interface GetStreamerInfoResponse
 */
export interface GetStreamerInfoResponse {
    /**
     * 
     * @type {BaseUserResponse}
     * @memberof GetStreamerInfoResponse
     */
    user: BaseUserResponse;
    /**
     * 
     * @type {GetChannelResponse}
     * @memberof GetStreamerInfoResponse
     */
    channel: GetChannelResponse;
}

/**
 * Check if a given object implements the GetStreamerInfoResponse interface.
 */
export function instanceOfGetStreamerInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "user" in value;
    isInstance = isInstance && "channel" in value;

    return isInstance;
}

export function GetStreamerInfoResponseFromJSON(json: any): GetStreamerInfoResponse {
    return GetStreamerInfoResponseFromJSONTyped(json, false);
}

export function GetStreamerInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetStreamerInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': BaseUserResponseFromJSON(json['user']),
        'channel': GetChannelResponseFromJSON(json['channel']),
    };
}

export function GetStreamerInfoResponseToJSON(value?: GetStreamerInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': BaseUserResponseToJSON(value.user),
        'channel': GetChannelResponseToJSON(value.channel),
    };
}

