import { useEffect, useState } from 'react'

export function useDebouncedValue(currentValue: string, ms: number = 300) {
  const [value, setValue] = useState(currentValue)

  useEffect(() => {
    // clean value instantly
    if (currentValue === '') setValue('')

    const timeout = setTimeout(() => {
      setValue(currentValue)
    }, ms)

    return () => {
      clearInterval(timeout)
    }
  }, [currentValue, ms])

  return value
}
