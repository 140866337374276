/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddBannedUserByDisplayNameRequest,
  ApiErrorResponse,
  RemoveBannedUserByDisplayNameRequest,
} from '../models/index';
import {
    AddBannedUserByDisplayNameRequestFromJSON,
    AddBannedUserByDisplayNameRequestToJSON,
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    RemoveBannedUserByDisplayNameRequestFromJSON,
    RemoveBannedUserByDisplayNameRequestToJSON,
} from '../models/index';

export interface ChannelbanChannelsChannelIdBansByDisplayNameDeleteRequest {
    channelId: string;
    removeBannedUserByDisplayNameRequest?: RemoveBannedUserByDisplayNameRequest;
}

export interface ChannelbanChannelsChannelIdBansByDisplayNamePostRequest {
    channelId: string;
    addBannedUserByDisplayNameRequest?: AddBannedUserByDisplayNameRequest;
}

export interface ChannelbanChannelsChannelIdTimeoutPostRequest {
    channelId: string;
    addBannedUserByDisplayNameRequest?: AddBannedUserByDisplayNameRequest;
}

/**
 * 
 */
export class ChannelBanApi extends runtime.BaseAPI {

    /**
     */
    async channelbanChannelsChannelIdBansByDisplayNameDeleteRaw(requestParameters: ChannelbanChannelsChannelIdBansByDisplayNameDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelbanChannelsChannelIdBansByDisplayNameDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channelban/channels/{channelId}/bans/by-display-name`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: RemoveBannedUserByDisplayNameRequestToJSON(requestParameters.removeBannedUserByDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelbanChannelsChannelIdBansByDisplayNameDelete(requestParameters: ChannelbanChannelsChannelIdBansByDisplayNameDeleteRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelbanChannelsChannelIdBansByDisplayNameDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelbanChannelsChannelIdBansByDisplayNamePostRaw(requestParameters: ChannelbanChannelsChannelIdBansByDisplayNamePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelbanChannelsChannelIdBansByDisplayNamePost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channelban/channels/{channelId}/bans/by-display-name`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBannedUserByDisplayNameRequestToJSON(requestParameters.addBannedUserByDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelbanChannelsChannelIdBansByDisplayNamePost(requestParameters: ChannelbanChannelsChannelIdBansByDisplayNamePostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelbanChannelsChannelIdBansByDisplayNamePostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async channelbanChannelsChannelIdTimeoutPostRaw(requestParameters: ChannelbanChannelsChannelIdTimeoutPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.channelId === null || requestParameters.channelId === undefined) {
            throw new runtime.RequiredError('channelId','Required parameter requestParameters.channelId was null or undefined when calling channelbanChannelsChannelIdTimeoutPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/channelban/channels/{channelId}/timeout`.replace(`{${"channelId"}}`, encodeURIComponent(String(requestParameters.channelId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddBannedUserByDisplayNameRequestToJSON(requestParameters.addBannedUserByDisplayNameRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async channelbanChannelsChannelIdTimeoutPost(requestParameters: ChannelbanChannelsChannelIdTimeoutPostRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.channelbanChannelsChannelIdTimeoutPostRaw(requestParameters, initOverrides);
    }

}
