/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ActivityEventType = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3
} as const;
export type ActivityEventType = typeof ActivityEventType[keyof typeof ActivityEventType];


export function ActivityEventTypeFromJSON(json: any): ActivityEventType {
    return ActivityEventTypeFromJSONTyped(json, false);
}

export function ActivityEventTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivityEventType {
    return json as ActivityEventType;
}

export function ActivityEventTypeToJSON(value?: ActivityEventType | null): any {
    return value as any;
}

