/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { ChannelSubscriptionItemHistory } from './ChannelSubscriptionItemHistory';
import {
    ChannelSubscriptionItemHistoryFromJSON,
    ChannelSubscriptionItemHistoryFromJSONTyped,
    ChannelSubscriptionItemHistoryToJSON,
} from './ChannelSubscriptionItemHistory';
import type { SubscriptionCancellationReason } from './SubscriptionCancellationReason';
import {
    SubscriptionCancellationReasonFromJSON,
    SubscriptionCancellationReasonFromJSONTyped,
    SubscriptionCancellationReasonToJSON,
} from './SubscriptionCancellationReason';
import type { SubscriptionPeriodStatus } from './SubscriptionPeriodStatus';
import {
    SubscriptionPeriodStatusFromJSON,
    SubscriptionPeriodStatusFromJSONTyped,
    SubscriptionPeriodStatusToJSON,
} from './SubscriptionPeriodStatus';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ChannelSubscriptionItem
 */
export interface ChannelSubscriptionItem {
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscriptionItem
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItem
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscriptionItem
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItem
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItem
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof ChannelSubscriptionItem
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ChannelSubscriptionItem
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItem
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof ChannelSubscriptionItem
     */
    channel?: Channel;
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscriptionItem
     */
    startedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof ChannelSubscriptionItem
     */
    expiresAt?: Date;
    /**
     * 
     * @type {SubscriptionPeriodStatus}
     * @memberof ChannelSubscriptionItem
     */
    status?: SubscriptionPeriodStatus;
    /**
     * 
     * @type {SubscriptionCancellationReason}
     * @memberof ChannelSubscriptionItem
     */
    cancellationReason?: SubscriptionCancellationReason;
    /**
     * 
     * @type {string}
     * @memberof ChannelSubscriptionItem
     */
    operation?: string | null;
    /**
     * 
     * @type {Array<ChannelSubscriptionItemHistory>}
     * @memberof ChannelSubscriptionItem
     */
    history?: Array<ChannelSubscriptionItemHistory> | null;
}

/**
 * Check if a given object implements the ChannelSubscriptionItem interface.
 */
export function instanceOfChannelSubscriptionItem(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelSubscriptionItemFromJSON(json: any): ChannelSubscriptionItem {
    return ChannelSubscriptionItemFromJSONTyped(json, false);
}

export function ChannelSubscriptionItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelSubscriptionItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'startedAt': !exists(json, 'startedAt') ? undefined : (new Date(json['startedAt'])),
        'expiresAt': !exists(json, 'expiresAt') ? undefined : (new Date(json['expiresAt'])),
        'status': !exists(json, 'status') ? undefined : SubscriptionPeriodStatusFromJSON(json['status']),
        'cancellationReason': !exists(json, 'cancellationReason') ? undefined : SubscriptionCancellationReasonFromJSON(json['cancellationReason']),
        'operation': !exists(json, 'operation') ? undefined : json['operation'],
        'history': !exists(json, 'history') ? undefined : (json['history'] === null ? null : (json['history'] as Array<any>).map(ChannelSubscriptionItemHistoryFromJSON)),
    };
}

export function ChannelSubscriptionItemToJSON(value?: ChannelSubscriptionItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'id': value.id,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt.toISOString()),
        'expiresAt': value.expiresAt === undefined ? undefined : (value.expiresAt.toISOString()),
        'status': SubscriptionPeriodStatusToJSON(value.status),
        'cancellationReason': SubscriptionCancellationReasonToJSON(value.cancellationReason),
        'operation': value.operation,
        'history': value.history === undefined ? undefined : (value.history === null ? null : (value.history as Array<any>).map(ChannelSubscriptionItemHistoryToJSON)),
    };
}

