/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseCategoryResponse } from './BaseCategoryResponse';
import {
    BaseCategoryResponseFromJSON,
    BaseCategoryResponseFromJSONTyped,
    BaseCategoryResponseToJSON,
} from './BaseCategoryResponse';
import type { GetVideoItemResponse } from './GetVideoItemResponse';
import {
    GetVideoItemResponseFromJSON,
    GetVideoItemResponseFromJSONTyped,
    GetVideoItemResponseToJSON,
} from './GetVideoItemResponse';

/**
 * 
 * @export
 * @interface GetVideoCategoryItemResponse
 */
export interface GetVideoCategoryItemResponse {
    /**
     * 
     * @type {BaseCategoryResponse}
     * @memberof GetVideoCategoryItemResponse
     */
    category: BaseCategoryResponse;
    /**
     * 
     * @type {Array<GetVideoItemResponse>}
     * @memberof GetVideoCategoryItemResponse
     */
    videos?: Array<GetVideoItemResponse> | null;
}

/**
 * Check if a given object implements the GetVideoCategoryItemResponse interface.
 */
export function instanceOfGetVideoCategoryItemResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "category" in value;

    return isInstance;
}

export function GetVideoCategoryItemResponseFromJSON(json: any): GetVideoCategoryItemResponse {
    return GetVideoCategoryItemResponseFromJSONTyped(json, false);
}

export function GetVideoCategoryItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVideoCategoryItemResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'category': BaseCategoryResponseFromJSON(json['category']),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(GetVideoItemResponseFromJSON)),
    };
}

export function GetVideoCategoryItemResponseToJSON(value?: GetVideoCategoryItemResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'category': BaseCategoryResponseToJSON(value.category),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(GetVideoItemResponseToJSON)),
    };
}

