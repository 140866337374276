/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { ChannelBannedUser } from './ChannelBannedUser';
import {
    ChannelBannedUserFromJSON,
    ChannelBannedUserFromJSONTyped,
    ChannelBannedUserToJSON,
} from './ChannelBannedUser';
import type { ChannelFollower } from './ChannelFollower';
import {
    ChannelFollowerFromJSON,
    ChannelFollowerFromJSONTyped,
    ChannelFollowerToJSON,
} from './ChannelFollower';
import type { ChannelModerator } from './ChannelModerator';
import {
    ChannelModeratorFromJSON,
    ChannelModeratorFromJSONTyped,
    ChannelModeratorToJSON,
} from './ChannelModerator';
import type { ChannelSubscription } from './ChannelSubscription';
import {
    ChannelSubscriptionFromJSON,
    ChannelSubscriptionFromJSONTyped,
    ChannelSubscriptionToJSON,
} from './ChannelSubscription';
import type { ChannelSubscriptionItem } from './ChannelSubscriptionItem';
import {
    ChannelSubscriptionItemFromJSON,
    ChannelSubscriptionItemFromJSONTyped,
    ChannelSubscriptionItemToJSON,
} from './ChannelSubscriptionItem';
import type { ContentReport } from './ContentReport';
import {
    ContentReportFromJSON,
    ContentReportFromJSONTyped,
    ContentReportToJSON,
} from './ContentReport';
import type { IVSStreamConfig } from './IVSStreamConfig';
import {
    IVSStreamConfigFromJSON,
    IVSStreamConfigFromJSONTyped,
    IVSStreamConfigToJSON,
} from './IVSStreamConfig';
import type { Site } from './Site';
import {
    SiteFromJSON,
    SiteFromJSONTyped,
    SiteToJSON,
} from './Site';

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    normalizedDisplayName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    auth0Id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    twitterUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    facebookUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    instagramUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    youtubeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    discordUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    tikTokUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profilePictureId?: string | null;
    /**
     * 
     * @type {Asset}
     * @memberof User
     */
    profilePicture?: Asset;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    externalId: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof User
     */
    channel?: Channel;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    siteId?: string;
    /**
     * 
     * @type {Site}
     * @memberof User
     */
    site?: Site;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    ivsStreamConfigId?: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    lastDisplayNameChangeDate?: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    registrationCompletedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    color?: number;
    /**
     * 
     * @type {IVSStreamConfig}
     * @memberof User
     */
    ivsStreamConfig?: IVSStreamConfig;
    /**
     * 
     * @type {Array<ChannelFollower>}
     * @memberof User
     */
    followingChannels?: Array<ChannelFollower> | null;
    /**
     * 
     * @type {Array<ChannelModerator>}
     * @memberof User
     */
    moderatorChannels?: Array<ChannelModerator> | null;
    /**
     * 
     * @type {Array<ChannelBannedUser>}
     * @memberof User
     */
    bannedChannels?: Array<ChannelBannedUser> | null;
    /**
     * 
     * @type {Array<ChannelSubscription>}
     * @memberof User
     */
    channelSubscriptions?: Array<ChannelSubscription> | null;
    /**
     * 
     * @type {Array<ChannelSubscriptionItem>}
     * @memberof User
     */
    channelSubscriptionItems?: Array<ChannelSubscriptionItem> | null;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    readonly canChangeDisplayName?: boolean;
    /**
     * 
     * @type {Array<ContentReport>}
     * @memberof User
     */
    contentReports?: Array<ContentReport> | null;
}

/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "normalizedDisplayName" in value;
    isInstance = isInstance && "username" in value;
    isInstance = isInstance && "auth0Id" in value;
    isInstance = isInstance && "email" in value;
    isInstance = isInstance && "externalId" in value;

    return isInstance;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': json['id'],
        'displayName': json['displayName'],
        'normalizedDisplayName': json['normalizedDisplayName'],
        'username': json['username'],
        'auth0Id': json['auth0Id'],
        'email': json['email'],
        'twitterUrl': !exists(json, 'twitterUrl') ? undefined : json['twitterUrl'],
        'facebookUrl': !exists(json, 'facebookUrl') ? undefined : json['facebookUrl'],
        'instagramUrl': !exists(json, 'instagramUrl') ? undefined : json['instagramUrl'],
        'youtubeUrl': !exists(json, 'youtubeUrl') ? undefined : json['youtubeUrl'],
        'discordUrl': !exists(json, 'discordUrl') ? undefined : json['discordUrl'],
        'tikTokUrl': !exists(json, 'tikTokUrl') ? undefined : json['tikTokUrl'],
        'profilePictureId': !exists(json, 'profilePictureId') ? undefined : json['profilePictureId'],
        'profilePicture': !exists(json, 'profilePicture') ? undefined : AssetFromJSON(json['profilePicture']),
        'externalId': json['externalId'],
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'siteId': !exists(json, 'siteId') ? undefined : json['siteId'],
        'site': !exists(json, 'site') ? undefined : SiteFromJSON(json['site']),
        'ivsStreamConfigId': !exists(json, 'ivsStreamConfigId') ? undefined : json['ivsStreamConfigId'],
        'lastDisplayNameChangeDate': !exists(json, 'lastDisplayNameChangeDate') ? undefined : (json['lastDisplayNameChangeDate'] === null ? null : new Date(json['lastDisplayNameChangeDate'])),
        'registrationCompletedAt': !exists(json, 'registrationCompletedAt') ? undefined : (json['registrationCompletedAt'] === null ? null : new Date(json['registrationCompletedAt'])),
        'color': !exists(json, 'color') ? undefined : json['color'],
        'ivsStreamConfig': !exists(json, 'ivsStreamConfig') ? undefined : IVSStreamConfigFromJSON(json['ivsStreamConfig']),
        'followingChannels': !exists(json, 'followingChannels') ? undefined : (json['followingChannels'] === null ? null : (json['followingChannels'] as Array<any>).map(ChannelFollowerFromJSON)),
        'moderatorChannels': !exists(json, 'moderatorChannels') ? undefined : (json['moderatorChannels'] === null ? null : (json['moderatorChannels'] as Array<any>).map(ChannelModeratorFromJSON)),
        'bannedChannels': !exists(json, 'bannedChannels') ? undefined : (json['bannedChannels'] === null ? null : (json['bannedChannels'] as Array<any>).map(ChannelBannedUserFromJSON)),
        'channelSubscriptions': !exists(json, 'channelSubscriptions') ? undefined : (json['channelSubscriptions'] === null ? null : (json['channelSubscriptions'] as Array<any>).map(ChannelSubscriptionFromJSON)),
        'channelSubscriptionItems': !exists(json, 'channelSubscriptionItems') ? undefined : (json['channelSubscriptionItems'] === null ? null : (json['channelSubscriptionItems'] as Array<any>).map(ChannelSubscriptionItemFromJSON)),
        'canChangeDisplayName': !exists(json, 'canChangeDisplayName') ? undefined : json['canChangeDisplayName'],
        'contentReports': !exists(json, 'contentReports') ? undefined : (json['contentReports'] === null ? null : (json['contentReports'] as Array<any>).map(ContentReportFromJSON)),
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'displayName': value.displayName,
        'normalizedDisplayName': value.normalizedDisplayName,
        'username': value.username,
        'auth0Id': value.auth0Id,
        'email': value.email,
        'twitterUrl': value.twitterUrl,
        'facebookUrl': value.facebookUrl,
        'instagramUrl': value.instagramUrl,
        'youtubeUrl': value.youtubeUrl,
        'discordUrl': value.discordUrl,
        'tikTokUrl': value.tikTokUrl,
        'profilePictureId': value.profilePictureId,
        'profilePicture': AssetToJSON(value.profilePicture),
        'externalId': value.externalId,
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'siteId': value.siteId,
        'site': SiteToJSON(value.site),
        'ivsStreamConfigId': value.ivsStreamConfigId,
        'lastDisplayNameChangeDate': value.lastDisplayNameChangeDate === undefined ? undefined : (value.lastDisplayNameChangeDate === null ? null : value.lastDisplayNameChangeDate.toISOString()),
        'registrationCompletedAt': value.registrationCompletedAt === undefined ? undefined : (value.registrationCompletedAt === null ? null : value.registrationCompletedAt.toISOString()),
        'color': value.color,
        'ivsStreamConfig': IVSStreamConfigToJSON(value.ivsStreamConfig),
        'followingChannels': value.followingChannels === undefined ? undefined : (value.followingChannels === null ? null : (value.followingChannels as Array<any>).map(ChannelFollowerToJSON)),
        'moderatorChannels': value.moderatorChannels === undefined ? undefined : (value.moderatorChannels === null ? null : (value.moderatorChannels as Array<any>).map(ChannelModeratorToJSON)),
        'bannedChannels': value.bannedChannels === undefined ? undefined : (value.bannedChannels === null ? null : (value.bannedChannels as Array<any>).map(ChannelBannedUserToJSON)),
        'channelSubscriptions': value.channelSubscriptions === undefined ? undefined : (value.channelSubscriptions === null ? null : (value.channelSubscriptions as Array<any>).map(ChannelSubscriptionToJSON)),
        'channelSubscriptionItems': value.channelSubscriptionItems === undefined ? undefined : (value.channelSubscriptionItems === null ? null : (value.channelSubscriptionItems as Array<any>).map(ChannelSubscriptionItemToJSON)),
        'contentReports': value.contentReports === undefined ? undefined : (value.contentReports === null ? null : (value.contentReports as Array<any>).map(ContentReportToJSON)),
    };
}

