/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddBannedUserByDisplayNameRequest
 */
export interface AddBannedUserByDisplayNameRequest {
    /**
     * 
     * @type {string}
     * @memberof AddBannedUserByDisplayNameRequest
     */
    channelId?: string;
    /**
     * 
     * @type {string}
     * @memberof AddBannedUserByDisplayNameRequest
     */
    displayName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddBannedUserByDisplayNameRequest
     */
    reason?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AddBannedUserByDisplayNameRequest
     */
    durationInMinutes?: number | null;
}

/**
 * Check if a given object implements the AddBannedUserByDisplayNameRequest interface.
 */
export function instanceOfAddBannedUserByDisplayNameRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AddBannedUserByDisplayNameRequestFromJSON(json: any): AddBannedUserByDisplayNameRequest {
    return AddBannedUserByDisplayNameRequestFromJSONTyped(json, false);
}

export function AddBannedUserByDisplayNameRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddBannedUserByDisplayNameRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'displayName': !exists(json, 'displayName') ? undefined : json['displayName'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'durationInMinutes': !exists(json, 'durationInMinutes') ? undefined : json['durationInMinutes'],
    };
}

export function AddBannedUserByDisplayNameRequestToJSON(value?: AddBannedUserByDisplayNameRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelId': value.channelId,
        'displayName': value.displayName,
        'reason': value.reason,
        'durationInMinutes': value.durationInMinutes,
    };
}

