import { SnackbarProvider } from 'notistack'
import { SuccessToast } from './SuccessToast'

export interface CustomSnackProviderProps {
  children: React.ReactNode
}

export const CustomSnackbarProvider = ({
  children,
}: CustomSnackProviderProps) => {
  return (
    <SnackbarProvider
      autoHideDuration={5000}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      Components={{
        success: SuccessToast,
      }}
      classes={{
        containerRoot: 'mt-12',
      }}
    >
      {children}
    </SnackbarProvider>
  )
}
