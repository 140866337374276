/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';

/**
 * 
 * @export
 * @interface ChannelPromoPanel
 */
export interface ChannelPromoPanel {
    /**
     * 
     * @type {string}
     * @memberof ChannelPromoPanel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChannelPromoPanel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelPromoPanel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelPromoPanel
     */
    imageId?: string | null;
    /**
     * 
     * @type {Asset}
     * @memberof ChannelPromoPanel
     */
    image?: Asset;
    /**
     * 
     * @type {string}
     * @memberof ChannelPromoPanel
     */
    imageLinkUrl?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChannelPromoPanel
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof ChannelPromoPanel
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof ChannelPromoPanel
     */
    channel?: Channel;
}

/**
 * Check if a given object implements the ChannelPromoPanel interface.
 */
export function instanceOfChannelPromoPanel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelPromoPanelFromJSON(json: any): ChannelPromoPanel {
    return ChannelPromoPanelFromJSONTyped(json, false);
}

export function ChannelPromoPanelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelPromoPanel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'imageLinkUrl': !exists(json, 'imageLinkUrl') ? undefined : json['imageLinkUrl'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
    };
}

export function ChannelPromoPanelToJSON(value?: ChannelPromoPanel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'title': value.title,
        'description': value.description,
        'imageId': value.imageId,
        'image': AssetToJSON(value.image),
        'imageLinkUrl': value.imageLinkUrl,
        'order': value.order,
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
    };
}

