/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CategoryTag } from './CategoryTag';
import {
    CategoryTagFromJSON,
    CategoryTagFromJSONTyped,
    CategoryTagToJSON,
} from './CategoryTag';
import type { StreamSession } from './StreamSession';
import {
    StreamSessionFromJSON,
    StreamSessionFromJSONTyped,
    StreamSessionToJSON,
} from './StreamSession';
import type { Video } from './Video';
import {
    VideoFromJSON,
    VideoFromJSONTyped,
    VideoToJSON,
} from './Video';

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {Date}
     * @memberof Category
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Category
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Category
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    slug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    parentCategoryId?: number | null;
    /**
     * 
     * @type {Category}
     * @memberof Category
     */
    parentCategory?: Category;
    /**
     * 
     * @type {Array<Category>}
     * @memberof Category
     */
    childCategories?: Array<Category> | null;
    /**
     * 
     * @type {Array<StreamSession>}
     * @memberof Category
     */
    streamSessions?: Array<StreamSession> | null;
    /**
     * 
     * @type {Array<Video>}
     * @memberof Category
     */
    videos?: Array<Video> | null;
    /**
     * 
     * @type {Array<CategoryTag>}
     * @memberof Category
     */
    tags?: Array<CategoryTag> | null;
}

/**
 * Check if a given object implements the Category interface.
 */
export function instanceOfCategory(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CategoryFromJSON(json: any): Category {
    return CategoryFromJSONTyped(json, false);
}

export function CategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): Category {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parentCategoryId': !exists(json, 'parentCategoryId') ? undefined : json['parentCategoryId'],
        'parentCategory': !exists(json, 'parentCategory') ? undefined : CategoryFromJSON(json['parentCategory']),
        'childCategories': !exists(json, 'childCategories') ? undefined : (json['childCategories'] === null ? null : (json['childCategories'] as Array<any>).map(CategoryFromJSON)),
        'streamSessions': !exists(json, 'streamSessions') ? undefined : (json['streamSessions'] === null ? null : (json['streamSessions'] as Array<any>).map(StreamSessionFromJSON)),
        'videos': !exists(json, 'videos') ? undefined : (json['videos'] === null ? null : (json['videos'] as Array<any>).map(VideoFromJSON)),
        'tags': !exists(json, 'tags') ? undefined : (json['tags'] === null ? null : (json['tags'] as Array<any>).map(CategoryTagFromJSON)),
    };
}

export function CategoryToJSON(value?: Category | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'name': value.name,
        'slug': value.slug,
        'description': value.description,
        'parentCategoryId': value.parentCategoryId,
        'parentCategory': CategoryToJSON(value.parentCategory),
        'childCategories': value.childCategories === undefined ? undefined : (value.childCategories === null ? null : (value.childCategories as Array<any>).map(CategoryToJSON)),
        'streamSessions': value.streamSessions === undefined ? undefined : (value.streamSessions === null ? null : (value.streamSessions as Array<any>).map(StreamSessionToJSON)),
        'videos': value.videos === undefined ? undefined : (value.videos === null ? null : (value.videos as Array<any>).map(VideoToJSON)),
        'tags': value.tags === undefined ? undefined : (value.tags === null ? null : (value.tags as Array<any>).map(CategoryTagToJSON)),
    };
}

