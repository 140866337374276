/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { MessageSenderRequest } from './MessageSenderRequest';
import {
    MessageSenderRequestFromJSON,
    MessageSenderRequestFromJSONTyped,
    MessageSenderRequestToJSON,
} from './MessageSenderRequest';

/**
 * 
 * @export
 * @interface MessageRequest
 */
export interface MessageRequest {
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    chatRoomId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    type?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof MessageRequest
     */
    timestamp?: Date;
    /**
     * 
     * @type {MessageSenderRequest}
     * @memberof MessageRequest
     */
    sender?: MessageSenderRequest;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof MessageRequest
     */
    messageId?: string | null;
}

/**
 * Check if a given object implements the MessageRequest interface.
 */
export function instanceOfMessageRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MessageRequestFromJSON(json: any): MessageRequest {
    return MessageRequestFromJSONTyped(json, false);
}

export function MessageRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): MessageRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'chatRoomId': !exists(json, 'chatRoomId') ? undefined : json['chatRoomId'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'sender': !exists(json, 'sender') ? undefined : MessageSenderRequestFromJSON(json['sender']),
        'content': !exists(json, 'content') ? undefined : json['content'],
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
    };
}

export function MessageRequestToJSON(value?: MessageRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'chatRoomId': value.chatRoomId,
        'type': value.type,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'sender': MessageSenderRequestToJSON(value.sender),
        'content': value.content,
        'messageId': value.messageId,
    };
}

