import { Link, useLocation } from 'react-router-dom'
import { routes, toggleSpecialSubdomain } from '../../../routes'
import { styled } from '../../../stitches'
import HeartIcon from '../../../assets/icons/HeartIcon.svg?react'
import HomeIcon from '../../../assets/icons/HomeIcon.svg?react'
import BrowseIcon from '../../../assets/icons/BrowseIcon.svg?react'
import PreviewIcon from '../../../assets/icons/PreviewIcon.svg?react'
import PlusIcon from '../../../assets/icons/PlusIcon.svg?react'
import { onlyFans, onlyStream } from '../../utils/sites'
import { useAuth0 } from '@auth0/auth0-react'
import useLanguage, { LanguageKey } from '../../stores/useLanguage'

const tabbarItems = [
  {
    name: 'home',
    route: routes.home,
    svg: <HomeIcon />,
    onlyLoggedIn: false,
  },
  {
    name: 'following',
    route: routes.streams.following,
    svg: <HeartIcon />,
    onlyLoggedIn: true,
  },
  {
    name: 'browse',
    route: routes.categories(),
    svg: <BrowseIcon />,
    onlyLoggedIn: false,
  },
  {
    name: 'profile',
    route: routes.profile,
    svg: <BrowseIcon />,
    onlyLoggedIn: true,
  },
]

const Tabbar = () => {
  const location = useLocation()
  const { t } = useLanguage()
  const { isAuthenticated } = useAuth0()

  return (
    <>
      <TabbarContainer>
        <TabbarContent>
          {onlyStream(
            <>
              <TabItemHref href={toggleSpecialSubdomain()}>
                <TabItem>
                  <PlusIcon />
                  {t('navbarItems.special')}
                </TabItem>
              </TabItemHref>
            </>
          )}
          {onlyFans(
            <TabItemHref href={toggleSpecialSubdomain()}>
              <TabItem>
                <PreviewIcon />
                {t('navbarItems.streaming')}
              </TabItem>
            </TabItemHref>
          )}
          {tabbarItems.map((item) => {
            if (!item.onlyLoggedIn || (item.onlyLoggedIn && isAuthenticated)) {
              return (
                <TabItemLink key={item.name} to={item.route}>
                  <TabItem active={item.route === location.pathname}>
                    <SVGWrapper>{item.svg}</SVGWrapper>
                    {t(`tabbarItems.${item.name.toLowerCase()}` as LanguageKey)}
                  </TabItem>
                </TabItemLink>
              )
            }
            return null
          })}
        </TabbarContent>
      </TabbarContainer>
      <Spacer />
    </>
  )
}
const Spacer = styled('div', {
  height: '4rem',
  display: 'block',
  '@desktop': {
    display: 'none',
  },
  '@tablet': {
    display: 'none',
  },
})
const TabbarContainer = styled('div', {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'black',
  zIndex: 50,
  '@desktop': {
    display: 'none',
  },
  '@tablet': {
    display: 'none',
  },
})

const TabbarContent = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(60px, 1fr))',
  padding: '15px 3%',
  alignItems: 'center',
  justifyContent: 'center',
})

const TabItemHref = styled('a', {
  textDecoration: 'none',
})

const TabItemLink = styled(Link, {
  textDecoration: 'none',
})
const TabItem = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '0.25rem',
  fontWeight: 500,
  minWidth: 'max-content',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '12px',
  color: 'gray',
  svg: {
    width: 20,
    height: 20,
  },
  variants: {
    active: {
      true: {
        color: 'white',
      },
    },
  },
})

const SVGWrapper = styled('div', {
  width: 20,
  height: 20,
})

export default Tabbar
