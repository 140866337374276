/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetChannelSearchResponse } from './GetChannelSearchResponse';
import {
    GetChannelSearchResponseFromJSON,
    GetChannelSearchResponseFromJSONTyped,
    GetChannelSearchResponseToJSON,
} from './GetChannelSearchResponse';

/**
 * 
 * @export
 * @interface GetSearchResponse
 */
export interface GetSearchResponse {
    /**
     * 
     * @type {Array<GetChannelSearchResponse>}
     * @memberof GetSearchResponse
     */
    channels?: Array<GetChannelSearchResponse> | null;
}

/**
 * Check if a given object implements the GetSearchResponse interface.
 */
export function instanceOfGetSearchResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetSearchResponseFromJSON(json: any): GetSearchResponse {
    return GetSearchResponseFromJSONTyped(json, false);
}

export function GetSearchResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetSearchResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channels': !exists(json, 'channels') ? undefined : (json['channels'] === null ? null : (json['channels'] as Array<any>).map(GetChannelSearchResponseFromJSON)),
    };
}

export function GetSearchResponseToJSON(value?: GetSearchResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channels': value.channels === undefined ? undefined : (value.channels === null ? null : (value.channels as Array<any>).map(GetChannelSearchResponseToJSON)),
    };
}

