/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetMessageSenderResponse } from './GetMessageSenderResponse';
import {
    GetMessageSenderResponseFromJSON,
    GetMessageSenderResponseFromJSONTyped,
    GetMessageSenderResponseToJSON,
} from './GetMessageSenderResponse';

/**
 * 
 * @export
 * @interface GetChatMessageItemResponse
 */
export interface GetChatMessageItemResponse {
    /**
     * 
     * @type {string}
     * @memberof GetChatMessageItemResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof GetChatMessageItemResponse
     */
    messageId: string;
    /**
     * 
     * @type {string}
     * @memberof GetChatMessageItemResponse
     */
    chatRoomId: string;
    /**
     * 
     * @type {string}
     * @memberof GetChatMessageItemResponse
     */
    content: string;
    /**
     * 
     * @type {Date}
     * @memberof GetChatMessageItemResponse
     */
    timestamp?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GetChatMessageItemResponse
     */
    createdAt: Date;
    /**
     * 
     * @type {string}
     * @memberof GetChatMessageItemResponse
     */
    type: string;
    /**
     * 
     * @type {GetMessageSenderResponse}
     * @memberof GetChatMessageItemResponse
     */
    sender: GetMessageSenderResponse;
}

/**
 * Check if a given object implements the GetChatMessageItemResponse interface.
 */
export function instanceOfGetChatMessageItemResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "messageId" in value;
    isInstance = isInstance && "chatRoomId" in value;
    isInstance = isInstance && "content" in value;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "sender" in value;

    return isInstance;
}

export function GetChatMessageItemResponseFromJSON(json: any): GetChatMessageItemResponse {
    return GetChatMessageItemResponseFromJSONTyped(json, false);
}

export function GetChatMessageItemResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChatMessageItemResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'messageId': json['messageId'],
        'chatRoomId': json['chatRoomId'],
        'content': json['content'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'createdAt': (new Date(json['createdAt'])),
        'type': json['type'],
        'sender': GetMessageSenderResponseFromJSON(json['sender']),
    };
}

export function GetChatMessageItemResponseToJSON(value?: GetChatMessageItemResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'messageId': value.messageId,
        'chatRoomId': value.chatRoomId,
        'content': value.content,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'createdAt': (value.createdAt.toISOString()),
        'type': value.type,
        'sender': GetMessageSenderResponseToJSON(value.sender),
    };
}

