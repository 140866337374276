import { routes } from '../../../../routes'
import { Link } from 'react-router-dom'
import { styled } from '../../../../stitches'
import { AdornmentButton, Input } from '../../Common/Form/Input'
import { BsSearch } from 'react-icons/bs'
import { useQuery } from '../../../utils/AsyncUtils/useQuery'
import { Skeleton } from '../../Common/Skeleton'
import { useDebouncedValue } from '../../../utils/useDebouncedValue'
import { Cross2Icon } from '@radix-ui/react-icons'
import { VerifiedBadge } from '../../../../OnlyStream/components/Channel/VerifiedBadge'
import { LiveBadge } from '../../../../OnlyStream/components/Channel/LiveBadge'
import HighlightedText from '../../Common/HighlightedText'
import { useApi } from '../../../utils/ApiContext'
import { GetChannelSearchResponse, SearchApi } from '../../../../client'
import useLanguage from '../../../stores/useLanguage'

export async function searchApiCall(searchApi: SearchApi, keyword: string) {
  const searchResult = await searchApi.searchPost({ query: keyword })
  const channels = searchResult.channels
  if (!channels) return []
  return channels
}

type SearchInputProps = {
  value: string
  onChange: (newQuery: string) => void
  onClose: () => void
  mode: 'navigate' | 'returnObject'
  onResultClick?: (channel: GetChannelSearchResponse) => void
  filter?: (channel: GetChannelSearchResponse) => boolean
}

export function SearchInput(props: SearchInputProps) {
  const { searchApi } = useApi()
  const { t } = useLanguage()
  const debouncedQuery = useDebouncedValue(props.value)
  const searchResults = useQuery(async () => {
    if (debouncedQuery === '') return []
    return await searchApiCall(searchApi, debouncedQuery)
  }, [debouncedQuery])

  const filteredResults =
    searchResults.state === 'success' && props.filter
      ? searchResults.data.filter(props.filter)
      : searchResults.data || []

  return (
    <SearchInputContainer>
      <StyledInput
        id="search"
        inputProps={{
          autoComplete: 'off',
          placeholder: t('search.placeholder'),
          value: props.value,
          onChange(e) {
            props.onChange(e.target.value)
          },
        }}
        endAdornment={
          props.value.length ? (
            <AdornmentButton
              onClick={() => {
                props.onChange('')
              }}
            >
              <Cross2Icon />
            </AdornmentButton>
          ) : (
            <AdornmentButton disabled>
              <BsSearch />
            </AdornmentButton>
          )
        }
      />
      <SearchResults show={!!props.value.length}>
        {searchResults.state === 'loading' && (
          <>
            <Skeleton
              type="text"
              css={{ height: '2.2rem', marginBottom: '0' }}
            />
            <Skeleton
              type="text"
              css={{ height: '2.2rem', marginBottom: '0' }}
            />
          </>
        )}
        {searchResults.state === 'success' &&
          filteredResults.map((channel) => (
            <SearchResult
              key={channel.slug}
              as={props.mode === 'navigate' ? Link : 'button'}
              onClick={() => {
                if (props.mode === 'navigate') {
                  props.onChange('')
                  props.onClose()
                } else if (
                  props.mode === 'returnObject' &&
                  props.onResultClick
                ) {
                  props.onResultClick(channel)
                }
              }}
              to={
                props.mode === 'navigate'
                  ? routes.channel.home(channel.slug)
                  : undefined
              }
            >
              <ChannelName>
                <HighlightedText
                  text={channel.slug}
                  word={debouncedQuery}
                ></HighlightedText>
              </ChannelName>
              <VerifiedBadge isVerified={channel.isVerified} />
              <LiveBadgeContainer>
                <LiveBadge isLive={channel.isLive} />
              </LiveBadgeContainer>
            </SearchResult>
          ))}
      </SearchResults>
    </SearchInputContainer>
  )
}

const StyledInput = styled(Input, {
  height: 44,
})

const ChannelName = styled('div', {
  fontWeight: '600',
  fontSize: '14px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '180px',
})

const LiveBadgeContainer = styled('div', {
  flex: '1',
  textAlign: 'right',
  minWidth: 'max-content',
})

const SearchInputContainer = styled('div', {
  width: 300,
  '@onlyPhone': {
    width: '70vw',
  },
})

const SearchResults = styled('div', {
  position: 'absolute',
  background: 'rgba(36 39 44 / 0.9)',
  borderRadius: '0.5rem',
  backdropFilter: 'blur(10px)',
  width: 300,
  zIndex: 99,
  '@onlyPhone': {
    width: '70vw',
  },
  display: 'grid',
  variants: {
    show: {
      false: {
        display: 'none',
      },
    },
  },
  '--base-color': 'rgba(77 77 77 / 0.1)',
  '--highlight-color': 'rgba(77 77 77 / 0.3)',
})

const SearchResult = styled('button', {
  display: 'flex',
  gap: '0.5rem',
  padding: '.5rem .6rem',
  height: '2.2rem',
  background: 'rgba(77 77 77 / 0.1)',
  borderRadius: '0.3rem',
  textDecoration: 'none',
  color: '#fff',
  margin: 0,
  fontSize: '1rem',
  alignItems: 'center',
  border: '1px solid #e4ffda0a',
  transition: 'background 0.3s',
  '@onlyPhone': {
    width: '70vw',
  },
  '&:hover': {
    backgroundColor: 'rgb(15 18 20)',
  },
})
