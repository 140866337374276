/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';

/**
 * 
 * @export
 * @interface ChatMessage
 */
export interface ChatMessage {
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessage
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    chatRoomId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    userId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    channelId?: string;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    content?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    type?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    timestamp?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    messageId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    senderId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    senderUsername?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    senderSlug?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    senderBadgeUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    senderBadgeLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChatMessage
     */
    senderColor?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChatMessage
     */
    duration?: number | null;
    /**
     * 
     * @type {Date}
     * @memberof ChatMessage
     */
    pinnedAt?: Date | null;
    /**
     * 
     * @type {Channel}
     * @memberof ChatMessage
     */
    channel?: Channel;
    /**
     * 
     * @type {boolean}
     * @memberof ChatMessage
     */
    readonly isActive?: boolean;
}

/**
 * Check if a given object implements the ChatMessage interface.
 */
export function instanceOfChatMessage(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChatMessageFromJSON(json: any): ChatMessage {
    return ChatMessageFromJSONTyped(json, false);
}

export function ChatMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChatMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'chatRoomId': !exists(json, 'chatRoomId') ? undefined : json['chatRoomId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'content': !exists(json, 'content') ? undefined : json['content'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'timestamp': !exists(json, 'timestamp') ? undefined : (new Date(json['timestamp'])),
        'messageId': !exists(json, 'messageId') ? undefined : json['messageId'],
        'senderId': !exists(json, 'senderId') ? undefined : json['senderId'],
        'senderUsername': !exists(json, 'senderUsername') ? undefined : json['senderUsername'],
        'senderSlug': !exists(json, 'senderSlug') ? undefined : json['senderSlug'],
        'senderBadgeUrl': !exists(json, 'senderBadgeUrl') ? undefined : json['senderBadgeUrl'],
        'senderBadgeLabel': !exists(json, 'senderBadgeLabel') ? undefined : json['senderBadgeLabel'],
        'senderColor': !exists(json, 'senderColor') ? undefined : json['senderColor'],
        'duration': !exists(json, 'duration') ? undefined : json['duration'],
        'pinnedAt': !exists(json, 'pinnedAt') ? undefined : (json['pinnedAt'] === null ? null : new Date(json['pinnedAt'])),
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function ChatMessageToJSON(value?: ChatMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'chatRoomId': value.chatRoomId,
        'userId': value.userId,
        'channelId': value.channelId,
        'content': value.content,
        'type': value.type,
        'timestamp': value.timestamp === undefined ? undefined : (value.timestamp.toISOString()),
        'messageId': value.messageId,
        'senderId': value.senderId,
        'senderUsername': value.senderUsername,
        'senderSlug': value.senderSlug,
        'senderBadgeUrl': value.senderBadgeUrl,
        'senderBadgeLabel': value.senderBadgeLabel,
        'senderColor': value.senderColor,
        'duration': value.duration,
        'pinnedAt': value.pinnedAt === undefined ? undefined : (value.pinnedAt === null ? null : value.pinnedAt.toISOString()),
        'channel': ChannelToJSON(value.channel),
    };
}

