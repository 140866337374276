/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetTopCategoryResponse } from './GetTopCategoryResponse';
import {
    GetTopCategoryResponseFromJSON,
    GetTopCategoryResponseFromJSONTyped,
    GetTopCategoryResponseToJSON,
} from './GetTopCategoryResponse';

/**
 * 
 * @export
 * @interface GetTopCategoryResponsePagedApiResponse
 */
export interface GetTopCategoryResponsePagedApiResponse {
    /**
     * 
     * @type {Array<GetTopCategoryResponse>}
     * @memberof GetTopCategoryResponsePagedApiResponse
     */
    data: Array<GetTopCategoryResponse>;
    /**
     * 
     * @type {number}
     * @memberof GetTopCategoryResponsePagedApiResponse
     */
    pageNumber?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetTopCategoryResponsePagedApiResponse
     */
    pageSize?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetTopCategoryResponsePagedApiResponse
     */
    totalItemCount: number;
    /**
     * 
     * @type {number}
     * @memberof GetTopCategoryResponsePagedApiResponse
     */
    totalPages: number;
}

/**
 * Check if a given object implements the GetTopCategoryResponsePagedApiResponse interface.
 */
export function instanceOfGetTopCategoryResponsePagedApiResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "data" in value;
    isInstance = isInstance && "totalItemCount" in value;
    isInstance = isInstance && "totalPages" in value;

    return isInstance;
}

export function GetTopCategoryResponsePagedApiResponseFromJSON(json: any): GetTopCategoryResponsePagedApiResponse {
    return GetTopCategoryResponsePagedApiResponseFromJSONTyped(json, false);
}

export function GetTopCategoryResponsePagedApiResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetTopCategoryResponsePagedApiResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(GetTopCategoryResponseFromJSON)),
        'pageNumber': !exists(json, 'pageNumber') ? undefined : json['pageNumber'],
        'pageSize': !exists(json, 'pageSize') ? undefined : json['pageSize'],
        'totalItemCount': json['totalItemCount'],
        'totalPages': json['totalPages'],
    };
}

export function GetTopCategoryResponsePagedApiResponseToJSON(value?: GetTopCategoryResponsePagedApiResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': ((value.data as Array<any>).map(GetTopCategoryResponseToJSON)),
        'pageNumber': value.pageNumber,
        'pageSize': value.pageSize,
        'totalItemCount': value.totalItemCount,
        'totalPages': value.totalPages,
    };
}

