import { styled } from '../../../stitches'
import useLanguage, { SUPPORTED_LANGUAGES } from '../../stores/useLanguage'
import { Select } from '../Common/Form/Select'

const LanguageDropdown = () => {
  const { languageCode, setLanguageCode } = useLanguage()

  const options = SUPPORTED_LANGUAGES.map((lang) => ({
    value: lang.code,
    label: (
      <>
        <LanguageCode>({lang.code})</LanguageCode>
        {lang.name}
      </>
    ),
  }))

  return (
    <StyledSelect
      options={options}
      value={languageCode}
      onChange={(code) => setLanguageCode(code)}
    />
  )
}
const StyledSelect = styled(Select, {
  width: '10rem',
  margin: '1rem auto',
  border: '0px',
}) as typeof Select

const LanguageCode = styled('span', {
  padding: '0.5em',
  textTransform: 'uppercase',
})

export default LanguageDropdown
