import { styled } from '../../../stitches'
import LogoPath from '../../../assets/logo.png?url'
import { ReactNode, useEffect } from 'react'

type ErrorLayoutProps = {
  children: ReactNode
}

export function ErrorLayout(props: ErrorLayoutProps) {
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    return () => {
      document.body.style.overflow = 'initial'
    }
  }, [])

  return (
    <Background>
      <Container>
        <Logo src={LogoPath} />
        {props.children}
      </Container>
    </Background>
  )
}

const Container = styled('div', {
  zIndex: '100',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexFlow: 'column',
  padding: '2rem 3rem',
  gap: '2rem',
  fontFamily: 'monospace',
  maxWidth: '40rem',
})

const Background = styled('div', {
  background: '#0c0e0f',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'center',
  zIndex: '999',
})

const Logo = styled('img', {
  width: 'min(20rem, 80vw)',
})

export const Button = styled('button', {
  fontFamily: 'Inter',
  background: '#1c2123',
  color: '#fff',
  border: 0,
  borderRadius: '0.5rem',
  fontSize: '1rem',
  fontWeight: 'bold',
  padding: '1rem 2rem',
  cursor: 'pointer',
  textDecoration: 'none',
  '&:hover': {
    background: '#242D30',
  },
})

export const Message = styled('span', {
  color: '#fff',
  fontSize: '1.5rem',
  margin: '1.5rem',
  textAlign: 'center',
})
