/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AddStreamRequest,
  AddStreamResponse,
  ApiErrorResponse,
  GetStreamConfigResponse,
  GetStreamDetailResponse,
  GetStreamInfoResponse,
} from '../models/index';
import {
    AddStreamRequestFromJSON,
    AddStreamRequestToJSON,
    AddStreamResponseFromJSON,
    AddStreamResponseToJSON,
    ApiErrorResponseFromJSON,
    ApiErrorResponseToJSON,
    GetStreamConfigResponseFromJSON,
    GetStreamConfigResponseToJSON,
    GetStreamDetailResponseFromJSON,
    GetStreamDetailResponseToJSON,
    GetStreamInfoResponseFromJSON,
    GetStreamInfoResponseToJSON,
} from '../models/index';

export interface StreamPutRequest {
    addStreamRequest?: AddStreamRequest;
}

/**
 * 
 */
export class StreamApi extends runtime.BaseAPI {

    /**
     * Get an AWS IVS stream configuration for the user
     */
    async streamConfigGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamConfigResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream/config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamConfigResponseFromJSON(jsonValue));
    }

    /**
     * Get an AWS IVS stream configuration for the user
     */
    async streamConfigGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamConfigResponse> {
        const response = await this.streamConfigGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Reset an AWS IVS stream key
     */
    async streamConfigResetPutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamConfigResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream/config/reset`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamConfigResponseFromJSON(jsonValue));
    }

    /**
     * Reset an AWS IVS stream key
     */
    async streamConfigResetPut(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamConfigResponse> {
        const response = await this.streamConfigResetPutRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets information about the stream session
     */
    async streamDetailsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamDetailResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamDetailResponseFromJSON(jsonValue));
    }

    /**
     * Gets information about the stream session
     */
    async streamDetailsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamDetailResponse> {
        const response = await this.streamDetailsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Gets the current stream info of a user, or creates new one if there\'s no stream session
     */
    async streamInfoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetStreamInfoResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetStreamInfoResponseFromJSON(jsonValue));
    }

    /**
     * Gets the current stream info of a user, or creates new one if there\'s no stream session
     */
    async streamInfoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetStreamInfoResponse> {
        const response = await this.streamInfoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     * Add a new stream for the user
     */
    async streamPutRaw(requestParameters: StreamPutRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AddStreamResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/stream`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: AddStreamRequestToJSON(requestParameters.addStreamRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AddStreamResponseFromJSON(jsonValue));
    }

    /**
     * Add a new stream for the user
     */
    async streamPut(requestParameters: StreamPutRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AddStreamResponse> {
        const response = await this.streamPutRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
