/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ChannelBannedUser
 */
export interface ChannelBannedUser {
    /**
     * 
     * @type {Date}
     * @memberof ChannelBannedUser
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ChannelBannedUser
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof ChannelBannedUser
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof ChannelBannedUser
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ChannelBannedUser
     */
    userId?: number;
    /**
     * 
     * @type {User}
     * @memberof ChannelBannedUser
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof ChannelBannedUser
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof ChannelBannedUser
     */
    channel?: Channel;
    /**
     * 
     * @type {Date}
     * @memberof ChannelBannedUser
     */
    bannedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof ChannelBannedUser
     */
    durationInMinutes?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChannelBannedUser
     */
    readonly isActive?: boolean;
}

/**
 * Check if a given object implements the ChannelBannedUser interface.
 */
export function instanceOfChannelBannedUser(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChannelBannedUserFromJSON(json: any): ChannelBannedUser {
    return ChannelBannedUserFromJSONTyped(json, false);
}

export function ChannelBannedUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChannelBannedUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
        'bannedAt': !exists(json, 'bannedAt') ? undefined : (new Date(json['bannedAt'])),
        'durationInMinutes': !exists(json, 'durationInMinutes') ? undefined : json['durationInMinutes'],
        'isActive': !exists(json, 'isActive') ? undefined : json['isActive'],
    };
}

export function ChannelBannedUserToJSON(value?: ChannelBannedUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'userId': value.userId,
        'user': UserToJSON(value.user),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
        'bannedAt': value.bannedAt === undefined ? undefined : (value.bannedAt.toISOString()),
        'durationInMinutes': value.durationInMinutes,
    };
}

