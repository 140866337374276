/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RemoveModeratorByIdRequest
 */
export interface RemoveModeratorByIdRequest {
    /**
     * 
     * @type {string}
     * @memberof RemoveModeratorByIdRequest
     */
    channelId?: string;
    /**
     * 
     * @type {number}
     * @memberof RemoveModeratorByIdRequest
     */
    userId?: number;
}

/**
 * Check if a given object implements the RemoveModeratorByIdRequest interface.
 */
export function instanceOfRemoveModeratorByIdRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RemoveModeratorByIdRequestFromJSON(json: any): RemoveModeratorByIdRequest {
    return RemoveModeratorByIdRequestFromJSONTyped(json, false);
}

export function RemoveModeratorByIdRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RemoveModeratorByIdRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function RemoveModeratorByIdRequestToJSON(value?: RemoveModeratorByIdRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'channelId': value.channelId,
        'userId': value.userId,
    };
}

