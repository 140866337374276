import { styled } from '../../../stitches'
import defaultAvatarUrl from '../../../assets/avatar.svg?url'
import { ComponentProps } from 'react'
import useLanguage from '../../../shared/stores/useLanguage'

export function Avatar(props: {
  isLive: boolean
  url?: string
  size?: ComponentProps<typeof ImageWrapper>['size']
  inline?: boolean
}) {
  const { t } = useLanguage()
  return (
    <Container inline={props.inline}>
      <ImageWrapper isLive={props.isLive} size={props.size}>
        <Image
          src={props.url || defaultAvatarUrl}
          alt={t('streamingGeneral.profilePictureAltText')}
        />
      </ImageWrapper>
      {props.isLive && (
        <LiveBadge size={props.size}>{t('streamingGeneral.live')}</LiveBadge>
      )}
    </Container>
  )
}

export const Container = styled('div', {
  position: 'relative',
  variants: {
    inline: {
      true: {
        display: 'inline-block',
        verticalAlign: 'middle',
        margin: '0.5em',
      },
    },
  },
})
export const ImageWrapper = styled('div', {
  borderRadius: '50%',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxSizing: 'content-box',
  variants: {
    size: {
      xs: {
        width: '30px',
        height: '30px',
      },
      sm: {
        width: '40px',
        height: '40px',
      },
      lg: {
        width: '80px',
        height: '80px',
      },
      xl: {
        width: '120px',
        height: '120px',
      },
    },
    isLive: {
      true: {
        border: '2px solid rgb(83, 252, 24)',
        margin: '-2px',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
  },
  '& img:hover': {
    transform: 'scale(1.25)',
  },
})
export const Image = styled('img', {
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  transition: 'transform .3s cubic-bezier(0,0,0,1)',
})
export const LiveBadge = styled('div', {
  position: 'absolute',
  bottom: '-4px',
  left: '50%',
  transform: 'translate(-50%, 0)',
  userSelect: 'none',
  borderRadius: '2px',
  backgroundColor: 'rgb(83 252 24)',
  paddingLeft: '6px',
  paddingRight: '6px',
  textAlign: 'center',
  verticalAlign: 'middle',
  fontWeight: '700',
  textTransform: 'uppercase',
  color: 'rgb(15 18 20)',
  height: '12px',
  fontSize: '8px',
  lineHeight: '12px',
  zIndex: 2,
  '@tablet': {
    height: '20px',
    lineHeight: '20px',
    fontSize: '10px',
    bottom: '-2px',
  },
  variants: {
    size: {
      xs: { display: 'none' },
      sm: { display: 'none' },
      lg: {},
      xl: {},
    },
  },
})
