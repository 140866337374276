import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useEffect,
  useState,
} from 'react'

type TransitionProps<
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<unknown>,
> = {
  onClosing?: Partial<ComponentProps<T>>
  onClosed?: Partial<ComponentProps<T>>
  onOpening?: Partial<ComponentProps<T>>
  onOpened?: Partial<ComponentProps<T>>
  onBeforeOpening?: Partial<ComponentProps<T>>
  onBeforeClosing?: Partial<ComponentProps<T>>
  as: T
  componentProps?: ComponentProps<T>
  anim: Anim
  children?: ReactNode
}

export const Transition = <
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  T extends keyof JSX.IntrinsicElements | JSXElementConstructor<any>,
>(
  props: TransitionProps<T>
) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const MyComponent: any = props.as

  const onClosing = props.onClosing || {}
  const onClosed = props.onClosed || {}
  const onOpening = props.onOpening || {}
  const onOpened = props.onOpened || {}
  const onBeforeOpening = props.onBeforeOpening || {}
  const onBeforeClosing = props.onBeforeClosing || {}

  const componentProps =
    typeof props.componentProps === 'object' && props.componentProps
      ? props.componentProps
      : {}

  switch (props.anim) {
    case 'closed':
      return <MyComponent {...{ ...componentProps, ...onClosed }}></MyComponent>
    case 'opening':
      return (
        <MyComponent {...{ ...componentProps, ...onOpening }}>
          {props.children}
        </MyComponent>
      )
    case 'opened':
      return (
        <MyComponent {...{ ...componentProps, ...onOpened }}>
          {props.children}
        </MyComponent>
      )
    case 'closing':
      return (
        <MyComponent {...{ ...componentProps, ...onClosing }}>
          {props.children}
        </MyComponent>
      )
    case 'beforeOpening':
      return (
        <MyComponent {...{ ...componentProps, ...onBeforeOpening }}>
          {props.children}
        </MyComponent>
      )
    case 'beforeClosing':
      return (
        <MyComponent {...{ ...componentProps, ...onBeforeClosing }}>
          {props.children}
        </MyComponent>
      )
  }
}

type Anim =
  | 'opening'
  | 'opened'
  | 'closing'
  | 'closed'
  | 'beforeOpening'
  | 'beforeClosing'

/**
 * Add a state that will be equal to targetOpenState when the animation is complete.
 *
 * @param targetOpenState The next state that the animation should be in after some delay
 * @param ms delay in milliseconds
 * @returns the animation state
 */
export function useAnimated(targetOpenState: boolean, ms: number): Anim {
  const [anim, setAnim] = useState<Anim>('closed')

  useEffect(() => {
    const closeWanted = !targetOpenState && anim === 'opened'
    const openWanted = targetOpenState && anim === 'closed'

    if (openWanted) {
      setAnim('beforeOpening')
      setTimeout(() => setAnim('opening'), 0)
      setTimeout(() => setAnim('opened'), ms)
    } else if (closeWanted) {
      setAnim('beforeClosing')
      setTimeout(() => setAnim('closing'), 0)
      setTimeout(() => setAnim('closed'), ms)
    }
  }, [targetOpenState, anim, ms])

  return anim
}
