import { ChangeEvent, ComponentProps, ReactNode, forwardRef } from 'react'
import {
  MentionProps as ReactMentionProps,
  MentionsInput as ReactMentionsInput,
  MentionsInputProps as ReactMentionsInputProps,
} from 'react-mentions'

import { styled } from '../../../../stitches'
import { FormRow, ErrorText, HelpText } from './Utils'

export interface InputProps {
  id: string
  errorText?: string
  label?: string
  inputProps: Omit<ComponentProps<'input'>, 'ref' | 'id'>
  endAdornment?: ReactNode
  startAdornment?: ReactNode
  helperText?: string
  className?: string
  formatter?: (value: string) => string
  disableAutocomplete?: boolean
}

export const Input = forwardRef<HTMLInputElement, InputProps>(function Input(
  props: InputProps,
  ref
) {
  const filled =
    (props.inputProps.value !== null &&
      props.inputProps.value !== undefined &&
      props.inputProps.value !== '') ||
    !!props.inputProps.placeholder

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    const value = props.formatter
      ? props.formatter(e.target.value)
      : e.target.value
    if (props.inputProps.onChange) {
      props.inputProps.onChange({
        ...e,
        target: {
          ...e.target,
          value,
        },
      })
    }
  }

  return (
    <FormRow className={props.className}>
      <Container error={!!props.errorText} disabled={props.inputProps.disabled}>
        {props.label && <Legend filled={filled}>{props.label}</Legend>}
        {props.startAdornment && <Adornment>{props.startAdornment}</Adornment>}
        <StyledInput
          {...props.inputProps}
          value={props.inputProps.value || ''}
          onChange={handleChange}
          id={props.id}
          autoComplete={props.disableAutocomplete ? 'off' : undefined}
          ref={ref}
        />
        {props.label && (
          <FloatingLabel filled={filled} htmlFor={props.id}>
            {props.label}
          </FloatingLabel>
        )}
        {props.endAdornment && <Adornment>{props.endAdornment}</Adornment>}
      </Container>
      {props.errorText && <ErrorText input>{props.errorText}</ErrorText>}
      {props.helperText && !props.errorText && (
        <HelpText input>{props.helperText}</HelpText>
      )}
    </FormRow>
  )
})

export interface MentionsInputProps {
  id: string
  inputProps: Omit<ReactMentionsInputProps, 'children'>
  children:
    | React.ReactElement<ReactMentionProps>
    | Array<React.ReactElement<ReactMentionProps>>
  errorText?: string
  label?: string
  endAdornment?: ReactNode
  startAdornment?: ReactNode
  disableAutocomplete?: boolean
  helperText?: string
  className?: string
  formatter?: (value: string) => string
}

export const MentionsInput = forwardRef<HTMLInputElement, MentionsInputProps>(
  function MentionsInput(props: MentionsInputProps, inputRef) {
    const filled =
      (props.inputProps.value !== null &&
        props.inputProps.value !== undefined &&
        props.inputProps.value !== '') ||
      !!props.inputProps.placeholder

    return (
      <FormRow className={props.className}>
        <Container
          error={!!props.errorText}
          disabled={props.inputProps.disabled}
        >
          {props.label && <Legend filled={filled}>{props.label}</Legend>}
          {props.startAdornment && (
            <Adornment>{props.startAdornment}</Adornment>
          )}

          <StyledReactMentionsInput
            {...props.inputProps}
            id={props.id}
            inputRef={inputRef}
            autoComplete={props.disableAutocomplete ? 'off' : undefined}
            style={MentionsInputSuggestionsStyle}
          >
            {props.children}
          </StyledReactMentionsInput>

          {props.label && (
            <FloatingLabel filled={filled} htmlFor={props.id}>
              {props.label}
            </FloatingLabel>
          )}
          {props.endAdornment && <Adornment>{props.endAdornment}</Adornment>}
        </Container>
        {props.errorText && <ErrorText input>{props.errorText}</ErrorText>}
        {props.helperText && !props.errorText && (
          <HelpText input>{props.helperText}</HelpText>
        )}
      </FormRow>
    )
  }
)

export interface TextAreaProps {
  id: string
  label?: string
  textareaProps: Omit<ComponentProps<'textarea'>, 'id' | 'ref'>
}

export const TextArea = ({ id, label, textareaProps }: TextAreaProps) => {
  const isFilled = textareaProps.value || textareaProps.placeholder
  return (
    <FormRow>
      <TextareaContainer error={false} disabled={textareaProps.disabled}>
        {label && <Legend filled={!!isFilled}>{label}</Legend>}
        <StyledTextArea as="textarea" {...textareaProps} id={id} />
        <TextareaFloatingLabel htmlFor={id} filled={!!isFilled}>
          {label}
        </TextareaFloatingLabel>
      </TextareaContainer>
    </FormRow>
  )
}

export const AdornmentButton = styled('button', {
  flex: '1',
  padding: '0 1rem',
  margin: '2px',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: '0.4rem',
  background: 'none',
  border: 'none',
  fontSize: '1rem',
  color: 'white',
  '&:disabled': {
    cursor: 'initial',
    opacity: 0.4,
  },
  '&:not(:disabled):hover': {
    background: 'rgba(255,255,255,0.2)',
  },
})

export const AdornmentText = styled('div', {
  display: 'flex',
  alignItems: 'center',
  padding: '0 0 0 1rem',
  whiteSpace: 'nowrap',
  borderRadius: '0.4rem 0 0 0.4rem',
  margin: '2px',
})

const Adornment = styled('div', {
  flex: 0,
  display: 'flex',
  alignItems: 'stretch',
  alignSelf: 'stretch',
})

const Container = styled('fieldset', {
  flex: '1',
  minWidth: '0',
  maxWidth: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  border: '1px solid #ffffff33',
  variants: {
    disabled: {
      true: {
        opacity: '0.6',
      },
    },
    error: {
      true: {
        border: '1px solid #FF0000',
      },
    },
  },
  '&:focus-within label': {
    transform: 'translate(0, -19px) scale(0.8)',
  },
  '&:focus-within legend': {
    display: 'block',
  },
  borderRadius: '0.2rem',
  margin: 0,
  padding: 0,
  '&:focus': {
    boxShadow: '0px 0px 0px 2px #80808096',
  },
})

const TextareaContainer = styled(Container, {
  '&:focus-within label': {
    transform: 'translate(0, -36px) scale(0.8)',
  },
  '&:focus-within legend': {
    display: 'block',
  },
})

const Legend = styled('legend', {
  marginLeft: '9px',
  maxHeight: 0,
  visibility: 'hidden',
  fontSize: '0.8rem',
  padding: '0 5px',
  variants: {
    filled: {
      false: {
        display: 'none',
      },
    },
  },
})

const FloatingLabel = styled('label', {
  fontWeight: 500,
  position: 'absolute',
  pointerEvents: 'none',
  transform: 'translate(0, 0px) scale(1)',
  transformOrigin: 'top left',
  color: '#fff',
  fontSize: '1rem',
  lineHeight: 1,
  left: '1rem',
  padding: '5px 10px 5px 10px',
  borderRadius: '5px',
  marginLeft: -10,

  variants: {
    filled: {
      true: {
        transform: 'translate(0, -19px) scale(0.8)',
      },
    },
  },
  disabled: {
    true: {
      opacity: 0.5,
    },
  },
})

const TextareaFloatingLabel = styled(FloatingLabel, {
  variants: {
    filled: {
      true: {
        transform: 'translate(0, -36px) scale(0.8)',
      },
    },
  },
})

const InputStyle = {
  fontFamily: 'Inter',
  minWidth: '0px',
  flex: 1,
  height: '42px',
  padding: '4px 16px 4px 16px',
  fontSize: '14px',
  lineHeight: 1,
  outline: 'none',
  boxShadow: 'none',
  transition: '200ms cubic-bezier(0, 0, 0.2, 1) 0ms',
  color: '#FFFFFF',
  border: 'none',
  background: 'none',
  '&:disabled': {
    color: '#666',
  },
  '&:focus': {
    outline: 'none',
  },
}

const StyledInput = styled('input', InputStyle)

const StyledReactMentionsInput = styled(ReactMentionsInput, {
  width: '100%',
  fontFamily: 'Inter',
  fontSize: '14px',
  height: '42px',
  input: {
    ...InputStyle,
    width: '100%',
  },
  div: {
    paddingLeft: '8px',
  },
  span: {
    lineHeight: '42px',
  },
})

const MentionsInputSuggestionsStyle = {
  suggestions: {
    backgroundColor: 'transparent',
    list: {
      backgroundColor: '#666',
    },
    item: {
      paddingLeft: '6px',
      '&focused': {
        backgroundColor: '#888',
      },
    },
  },
}

const StyledTextArea = styled(StyledInput, {
  padding: '16px',
  height: 'auto',
  resize: 'none',
})
