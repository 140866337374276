import { styled } from '@stitches/react'
import useLanguage from '../../../shared/stores/useLanguage'

export function LiveBadge(props: { isLive: boolean }) {
  const { t } = useLanguage()
  if (!props.isLive) return
  return <LiveBadgeContainer>{t('streamingGeneral.live')}</LiveBadgeContainer>
}

const LiveBadgeContainer = styled('div', {
  textTransform: 'uppercase',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.25rem 0.375rem',
  fontSize: '13px',
  color: 'rgb(83 252 24)',
  background: 'rgb(15, 18, 20)',
  fontWeight: 600,
  lineHeight: '13px',
  borderRadius: '0.375rem',
  backgroundColor: 'rgb(15 18 20 1)',
})
