/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Asset } from './Asset';
import {
    AssetFromJSON,
    AssetFromJSONTyped,
    AssetToJSON,
} from './Asset';
import type { Channel } from './Channel';
import {
    ChannelFromJSON,
    ChannelFromJSONTyped,
    ChannelToJSON,
} from './Channel';

/**
 * 
 * @export
 * @interface CustomEmote
 */
export interface CustomEmote {
    /**
     * 
     * @type {Date}
     * @memberof CustomEmote
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CustomEmote
     */
    createdBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CustomEmote
     */
    updatedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CustomEmote
     */
    updatedBy?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof CustomEmote
     */
    deletedAt?: Date | null;
    /**
     * 
     * @type {string}
     * @memberof CustomEmote
     */
    deletedBy?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CustomEmote
     */
    hardDelete?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CustomEmote
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CustomEmote
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomEmote
     */
    imageId?: string | null;
    /**
     * 
     * @type {Asset}
     * @memberof CustomEmote
     */
    image?: Asset;
    /**
     * 
     * @type {string}
     * @memberof CustomEmote
     */
    channelId?: string;
    /**
     * 
     * @type {Channel}
     * @memberof CustomEmote
     */
    channel?: Channel;
}

/**
 * Check if a given object implements the CustomEmote interface.
 */
export function instanceOfCustomEmote(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CustomEmoteFromJSON(json: any): CustomEmote {
    return CustomEmoteFromJSONTyped(json, false);
}

export function CustomEmoteFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomEmote {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (json['updatedAt'] === null ? null : new Date(json['updatedAt'])),
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'deletedAt': !exists(json, 'deletedAt') ? undefined : (json['deletedAt'] === null ? null : new Date(json['deletedAt'])),
        'deletedBy': !exists(json, 'deletedBy') ? undefined : json['deletedBy'],
        'hardDelete': !exists(json, 'hardDelete') ? undefined : json['hardDelete'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'imageId': !exists(json, 'imageId') ? undefined : json['imageId'],
        'image': !exists(json, 'image') ? undefined : AssetFromJSON(json['image']),
        'channelId': !exists(json, 'channelId') ? undefined : json['channelId'],
        'channel': !exists(json, 'channel') ? undefined : ChannelFromJSON(json['channel']),
    };
}

export function CustomEmoteToJSON(value?: CustomEmote | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt === undefined ? undefined : (value.createdAt.toISOString()),
        'createdBy': value.createdBy,
        'updatedAt': value.updatedAt === undefined ? undefined : (value.updatedAt === null ? null : value.updatedAt.toISOString()),
        'updatedBy': value.updatedBy,
        'deletedAt': value.deletedAt === undefined ? undefined : (value.deletedAt === null ? null : value.deletedAt.toISOString()),
        'deletedBy': value.deletedBy,
        'hardDelete': value.hardDelete,
        'id': value.id,
        'name': value.name,
        'imageId': value.imageId,
        'image': AssetToJSON(value.image),
        'channelId': value.channelId,
        'channel': ChannelToJSON(value.channel),
    };
}

