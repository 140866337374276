import { atom, useAtom } from 'jotai'

const invalidatorsAtom = atom<Record<string, InvalidatorId>>({})

class InvalidatorId {
  constructor(
    public __id: number,
    public notify: () => void
  ) {}
}
const DEFAULT_ID = new InvalidatorId(0, () => {})

/**
 * Use it like this:
 * ```
 * const invalidator = useInvalidator('SOME_ID')
 * useAsyncEffect(() => { ... }, [invalidator.id])
 * ```
 *
 * And where you need to invalidate:
 *
 * ```
 * const invalidator = useInvalidator('SOME_ID')
 * // ...
 * await invalidator.invalidate()
 * ```
 */
export function useInvalidator(key: string) {
  const [invalidators, setInvalidators] = useAtom(invalidatorsAtom)
  const id = invalidators[key] || DEFAULT_ID
  return {
    id,
    /** For these AsyncUtils */
    invalidate() {
      return new Promise((resolve) => {
        setInvalidators({
          ...invalidators,
          [key]: new InvalidatorId(id.__id + 1, () => {
            resolve(undefined)
          }),
        })
      })
    },
    /**
     * for standard elements that doesn't use notifyInvalidators
     * e.g.: useEffect
     */
    invalidateSync() {
      setInvalidators({
        ...invalidators,
        [key]: new InvalidatorId(id.__id + 1, () => {}),
      })
    },
  }
}

export function notifyInvalidators(dependencies: unknown[]) {
  dependencies.forEach((dep) => {
    if (dep instanceof InvalidatorId) {
      dep.notify()
    }
  })
}
