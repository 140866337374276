import LoadingIcon from '../../../../assets/icons/Loading.svg?react'
import { styled } from '../../../../stitches'

type ButtonProps = {
  type: 'button' | 'submit' | 'reset' | undefined
  className?: string
  onClick?: () => void
  disabled?: boolean
  isLoading?: boolean
  children: React.ReactNode
  variant?:
    | 'danger'
    | 'secondary'
    | 'fans'
    | 'fansPost'
    | 'new'
    | 'add'
    | 'cancel'
}

export default function Button(props: ButtonProps) {
  return (
    <StyledButton
      type={props.type}
      onClick={props.onClick}
      className={props.className}
      disabled={props.disabled || props.isLoading}
      variant={props.variant}
    >
      {props.isLoading ? <LoadingIcon /> : props.children}
    </StyledButton>
  )
}

const StyledButton = styled('button', {
  cursor: 'pointer',
  transition: 'background 200ms',
  background: '#5f5bfe',
  padding: '5px 15px',
  height: '2.625em',
  borderRadius: '0.2rem',
  fontWeight: '600',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 0,
  color: '#fff',
  gap: '0.5em',
  '&:not(:disabled):hover': {
    background: 'rgba(255,255,255,0.1)',
  },
  '&:not(:disabled):active': {
    background: 'rgba(255,255,255,0.2)',
  },
  '&:disabled': {
    cursor: 'initial',
    opacity: 0.5,
  },
  fontSize: '1em',
  variants: {
    variant: {
      secondary: {
        background: 'none',
        fontSize: '0.9em',
        color: '#aaa',
        fontWeight: '400',
      },
      danger: {
        background: '#ca3f3f',
      },
      fans: {
        color: 'white',
        fontWeight: '400',
        height: '48px',
        width: '48px',
        borderRadius: '50%',
      },
      fansPost: {
        background: 'none',
        borderRadius: '50%',
        height: '48px',
        width: '48px',
        color: '#8a96a3',
        '&:hover:not(:disabled)': {
          color: '#5AB8F2',
          backgroundColor: '#F0F9FE',
        },
      },
      new: {
        fontSize: '16px',
        backgroundColor: '#3a3a3a',
        color: 'white',
        '&:hover': {
          backgroundColor: '#2c2c2c',
        },
      },
      add: {
        backgroundColor: '#00FF00',
        color: 'black',
        '&:hover': {
          backgroundColor: '#00cc00',
        },
        marginLeft: '10px',
      },
      cancel: {
        backgroundColor: '#3a3a3a',
        color: 'white',
        '&:hover': {
          backgroundColor: '#2c2c2c',
        },
      },
    },
  },
})
