/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBanInfo
 */
export interface GetBanInfo {
    /**
     * 
     * @type {boolean}
     * @memberof GetBanInfo
     */
    isBanned: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetBanInfo
     */
    bannedAt?: Date | null;
    /**
     * 
     * @type {number}
     * @memberof GetBanInfo
     */
    durationInMinutes?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetBanInfo
     */
    reason?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetBanInfo
     */
    userId: string;
    /**
     * 
     * @type {Date}
     * @memberof GetBanInfo
     */
    finishedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetBanInfo
     */
    isPermanent: boolean;
}

/**
 * Check if a given object implements the GetBanInfo interface.
 */
export function instanceOfGetBanInfo(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "isBanned" in value;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "isPermanent" in value;

    return isInstance;
}

export function GetBanInfoFromJSON(json: any): GetBanInfo {
    return GetBanInfoFromJSONTyped(json, false);
}

export function GetBanInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBanInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isBanned': json['isBanned'],
        'bannedAt': !exists(json, 'bannedAt') ? undefined : (json['bannedAt'] === null ? null : new Date(json['bannedAt'])),
        'durationInMinutes': !exists(json, 'durationInMinutes') ? undefined : json['durationInMinutes'],
        'reason': !exists(json, 'reason') ? undefined : json['reason'],
        'userId': json['userId'],
        'finishedAt': !exists(json, 'finishedAt') ? undefined : (json['finishedAt'] === null ? null : new Date(json['finishedAt'])),
        'isPermanent': json['isPermanent'],
    };
}

export function GetBanInfoToJSON(value?: GetBanInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isBanned': value.isBanned,
        'bannedAt': value.bannedAt === undefined ? undefined : (value.bannedAt === null ? null : value.bannedAt.toISOString()),
        'durationInMinutes': value.durationInMinutes,
        'reason': value.reason,
        'userId': value.userId,
        'finishedAt': value.finishedAt === undefined ? undefined : (value.finishedAt === null ? null : value.finishedAt.toISOString()),
        'isPermanent': value.isPermanent,
    };
}

