/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetBanInfo } from './GetBanInfo';
import {
    GetBanInfoFromJSON,
    GetBanInfoFromJSONTyped,
    GetBanInfoToJSON,
} from './GetBanInfo';
import type { GetSubscriptionResponse } from './GetSubscriptionResponse';
import {
    GetSubscriptionResponseFromJSON,
    GetSubscriptionResponseFromJSONTyped,
    GetSubscriptionResponseToJSON,
} from './GetSubscriptionResponse';

/**
 * 
 * @export
 * @interface GetChannelLoggedInUserResponse
 */
export interface GetChannelLoggedInUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof GetChannelLoggedInUserResponse
     */
    readonly isFollowing?: boolean;
    /**
     * 
     * @type {Date}
     * @memberof GetChannelLoggedInUserResponse
     */
    followedAt?: Date | null;
    /**
     * 
     * @type {GetSubscriptionResponse}
     * @memberof GetChannelLoggedInUserResponse
     */
    subscription?: GetSubscriptionResponse;
    /**
     * 
     * @type {GetBanInfo}
     * @memberof GetChannelLoggedInUserResponse
     */
    banInfo?: GetBanInfo;
}

/**
 * Check if a given object implements the GetChannelLoggedInUserResponse interface.
 */
export function instanceOfGetChannelLoggedInUserResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetChannelLoggedInUserResponseFromJSON(json: any): GetChannelLoggedInUserResponse {
    return GetChannelLoggedInUserResponseFromJSONTyped(json, false);
}

export function GetChannelLoggedInUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetChannelLoggedInUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isFollowing': !exists(json, 'isFollowing') ? undefined : json['isFollowing'],
        'followedAt': !exists(json, 'followedAt') ? undefined : (json['followedAt'] === null ? null : new Date(json['followedAt'])),
        'subscription': !exists(json, 'subscription') ? undefined : GetSubscriptionResponseFromJSON(json['subscription']),
        'banInfo': !exists(json, 'banInfo') ? undefined : GetBanInfoFromJSON(json['banInfo']),
    };
}

export function GetChannelLoggedInUserResponseToJSON(value?: GetChannelLoggedInUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'followedAt': value.followedAt === undefined ? undefined : (value.followedAt === null ? null : value.followedAt.toISOString()),
        'subscription': GetSubscriptionResponseToJSON(value.subscription),
        'banInfo': GetBanInfoToJSON(value.banInfo),
    };
}

