/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GetVideoCategoryItemResponse } from './GetVideoCategoryItemResponse';
import {
    GetVideoCategoryItemResponseFromJSON,
    GetVideoCategoryItemResponseFromJSONTyped,
    GetVideoCategoryItemResponseToJSON,
} from './GetVideoCategoryItemResponse';

/**
 * 
 * @export
 * @interface GetVideoCategoryResponse
 */
export interface GetVideoCategoryResponse {
    /**
     * 
     * @type {Array<GetVideoCategoryItemResponse>}
     * @memberof GetVideoCategoryResponse
     */
    categories?: Array<GetVideoCategoryItemResponse> | null;
}

/**
 * Check if a given object implements the GetVideoCategoryResponse interface.
 */
export function instanceOfGetVideoCategoryResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GetVideoCategoryResponseFromJSON(json: any): GetVideoCategoryResponse {
    return GetVideoCategoryResponseFromJSONTyped(json, false);
}

export function GetVideoCategoryResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVideoCategoryResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'categories': !exists(json, 'categories') ? undefined : (json['categories'] === null ? null : (json['categories'] as Array<any>).map(GetVideoCategoryItemResponseFromJSON)),
    };
}

export function GetVideoCategoryResponseToJSON(value?: GetVideoCategoryResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'categories': value.categories === undefined ? undefined : (value.categories === null ? null : (value.categories as Array<any>).map(GetVideoCategoryItemResponseToJSON)),
    };
}

