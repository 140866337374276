import { useEffect } from 'react'
import useLanguage from '../../../shared/stores/useLanguage'
import { enqueueSnackbar } from 'notistack'
import { getErrorMessage } from '../apiError'
import { useThrowError } from './useThrowError'
import { notifyInvalidators } from './useInvalidator'

/** useEffect but for async functions with notification on error */
export function useAsyncEffect(
  cb: () => Promise<void>,
  dependencies?: unknown[]
) {
  const { t } = useLanguage()
  useEffect(() => {
    cb()
      .then(() => {
        if (dependencies) notifyInvalidators(dependencies)
      })
      .catch((e: unknown) => {
        enqueueSnackbar({
          message: t(getErrorMessage(e)),
          variant: 'error',
        })
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies || [])
}

/** useEffect but for async functions with crasing the component on error */
export function useAsyncEffectThrow(
  cb: () => Promise<void>,
  dependencies?: unknown[]
) {
  const throwError = useThrowError()
  useEffect(() => {
    cb()
      .then(() => {
        if (dependencies) notifyInvalidators(dependencies)
      })
      .catch((e) => throwError(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies || [])
}
