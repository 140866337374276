import { routes, toggleSpecialSubdomain } from '../../../../routes'
import { Link } from 'react-router-dom'
import useLanguage from '../../../stores/useLanguage'
import { Container, styled } from '../../../../stitches'
import LogoImage from '../../../../assets/logo.png'
import { BsSearch } from 'react-icons/bs'
import { Ref, forwardRef, useState } from 'react'
import Button from '../../Common/Form/Button'
import { ProfileMenu } from './ProfileMenu'
import { SearchInput } from './SearchInput'
import { ResetIcon } from '@radix-ui/react-icons'
import { LoginAndRegisterButtons } from './LoginAndRegisterButtons'
import { onlyFans, onlyStream } from '../../../utils/sites'
import { useAuth0 } from '@auth0/auth0-react'

export const Navbar = forwardRef<HTMLDivElement, object>(function Navbar(
  _,
  ref: Ref<HTMLDivElement>
) {
  const [isSearchActive, setIsSearchActive] = useState(false)
  const [query, setQuery] = useState('')
  const { isAuthenticated } = useAuth0()
  const { t } = useLanguage()

  const searchInput = (
    <SearchInput
      value={query}
      mode="navigate"
      onChange={(query) => {
        setQuery(query)
        if (query.length && !isSearchActive) setIsSearchActive(true)
      }}
      onClose={() => setIsSearchActive(false)}
    />
  )

  const mobileRightBarOnInactiveSearch = (
    <ButtonGroup>
      <Button
        type="button"
        variant="secondary"
        onClick={() => setIsSearchActive(true)}
      >
        <BsSearch />
      </Button>
      {!isAuthenticated ? <LoginAndRegisterButtons /> : <ProfileMenu />}
    </ButtonGroup>
  )

  const mobileRightBarOnActiveSearch = (
    <ButtonGroup>
      {searchInput}
      <Button
        type="button"
        variant="secondary"
        onClick={() => setIsSearchActive(false)}
      >
        <ResetIcon />
      </Button>
    </ButtonGroup>
  )

  return (
    <NavbarBackground ref={ref}>
      <StyledContainer>
        <LeftBar>
          <MainMenuItem to={routes.home}>
            <Logo src={LogoImage} />
          </MainMenuItem>
          {onlyStream(
            <>
              <MainMenuItem to={routes.categories()}>
                {t('navbarItems.browse')}
              </MainMenuItem>
              <MainMenuHrefItem href={toggleSpecialSubdomain()}>
                {t('navbarItems.special')}
              </MainMenuHrefItem>
              {isAuthenticated && (
                <>
                  <MainMenuItem to={routes.streams.following}>
                    {t('followingPage.title')}
                  </MainMenuItem>
                </>
              )}
            </>
          )}
          {onlyFans(
            <MainMenuHrefItem href={toggleSpecialSubdomain()}>
              {t('navbarItems.streaming')}
            </MainMenuHrefItem>
          )}
        </LeftBar>
        <CenterBar>{searchInput}</CenterBar>
        <RightBar forSmallScreen={false}>
          {!isAuthenticated ? <LoginAndRegisterButtons /> : <ProfileMenu />}
        </RightBar>
        <RightBar forSmallScreen={true}>
          {isSearchActive
            ? mobileRightBarOnActiveSearch
            : mobileRightBarOnInactiveSearch}
        </RightBar>
      </StyledContainer>
    </NavbarBackground>
  )
})

const Logo = styled('img', {
  height: '36px',
  paddingTop: '8px',
  width: 'auto',
})
const StyledContainer = styled(Container, {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'stretch',
})
const Bar = styled('div', {
  flex: '1 1 0',
  display: 'flex',
  gap: '2rem',
  alignItems: 'center',
  minWidth: 'max-content',
})
const LeftBar = styled(Bar, {
  '@onlyPhone': {
    display: 'none',
  },
})
const RightBar = styled(Bar, {
  flex: 1,
  justifyContent: 'right',
  maxWidth: '100%',
  variants: {
    forSmallScreen: {
      true: {
        '@onlyDesktop': {
          display: 'none',
        },
      },
      false: {
        display: 'none',
        '@onlyDesktop': {
          display: 'flex',
        },
      },
    },
  },
})
const CenterBar = styled('div', {
  flex: 1,
  maxWidth: '300px',
  display: 'none',
  '@onlyDesktop': {
    display: 'block',
  },
})
const NavbarBackground = styled('div', {
  background: 'rgb(36, 39, 44)',
  padding: '0.6rem 1rem',
  color: '#fff',
  position: 'fixed',
  top: '0',
  width: '100%',
  zIndex: '100',
})
const MainMenuItem = styled(Link, {
  color: '#fff',
  textDecoration: 'none',
  fontWeight: 'bold',
  '@onlyPhone': {
    display: 'none',
  },
})
const MainMenuHrefItem = styled('a', {
  color: '#fff',
  textDecoration: 'none',
  fontWeight: 'bold',
  '@onlyPhone': {
    display: 'none',
  },
})
const ButtonGroup = styled('div', {
  maxWidth: '100%',
  justifyContent: 'right',
  display: 'flex',
  gap: '1rem',
  alignItems: 'center',
})
