/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImageVariantResponse
 */
export interface ImageVariantResponse {
    /**
     * 
     * @type {string}
     * @memberof ImageVariantResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ImageVariantResponse
     */
    url: string;
    /**
     * 
     * @type {number}
     * @memberof ImageVariantResponse
     */
    width: number;
    /**
     * 
     * @type {string}
     * @memberof ImageVariantResponse
     */
    format?: string | null;
}

/**
 * Check if a given object implements the ImageVariantResponse interface.
 */
export function instanceOfImageVariantResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "width" in value;

    return isInstance;
}

export function ImageVariantResponseFromJSON(json: any): ImageVariantResponse {
    return ImageVariantResponseFromJSONTyped(json, false);
}

export function ImageVariantResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageVariantResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'url': json['url'],
        'width': json['width'],
        'format': !exists(json, 'format') ? undefined : json['format'],
    };
}

export function ImageVariantResponseToJSON(value?: ImageVariantResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'url': value.url,
        'width': value.width,
        'format': value.format,
    };
}

