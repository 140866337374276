/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BaseCategoryResponse } from './BaseCategoryResponse';
import {
    BaseCategoryResponseFromJSON,
    BaseCategoryResponseFromJSONTyped,
    BaseCategoryResponseToJSON,
} from './BaseCategoryResponse';
import type { BaseTagResponse } from './BaseTagResponse';
import {
    BaseTagResponseFromJSON,
    BaseTagResponseFromJSONTyped,
    BaseTagResponseToJSON,
} from './BaseTagResponse';
import type { GetStreamMetadataResponse } from './GetStreamMetadataResponse';
import {
    GetStreamMetadataResponseFromJSON,
    GetStreamMetadataResponseFromJSONTyped,
    GetStreamMetadataResponseToJSON,
} from './GetStreamMetadataResponse';
import type { GetStreamerInfoResponse } from './GetStreamerInfoResponse';
import {
    GetStreamerInfoResponseFromJSON,
    GetStreamerInfoResponseFromJSONTyped,
    GetStreamerInfoResponseToJSON,
} from './GetStreamerInfoResponse';

/**
 * 
 * @export
 * @interface GetLivestreamInfoResponse
 */
export interface GetLivestreamInfoResponse {
    /**
     * 
     * @type {string}
     * @memberof GetLivestreamInfoResponse
     */
    id: string;
    /**
     * 
     * @type {GetStreamerInfoResponse}
     * @memberof GetLivestreamInfoResponse
     */
    streamer: GetStreamerInfoResponse;
    /**
     * 
     * @type {GetStreamMetadataResponse}
     * @memberof GetLivestreamInfoResponse
     */
    metadata: GetStreamMetadataResponse;
    /**
     * 
     * @type {number}
     * @memberof GetLivestreamInfoResponse
     */
    viewersCount?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLivestreamInfoResponse
     */
    playbackUrl: string;
    /**
     * 
     * @type {string}
     * @memberof GetLivestreamInfoResponse
     */
    thumbnailUrl?: string | null;
    /**
     * 
     * @type {Date}
     * @memberof GetLivestreamInfoResponse
     */
    startedAt?: Date | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLivestreamInfoResponse
     */
    isBanned?: boolean;
    /**
     * 
     * @type {BaseCategoryResponse}
     * @memberof GetLivestreamInfoResponse
     */
    category: BaseCategoryResponse;
    /**
     * 
     * @type {Array<BaseTagResponse>}
     * @memberof GetLivestreamInfoResponse
     */
    tags: Array<BaseTagResponse>;
}

/**
 * Check if a given object implements the GetLivestreamInfoResponse interface.
 */
export function instanceOfGetLivestreamInfoResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "streamer" in value;
    isInstance = isInstance && "metadata" in value;
    isInstance = isInstance && "playbackUrl" in value;
    isInstance = isInstance && "category" in value;
    isInstance = isInstance && "tags" in value;

    return isInstance;
}

export function GetLivestreamInfoResponseFromJSON(json: any): GetLivestreamInfoResponse {
    return GetLivestreamInfoResponseFromJSONTyped(json, false);
}

export function GetLivestreamInfoResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetLivestreamInfoResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'streamer': GetStreamerInfoResponseFromJSON(json['streamer']),
        'metadata': GetStreamMetadataResponseFromJSON(json['metadata']),
        'viewersCount': !exists(json, 'viewersCount') ? undefined : json['viewersCount'],
        'playbackUrl': json['playbackUrl'],
        'thumbnailUrl': !exists(json, 'thumbnailUrl') ? undefined : json['thumbnailUrl'],
        'startedAt': !exists(json, 'startedAt') ? undefined : (json['startedAt'] === null ? null : new Date(json['startedAt'])),
        'isBanned': !exists(json, 'isBanned') ? undefined : json['isBanned'],
        'category': BaseCategoryResponseFromJSON(json['category']),
        'tags': ((json['tags'] as Array<any>).map(BaseTagResponseFromJSON)),
    };
}

export function GetLivestreamInfoResponseToJSON(value?: GetLivestreamInfoResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'streamer': GetStreamerInfoResponseToJSON(value.streamer),
        'metadata': GetStreamMetadataResponseToJSON(value.metadata),
        'viewersCount': value.viewersCount,
        'playbackUrl': value.playbackUrl,
        'thumbnailUrl': value.thumbnailUrl,
        'startedAt': value.startedAt === undefined ? undefined : (value.startedAt === null ? null : value.startedAt.toISOString()),
        'isBanned': value.isBanned,
        'category': BaseCategoryResponseToJSON(value.category),
        'tags': ((value.tags as Array<any>).map(BaseTagResponseToJSON)),
    };
}

