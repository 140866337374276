import { useState } from 'react'
import useLanguage, { LanguageKey } from '../../../shared/stores/useLanguage'
import { getErrorMessage } from '../apiError'
import { enqueueSnackbar } from 'notistack'

export type MutationState = 'waiting' | 'loading' | 'success' | 'error'

/** It tracks the lifecycle of an async callback */
export function useMutation<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TArgs extends Array<any>,
  TResult,
>(callback: (...args: TArgs) => Promise<TResult>) {
  const { t } = useLanguage()
  const [state, setState] = useState<MutationState>('waiting')
  const [error, setError] = useState<LanguageKey>()
  const [data, setData] = useState<TResult>()

  function reset() {
    setState('waiting')
    setError(undefined)
  }

  async function run(notify: boolean, ...args: TArgs): Promise<TResult> {
    try {
      setError(undefined)
      setState('loading')
      const result = await callback(...args)
      setData(result)
      setState('success')
      return result
    } catch (e) {
      const message = getErrorMessage(e)
      setError(message)
      setState('error')
      if (notify) {
        enqueueSnackbar({
          message: t(message),
          variant: 'error',
        })
      }
      throw e
    }
  }

  const genericResponse = {
    error,
    state,
    run: (...args: TArgs) => run(false, ...args),
    runWithErrorNofication: (...args: TArgs) => run(true, ...args),
    reset,
  }

  switch (state) {
    case 'error':
      return { ...genericResponse, state: 'error' as const, data }
    case 'waiting':
      return { ...genericResponse, state: 'waiting' as const, data }
    case 'loading':
      return { ...genericResponse, state: 'loading' as const, data }
    case 'success':
      return {
        ...genericResponse,
        state: 'success' as const,
        data: data as TResult,
      }
  }
}
