/* tslint:disable */
/* eslint-disable */
/**
 * Casino.API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RecordingStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4
} as const;
export type RecordingStatus = typeof RecordingStatus[keyof typeof RecordingStatus];


export function RecordingStatusFromJSON(json: any): RecordingStatus {
    return RecordingStatusFromJSONTyped(json, false);
}

export function RecordingStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordingStatus {
    return json as RecordingStatus;
}

export function RecordingStatusToJSON(value?: RecordingStatus | null): any {
    return value as any;
}

