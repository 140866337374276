import { useLocation } from 'react-router-dom'
import { styled } from '../../../stitches'
import { useEffect } from 'react'

type EmptyLayoutProps = {
  children: React.ReactNode
}

const EmptyLayout = (props: EmptyLayoutProps) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return (
    <Container>
      <ContentContainer>{props.children}</ContentContainer>
    </Container>
  )
}

const Container = styled('div', {
  minHeight: '100vh',
})

const ContentContainer = styled('div', {
  paddingBottom: '1rem',
  minHeight: '100vh',
  color: 'white',
})

export default EmptyLayout
